<div>
  <h5>
    <b>R Number: </b>{{Rnumber}}
  </h5>
</div>
<div style="clear: both; margin-bottom: 5px;">
  <input type="text"
         (input)="onQuickFilterChanged()"
         id="quickFilter"
         placeholder="quick filter..." />
</div>
<div style="clear: both;">
  <ag-grid-angular style="width: 98%; height: 375px;"
                   class="ag-theme-alpine"
                   pagination
                   [gridOptions]="gridOptions"
                   (gridReady)="onGridReady($event)"
                   [rowData]="rowData"
                   [columnDefs]="columnDefs"
                   [pagination]="true"
                   [paginationPageSize]="5"
                   [suppressRowClickSelection]="true"
                   [suppressMenuHide]="true"
                   [frameworkComponents]="frameworkComponents"
                   (rowClicked)="onRowClicked($event)"
                   (rowDoubleClicked)="onDegreeSelected($event)">
  </ag-grid-angular>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="Close()">Close</button>
</div>
