import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../Services/students.service';

@Component({
  selector: 'app-institutional-support',
  templateUrl: './institutional-support.component.html',
  styleUrls: ['./institutional-support.component.css']
})
export class InstitutionalSupportComponent implements OnInit {
  rNumber: any;
  IsData: any;
  telephoneValues: any;
  relationCodeValues: any;
  emergencyContactValues: any;
  currentIdentifier: any;
  codeRelatedPhoneNumber: any;
  soaFolkRelaionCode: any;
  emergPriorityCode: any;
  hideNoRecords: boolean = true;
  formValid: boolean = true;
  hideData: boolean = true;

  
  constructor(private http: HttpClient, private data: SharedService) { }

  ngOnInit(): void {
  }


  userIDForm = new FormGroup({
    'userID': new FormControl('', Validators.required)
  });
  allMatForm = new FormGroup({
    'phoneCodesListsform': new FormControl('', Validators.required),
    'relationCodesListsform': new FormControl('', Validators.required),
    'emergencyContactsListsform': new FormControl('', Validators.required)
  });
  get userIDGetForm() {
    return this.userIDForm.get('userID');
  }
  onKeyClear() {
    this.rNumber = '';
    this.hideData = true;
    this.hideNoRecords = true;
    this.formValid = true;
    this.codeRelatedPhoneNumber = '';
    this.soaFolkRelaionCode = '';
  }
  onKeyRNumEnter(event: any) {
    this.userIDForm.controls['userID'].markAsTouched();
  }
  onKeyeremrno(event: any) {
    if (event.target.value == '') {
      this.rNumber = '';
      this.hideData = true;
      this.hideNoRecords = true;
      this.formValid = true;
      this.codeRelatedPhoneNumber = '';
      this.soaFolkRelaionCode = '';
    }
  }
  //Check if string is in RNumber Format
  isStringRNumber(str: string): boolean {
    const regex = new RegExp('^[rR][0-9]{8}$');
    return regex.test(str);
  };

  //Check if the input is in Email format (accepts lowercase format only)
  isStringTTUEmail(str: string): boolean {
    const regex = new RegExp('^[a-z0-9._%+-]+@ttu\.edu$');
    return regex.test(str.toLowerCase());
  }

  //Check if the input is in Email HSC format (accepts lowercase format only)
  isStringTTUHSCEmail(str: string): boolean {
    const regex = new RegExp('^[a-z0-9._%+-]+@ttuhsc\.edu$');
    return regex.test(str.toLowerCase());
  }

  //Check if the input is in Eraider format
  isStringERaider(str: string): boolean {
    const regex = new RegExp('^[a-zA-Z0-9]{1,8}$');
    return regex.test(str);
  }

  eremrnoSubmit() {
    if (this.isStringRNumber(this.userIDForm.get('userID').value)) {
      this.getEremrnoDetails('rno')
    } else if (this.isStringTTUHSCEmail(this.userIDForm.get('userID').value) || this.isStringTTUEmail(this.userIDForm.get('userID').value)) {
      this.getEremrnoDetails('email')
    } else if (this.isStringERaider(this.userIDForm.get('userID').value)) {
      this.getEremrnoDetails('eraider')
    } else if (this.userIDForm.get('userID').value == '' || !this.isStringRNumber(this.userIDForm.get('userID').value)
      || !this.isStringTTUHSCEmail(this.userIDForm.get('userID').value)
      || !this.isStringERaider(this.userIDForm.get('userID').value)) {
      this.formValid = false;
    }
  }
  
  async getEremrnoDetails(indentifier: string) {
    //broadcasting identifier so that it can be used in queries to identify rnumber,eraider,email
    this.data.transmitIdentifier(indentifier);
    this.data.latestIdentifier.subscribe((identifierValue: any) => { this.currentIdentifier = identifierValue; })

    const rnoDetails = await this.http.get('dashboard/studentapi/studentdetail/' + this.userIDForm.get('userID').value + '/userid/' + indentifier + '/identifier/' + 'detailsFromEremrno').toPromise();
    const telephoneDropDownValue = await this.http.get('dashboard/studentapi/studentdetail/' + this.userIDForm.get('userID').value + '/userid/' + indentifier + '/identifier/' + 'telephoneDropDownValue').toPromise();
    const relationCodeDropDownValue = await this.http.get('dashboard/studentapi/studentdetail/' + this.userIDForm.get('userID').value + '/userid/' + indentifier + '/identifier/' + 'relationCodeDropDownValue').toPromise();
    const emergencyContactDropDownValue = await this.http.get('dashboard/studentapi/studentdetail/' + this.userIDForm.get('userID').value + '/userid/' + indentifier + '/identifier/' + 'emergencyContactDropDownValue').toPromise();
    this.telephoneValues = telephoneDropDownValue;
    this.relationCodeValues = relationCodeDropDownValue;
    this.emergencyContactValues = emergencyContactDropDownValue;
    this.IsData = rnoDetails;
    if (this.IsData.length != 0) {
      this.hideData = false;
      this.hideNoRecords = true;
      this.formValid = true;
      if (this.telephoneValues.length != 0 ) {
        this.onLoadPhoneCodeSelect(this.telephoneValues[0].TELECODE)
        this.selectedTeleVal = this.telephoneValues[0].TELECODE;        
      }
      if (this.relationCodeValues.length != 0) {
        this.onLoadSoafolkCodeSelect(this.relationCodeValues[0].SORFOLKRELT)
        this.selectedSoafolkVal = this.relationCodeValues[0].SORFOLKRELT;
      }
      if (this.emergencyContactValues.length != 0) {
        this.onLoadEmergPrioritySelect(this.emergencyContactValues[0].PRIORITYCODE)
        this.selectedEmergVal = this.emergencyContactValues[0].PRIORITYCODE;
      }
    } else {
      this.hideData = true;
      this.hideNoRecords = false;
      this.formValid = true;
    }
  }

  
  selectedTeleVal: any;
  selectedSoafolkVal: any;
  selectedEmergVal: any;

  
  async phoneCodeSelect(event: any) {
    if (event.isUserInput) {
      const phoneNumber = await this.http.get('dashboard/studentapi/studentdetail/' + event.source.value + '/phoneCodeValue/' + this.userIDForm.get('userID').value + '/searchValue/' + this.currentIdentifier + '/identifier/' + 'phoneCodeSelect').toPromise();
      this.codeRelatedPhoneNumber = phoneNumber;
    } 
  }
  async onLoadPhoneCodeSelect(phoneCode: any) {
    const phoneNumber = await this.http.get('dashboard/studentapi/studentdetail/' + phoneCode + '/phoneCodeValue/' + this.userIDForm.get('userID').value + '/searchValue/' + this.currentIdentifier + '/identifier/' + 'phoneCodeSelect').toPromise();
    this.codeRelatedPhoneNumber = phoneNumber;
  }

  async soafolkSelect(event: any) {
    if (event.isUserInput) {
      const soafolk = await this.http.get('dashboard/studentapi/studentdetail/' + event.source.value + '/soafolkCodeValue/' + this.userIDForm.get('userID').value + '/searchValue/' + this.currentIdentifier + '/identifier/' + 'soafolkSelect').toPromise();
      this.soaFolkRelaionCode = soafolk;
    }
  }
  async onLoadSoafolkCodeSelect(soaCode: any) {
    const soafolk = await this.http.get('dashboard/studentapi/studentdetail/' + soaCode + '/soafolkCodeValue/' + this.userIDForm.get('userID').value + '/searchValue/' + this.currentIdentifier + '/identifier/' + 'soafolkSelect').toPromise();
    this.soaFolkRelaionCode = soafolk;
  }

  async emergPrioritySelect(event: any) {
    if (event.isUserInput) {
      const emergPriority = await this.http.get('dashboard/studentapi/studentdetail/' + event.source.value + '/emergPriorityValue/' + this.userIDForm.get('userID').value + '/searchValue/' + this.currentIdentifier + '/identifier/' + 'emergPrioritySelect').toPromise();
      this.emergPriorityCode = emergPriority;
    }
  } 
  async onLoadEmergPrioritySelect(emergCode: any) {
    const emergPriority = await this.http.get('dashboard/studentapi/studentdetail/' + emergCode + '/emergPriorityValue/' + this.userIDForm.get('userID').value + '/searchValue/' + this.currentIdentifier + '/identifier/' + 'emergPrioritySelect').toPromise();
    this.emergPriorityCode = emergPriority;
  }
  allMatSubmit() {

  }
  soafolkDetailrows = ['Name', 'Address', 'Phone Number'];
}
