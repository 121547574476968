import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { distinctUntilChanged } from 'rxjs/operators';

export interface GradItem {
  COMMON: string;
}
const GRAD_DATA: GradItem[] = [];
export interface gradFilter {
}
@Component({
  selector: 'app-graduation-dashboard',
  templateUrl: './graduation-dashboard.component.html',
  styleUrls: ['./graduation-dashboard.component.css']
})
export class GraduationDashboardComponent implements OnInit {
  selectedTerm: any;
  termCodesList: any;
  allPrograms: any;
  infoTabLoading: boolean = false;
  dropdownSelectError: boolean = false;
  hideNoRecords: boolean = false;
  hidePagination: boolean = false;
  hideFilteredError: boolean = false;
  hideData: boolean = true;
  displayedColumns: string[] = ['COMMON'];
  dataSource = new MatTableDataSource<GradItem>(GRAD_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('select') select: MatSelect;
  gradFilters: gradFilter[] = [];
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  gradboard_form: FormGroup = this.fb.group({
    termCodesListsform: ['', Validators.required],
    programCodesListsform: [''],
  });

  ngOnInit(): void {
    this.http.get('dashboard/studentapi/studentdetail/AllGradDashBoardTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }

  constructor(private http: HttpClient, private dialog: MatDialog, private snackBar: MatSnackBar, private fb: FormBuilder) { }

  gradboardDetailsTable_form = new FormGroup({
  });
 
  termSelect(event: any) {
    if (event.isUserInput) {
      console.log(this.gradboard_form.get('termCodesListsform').value);
      console.log(event.source.value);
      this.getPrograms(event.source.value.slice(-6));
    }
  }
  async getPrograms(termcode: any) {
    const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/programsFromTerm').toPromise();
    this.allPrograms = programs;
    this.gradboard_form.get('programCodesListsform').setValue('');
  }
  termCodeFieldHasError(): boolean {
    const termControl = this.gradboard_form.get('termCodesListsform');
    return termControl.hasError('required') && termControl.touched;
  }

  gradboard_form_submit() {
    this.infoTabLoading = true;
      if (this.gradboard_form.get('termCodesListsform').value == "" && this.gradboard_form.get('programCodesListsform').value == "") {
        this.infoTabLoading = false;
        this.dropdownSelectError = true;
      } else if (this.gradboard_form.get('termCodesListsform').value != "" && this.gradboard_form.get('programCodesListsform').value == "") {
        this.getGradBoardTable(this.gradboard_form.get('termCodesListsform').value.slice(-6), "NA");
      } else if (this.gradboard_form.get('termCodesListsform').value != "" && this.gradboard_form.get('programCodesListsform').value != "") {
        this.getGradBoardTable(this.gradboard_form.get('termCodesListsform').value.slice(-6), this.gradboard_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1);
      }
  }
  searchValue = '';
  @ViewChild('inputo', { static: true }) searchInput!: ElementRef;
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  async getGradBoardTable(termcode: any, programs: string) {
    this.dropdownSelectError = false;
    this.infoTabLoading = true;
    this.hidePagination = false;

    this.selectedTerm = termcode;
    const tableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + programs + '/programs/gradTableData').toPromise();
    this.dataSource = new MatTableDataSource(tableData as GradItem[]);
    if (this.dataSource.data.length != 0) {
      this.infoTabLoading = false;
      this.dataSource.paginator = this.paginator;
      this.hidePagination = true;
      this.hideNoRecords = false;
    } else {
      this.infoTabLoading = false;
      this.hidePagination = false;
      this.hideNoRecords = true;
    }
  }
}

