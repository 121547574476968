import { Component, OnInit, ViewChild, HostListener, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../Services/students.service';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { ScrollToTopComponent } from '../scroll-to-top/scroll-to-top.component';
import { NotificationsComponent } from '../allmodals/notifications/notifications.component';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

interface UserRoles {
  HSCREG_USER_DASHBOARD?: string;
  HSCREG_USER_WORKBOARD?: string;
  HSCREG_USER_TRANSFERBOARD?: string;
  HSCREG_USER_INSSUPPORT?: string;
  HSCREG_USER_ENROLLMENTDASH?: string;
  HSCREG_USER_ROSTER?: string;
  HSCREG_USER_GRADES?: string;
  HSCREG_USER_GRADDASH?: string;
  HSCREG_USER_SCHOOLGRADDASH?: string;
  HSCREG_USER_COMBINEDGRADDASH?: string;
  HSCREG_USER_RESIDENCYDASH?: string;
  HSCREG_USER_ODSREPORTSDASH?: string;
  HSCREG_USER_DUALREPORTSDASH?: string;
  HSCREG_USER_ROLES?: string;
  HSCREG_USER_CRNACCESS ?: string;
  [key: string]: string | undefined; // This is an index signature for any additional dynamic keys.
}
@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.css']
})
export class NavSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenavContent) sidenavContent: MatSidenavContent;
  @ViewChild(ScrollToTopComponent) scrollToTopComponent: ScrollToTopComponent;
  userRoles: UserRoles = {};
  @Output() sidenavContentScrolled = new EventEmitter<void>();
  ngAfterViewInit() {
    this.scrollToTopComponent.scrollContainer = this.sidenavContent.getElementRef();

    this.sidenavContent.elementScrolled().subscribe(() => {
      this.sidenavContentScrolled.emit();
    });
  }

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  showDashboardMenu : boolean = false;
  showAcademicMenu: boolean = false;
  showReportsMenu: boolean = false;

  showDashboardMainTab: boolean = false;
  showSettingsMainTab: boolean = false;
  showReportsMainTab: boolean = false;
  showAcademicServicesMainTab: boolean = false;
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  //userRoles: any = {}
  constructor(private http: HttpClient, private data: SharedService, public dialog: MatDialog) {
    const storedCount = localStorage.getItem('notificationCount');
    if (storedCount) {
      this.notificationCount = parseInt(storedCount);
    }
  }
  roles: BehaviorSubject<UserRoles> = new BehaviorSubject<UserRoles>({});
  ngOnInit() {
    this.setUserAccess();
  }
  setUserAccess() {
    // Assuming you get an object of userRoles where the keys are the role names
    // and the values are 'YES' or 'NO'
    this.data.roles.subscribe((userRoles: UserRoles) => {
      this.userRoles = userRoles;
      this.showDashboardMainTab = this.hasAccessToAnySubCategory([
        this.userRoles.HSCREG_USER_DASHBOARD,
        this.userRoles.HSCREG_USER_WORKBOARD,
        this.userRoles.HSCREG_USER_TRANSFERBOARD,
        this.userRoles.HSCREG_USER_INSSUPPORT,
        this.userRoles.HSCREG_USER_ENROLLMENTDASH,
        this.userRoles.HSCREG_USER_DUALREPORTSDASH,
      ]);
      this.showAcademicServicesMainTab = this.hasAccessToAnySubCategory([ 
      this.userRoles.HSCREG_USER_ROSTER,
        this.userRoles.HSCREG_USER_GRADES,
        this.userRoles.HSCREG_USER_GRADDASH,
        this.userRoles.HSCREG_USER_SCHOOLGRADDASH,
        this.userRoles.HSCREG_USER_COMBINEDGRADDASH,
        this.userRoles.HSCREG_USER_RESIDENCYDASH
      ]);
      this.showReportsMainTab = this.hasAccessToAnySubCategory([
        userRoles.HSCREG_USER_ODSREPORTSDASH,
      ]);
      this.showSettingsMainTab = this.hasAccessToAnySubCategory([
        userRoles.HSCREG_USER_ROLES,
        userRoles.HSCREG_USER_CRNACCESS,      
      ]);
     
    });
  }

  // Utility method to check if any of the subcategories has access
  private hasAccessToAnySubCategory(roles: string[]): boolean {
    return roles.some(role => role === 'YES');
  }

  opened = false;
  notificationCount = 4;
  resetBadgeCount() {

  }
  myNotificationMessage: string = "<h3>Welcome to this notification badge, which serves as a tool for displaying new and ad-hoc changes to the user in a timely and efficient manner. It provides a streamlined approach to notifying users of updates, allowing for improved communication.</h3><b>Note: Please clear your cache and cookies if you find any functionalities not working. Thank you!</b><ul><h6>Completed Changes</h6><li>Completed Enrollment Dashboard</li><li>Reports dashboard has clearinghouse report module added</li><h6>Upcoming Changes</h6><li>Working on Residency Dashboard and Reports Dashboard.</li></ul>";

  async showNotification() {
    const dialogRef = this.dialog.open(NotificationsComponent, {
      data: {
        modalMsg: this.myNotificationMessage,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.notificationCount = 0;
      localStorage.setItem('notificationCount', '0');
    });
  }
  

  url: any;
  async logout() {
    console.log("cames");
    const x = await this.http.get('dashboard/studentapi/studentdetail/logout').toPromise();
    console.log(x);
    this.url = 'https://eraider.ttu.edu/signout.asp?redirect=https://registrar.ttuhsc.edu/dashboard/index.html';
    window.location.href = this.url;
    console.log("logout complete");
  }
}
