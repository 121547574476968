<div class="container">
  <mat-card class="stack-card">
    <mat-card-title-group>
      <mat-card-title>Graph</mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
      <table class="table-borderless">
        <tbody>
          <tr>
            <th> <h6><u>Headcount By Level (All Academic Terms)</u></h6></th>
          </tr>
          <tr>
            <td>
              <div *ngIf="barChartDataLevel">
                <canvas baseChart
                        [datasets]="barChartDataLevel"
                        [labels]="barChartLabelsLevel"
                        [options]="barChartOptionsLevel"
                        [plugins]="barChartPluginsLevel"
                        [legend]="barChartLegendLevel"
                        [chartType]="barChartTypeLevel">
                </canvas>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </mat-card-content>
  </mat-card>


</div>
