import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { MatSnackBar } from '@angular/material/snack-bar';
import { formatDate } from '@angular/common';

export interface DialogInfo {
  RNumber: string;
}
@Component({
  selector: 'app-xtender-documenttypelist-modal',
  templateUrl: './xtender-documenttypelist-modal.component.html',
  styleUrls: ['./xtender-documenttypelist-modal.component.css']
})
export class XtenderDocumenttypelistModalComponent implements OnInit {

  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    public dialogRef: MatDialogRef<XtenderDocumenttypelistModalComponent>
    , private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getDocumentTypeInTable();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
      filter: true,
      sortable: true,
      floatingFilter: true,

    };
    this.gridOptions = {
      domLayout: 'normal',
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      rowSelection: 'single',
    };
  }
  Rnumber = this.data.RNumber;
  public rowData: any;
  public gridOptions;
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  frameworkComponents: any;
  public defaultColDef;

  columnDefs = [
    {
      headerName: 'Document Type',
      field: 'DOCUMENT_TYPE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Document Reference Number',
      field: 'DOCUMENT_REFERENCE_NUMBER',
      sortable: true,
      filter: true,
      hide: true
    },
    {
      headerName: 'Document Description',
      field: 'DOCUMENT_DESCRIPTION',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Date Received (mm-dd-yyyy)',
      field: 'DATE_RECEIVED',
      sortable: true,
      filter: true,
      sort: 'desc',
      valueFormatter: params => {
        var dt = new Date(params.value);
        return formatDate(dt, 'MM-dd-yyyy', 'en');
      }
    },
    {
      headerName: 'Rnumber',
      field: 'R_NUMBER',
      sortable: true,
      filter: true,
      hide: true
    }

  ];
  async getDocumentTypeInTable() {
    const student_SBSdocs = await this.http.get('dashboard/studentapi/studentdetail/' + this.Rnumber + '/RNumber/SendRNumberToXtender').toPromise();
    console.log(student_SBSdocs)
    this.rowData = student_SBSdocs;
    /*if (this.Rnumber != "") {
      const holdsTableData = await this.http.get('dashboard/studentapi/studentdetail/' + this.Rnumber + '/rno/' + 'holdsModalTableData').toPromise();
      this.rowData = holdsTableData;
      if (this.rowData.length != 0) {

        this.NoHoldInfo = true;
      }
      else {
        this.NoHoldInfo = false;
      }
    }*/
    console.log(this.Rnumber);
  }
  Close(): void {
    this.dialogRef.close(true);
  }
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
  onRowClicked(event: any) {
    const selectedDocumentType = event.data.DOCUMENT_TYPE;
    const selectedDocumentRef = event.data.DOCUMENT_REFERENCE_NUMBER;
    const selectedrnno = event.data.R_NUMBER;
    console.log(selectedDocumentType, selectedDocumentRef, selectedrnno)
    this.XtenderSearch(selectedDocumentRef)
  }
  student_docs_download: any;
  async XtenderSearch(selectedDocumentRef: any) {
    this.showSnackbarAction('Please Wait. Opening file', null)
    /* this.student_details_from_xtender = await this.http.get('dashboard/studentapi/studentdetail/SendRNumberToXtender?Rnumber=' + rno).toPromise();*/

    // if (this.student_details_from_xtender.length != 0) {
    this.student_docs_download = await this.http.get('dashboard/studentapi/studentdetail/DownloadXtenderFile/' + selectedDocumentRef).toPromise();
    if (this.student_docs_download != null) {
      var type = this.student_docs_download.Extension + '';
      type = type.replace('.', '');
      var binaryString = window.atob(this.student_docs_download.FileBytes);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }

      var blob = new Blob([bytes], { type: "application/" + type });
      /*var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.student_docs_download.Filename;
      link.click();*/
      var file = window.URL.createObjectURL(blob);
      window.open(file);
    } else {
      this.showSnackbarAction('No File Found', null)
    }

    /*} else if (this.student_details_from_xtender.length == 0) {
      this.showSnackbarAction('No File Found', null)
    }*/

  }
  showSnackbarAction(content, action) {
    let snack = this.snackBar.open(content, action, { duration: 3000 });
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

}
