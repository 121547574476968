<div style="margin-top: 20px;"  class="container">
  <h1>Dean's Dashboard</h1>
  <mat-card class="example-card">
    <mat-card-title-group>
      <mat-card-title>Current Term Count (Fall 2022)</mat-card-title>

    </mat-card-title-group>
    <mat-card-content>
      <div>
        <div *ngIf="infoTabLoading" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        <mat-grid-list gutterSize="15px" cols="4" rowHeight="100px">
          <mat-grid-tile *ngFor="let tile of countList"
                         [colspan]="1"
                         [rowspan]="1">
            <div id="outer">
              <div id="inner">
                {{tile.COUNTTYPE}} : {{tile.OVERALLCOUNT}}
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </mat-card-content>

  </mat-card>
  <div style="padding-top: 30px;min-height:2000px;">
    <mat-tab-group animationDuration="0ms" color="warn" dynamicHeight="true">
      <mat-tab label="Campus"><div style="padding-top: 30px;"><app-deans-campus></app-deans-campus></div></mat-tab>
      <mat-tab label="School"><div style="padding-top: 30px;"><app-deans-school></app-deans-school></div></mat-tab>
      <mat-tab label="Level"><div style="padding-top: 30px;"><app-deans-level></app-deans-level></div></mat-tab>
    </mat-tab-group>
  </div>
  
</div>

  <br />
  <br />
