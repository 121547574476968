import { NgModule } from '@angular/core';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { RouterModule, Routes } from '@angular/router';
import { RosterComponent } from './roster/roster.component';
import { GradeComponent } from './grade/grade.component';
import { ComponentOneComponent } from './component-one/component-one.component';
import { RolesComponent } from './roles/roles.component';
import { AppComponent } from './app.component';
import { CrnAccessComponent } from './crn-access/crn-access.component';
import { AuthorizationGuardService } from './Services/authorization-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { DeansDashboardComponent } from './deans-dashboard/deans-dashboard.component';
import { WorkBoardComponent } from './work-board/work-board.component';
import { InstitutionalSupportComponent } from './institutional-support/institutional-support.component';
import { TransferBoardComponent } from './transfer-board/transfer-board.component';
import { EnrollmentDashboardComponent } from './enrollment-dashboard/enrollment-dashboard.component';
import { GraduationDashboardComponent } from './graduation-dashboard/graduation-dashboard.component';
import { SchoolGraduationDashboardComponent } from './school-graduation-dashboard/school-graduation-dashboard.component';
import { CombinedGraduationDashboardComponent } from './combined-graduation-dashboard/combined-graduation-dashboard.component';
import { ResidencyDashboardComponent } from './residency-dashboard/residency-dashboard.component';
import { OdsReportsComponent } from './ods-reports/ods-reports.component';
import { DualdegreeDashboardComponent } from './dualdegree-dashboard/dualdegree-dashboard.component';

const routes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  {
    path: '', canActivate: [AuthorizationGuardService], children: [
      { path: '', component: ComponentOneComponent, pathMatch: 'full' },
      { path: 'roster', component: RosterComponent },
      { path: 'grade', component: GradeComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'crn-access', component: CrnAccessComponent },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'deans-dashboard', component: DeansDashboardComponent },
      { path: 'work-board', component: WorkBoardComponent },
      { path: 'transfer-board', component: TransferBoardComponent },
      { path: 'institutional-support', component: InstitutionalSupportComponent },
      { path: 'enrollment-dashboard', component: EnrollmentDashboardComponent },
      { path: 'graduation-dashboard', component: GraduationDashboardComponent },
      { path: 'school-graduation-dashboard', component: SchoolGraduationDashboardComponent },
      { path: 'combined-graduation-dashboard', component: CombinedGraduationDashboardComponent },
      { path: 'residency-dashboard', component: ResidencyDashboardComponent },
      { path: 'ods-reports', component: OdsReportsComponent },
      { path: 'dualdegree-dashboard', component: DualdegreeDashboardComponent }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
]

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [AppComponent, NavSidebarComponent];
