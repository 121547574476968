import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { XtenderDocumenttypelistModalComponent } from '../allmodals/xtender-documenttypelist-modal/xtender-documenttypelist-modal.component';
import { XtenderDocumentuploadModalComponent } from '../allmodals/xtender-documentupload-modal/xtender-documentupload-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface TransferItem {
  RNUMBER: string;
  COMMON: string;
  HVAD_ADMIT_TERM: string;
  PREREQUISITE: string;
  FINAL_DECISION: string;
  MISSING_TRANSCRIPT: string;
}
const TRANSFER_DATA: TransferItem[] = [];
export interface transferFilter {
  name: string;
  options: string[];
  defaultValue: string;
}
@Component({
  selector: 'app-transfer-board',
  templateUrl: './transfer-board.component.html',
  styleUrls: ['./transfer-board.component.css']
})
export class TransferBoardComponent implements OnInit {
  termCodesList: any;
  allPrograms: any;
  infoTabLoading: boolean = false;
  hidePagination: boolean = false;
  hideNoRecords: boolean = false;
  selectedTerm: string;
  allTermsArray: string[] = [];
  selectedTermsListValues: any;
  selectedProgramsListValues: any;
  dropdownSelectError: boolean = false;
  hideFilteredError: boolean = false;
  defaultValue = "All";
  filterDictionary = new Map<string, string>();
  classificationValues: any;
  transferfilterHvadAdmitTerm: any;
  transferfilterLevel: any;
  transferfilterResult: any;
  transferfilterMissingTranscript: any;
  transferfilterPrerequisite: any;
  transferfiltersLength: any;
  Result: any;//this variable is used to hold the complete or incomplete transfer value obtained from child component
  displayedColumns: string[] = ['COMMON'];
  dataSource = new MatTableDataSource<TransferItem>(TRANSFER_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('select') select: MatSelect;
  transferFilters: transferFilter[] = [];

  constructor(private http: HttpClient, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.http.get('dashboard/studentapi/studentdetail/AllTransferBoardTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }

  transferBoardTable: any;
  showmeRes = new FormGroup({
  });
  transferboard_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'programCodesListsform': new FormControl('')
  });
  transferboardDetailsTable_form = new FormGroup({
  });

  optionClick() {
    this.select.options.forEach((item: MatOption) => {
    });
  }

  termSelect(event: any) {

    if (event.isUserInput) {
      console.log(this.transferboard_form.get('termCodesListsform').value);
      console.log(event.source.value);
      this.getPrograms(event.source.value.slice(-6));
    }
  }

  async getPrograms(termcode: any) {
    const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/programsFromTerm').toPromise();
    this.allPrograms = programs;
    this.transferboard_form.get('programCodesListsform').setValue('');
  }

  transferboard_submit() {
    this.infoTabLoading = true;
    if (this.transferboard_form.get('termCodesListsform').value == "" && this.transferboard_form.get('programCodesListsform').value == "") {
      this.infoTabLoading = false;
      this.dropdownSelectError = true;
    } else if (this.transferboard_form.get('termCodesListsform').value != "" && this.transferboard_form.get('programCodesListsform').value == "") {
      this.getTransferBoardTable(this.transferboard_form.get('termCodesListsform').value.slice(-6), "NA");
    } else if (this.transferboard_form.get('termCodesListsform').value != "" && this.transferboard_form.get('programCodesListsform').value != "") {
      this.getTransferBoardTable(this.transferboard_form.get('termCodesListsform').value.slice(-6), this.transferboard_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1);
    }
  }
  transferboardDetailsTable_submit() {

  }
  applyTransferFilter(ob: MatSelectChange, transferfilterKey: transferFilter) {
    this.filterDictionary.set(transferfilterKey.name, ob.value);
    this.applyCombinedFilters();
    console.log(this.dataSource.filteredData.length);
    if (this.dataSource.filteredData.length != 0) {
      this.hideFilteredError = false;
    } else {
      this.hideFilteredError = true;
    }
  }

  searchValue = '';
  @ViewChild('inputo', { static: true }) searchInput!: ElementRef;

  applyFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.applyCombinedFilters();
  }
  applyCombinedFilters() {
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);

    const jsonString = JSON.stringify({
      searchValue: this.searchValue,
      filters: Array.from(this.filterDictionary.entries()),
    });

    this.dataSource.filter = jsonString;

    if (this.dataSource.filteredData.length !== 0) {
      this.hideFilteredError = false;
    } else {
      this.hideFilteredError = true;
    }
  }
  filterPredicate(record: TransferItem, filter: string) {
    const parsedFilter = JSON.parse(filter);
    const map = new Map<string, string>(parsedFilter.filters);
    const searchValue = parsedFilter.searchValue?.trim().toLowerCase() || '';

    let searchMatch = false;

    if (searchValue) {
      for (const key in record) {
        if (
          record.hasOwnProperty(key) &&
          typeof record[key as keyof TransferItem] === 'string' &&
          record[key as keyof TransferItem].toLowerCase().includes(searchValue)
        ) {
          searchMatch = true;
          break;
        }
      }
    } else {
      searchMatch = true;
    }

    for (const [key, value] of map) {
      if (value !== 'All' && record[key as keyof TransferItem] !== value) {
        return false;
      }
    }

    return searchMatch;
  }
  clearFilters() {
    // Reset filterDictionary
    this.filterDictionary.clear();

    // Reset each filter to "All"
    this.transferFilters.forEach((filter, index) => {
      this.filterDictionary.set(filter.name, this.defaultValue);
      this.transferFilters[index].defaultValue = this.defaultValue;
    });

    // Reset search input
    this.searchValue = '';
    this.searchInput.nativeElement.value = '';


    // Apply the updated filters
    this.applyCombinedFilters();
  }
  async getTransferBoardTable(termcode: any, programs: any) {

    this.dropdownSelectError = false;
    this.infoTabLoading = true;
    this.hidePagination = false;
    this.selectedTerm = termcode;
    const tableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + programs + '/programs/' + 'transferTableData').toPromise();
    this.dataSource = new MatTableDataSource(tableData as TransferItem[]);
    if (this.dataSource.data.length != 0) {
      //clears out the array and adds in the new filters on every submit
      this.transferFilters.splice(0);

      this.transferfilterHvadAdmitTerm = [...new Set(this.dataSource.data.map(item => item.HVAD_ADMIT_TERM))];
      this.transferfilterHvadAdmitTerm.unshift(this.defaultValue);

      this.transferfilterResult = [...new Set(this.dataSource.data.map(item => item.FINAL_DECISION))];
      this.transferfilterResult.unshift(this.defaultValue);

      this.transferfilterMissingTranscript = [...new Set(this.dataSource.data.map(item => item.MISSING_TRANSCRIPT))];
      this.transferfilterMissingTranscript.unshift(this.defaultValue);

      this.transferfilterPrerequisite = [...new Set(this.dataSource.data.map(item => item.PREREQUISITE))];
      this.transferfilterPrerequisite.unshift(this.defaultValue);

      this.transferFilters.push({ name: 'HVAD_ADMIT_TERM', options: this.transferfilterHvadAdmitTerm, defaultValue: this.defaultValue });
      this.transferFilters.push({ name: 'FINAL_DECISION', options: this.transferfilterResult, defaultValue: this.defaultValue });
      this.transferFilters.push({ name: 'MISSING_TRANSCRIPT', options: this.transferfilterMissingTranscript, defaultValue: this.defaultValue });
      this.transferFilters.push({ name: 'PREREQUISITE', options: this.transferfilterPrerequisite, defaultValue: this.defaultValue });


      this.transferfiltersLength = this.transferFilters.length;
      this.infoTabLoading = false;
      this.dataSource.paginator = this.paginator;
      this.hidePagination = true;
      this.hideNoRecords = false;
      //this.workboard_form.get('programCodesListsform').setValue('');

    } else {
      this.infoTabLoading = false;
      this.hidePagination = false;
      this.hideNoRecords = true;
    }
  }
  showmeRes_submit() {
  }
  async showXtenderDocumentType(rno: any) {
    let dialogRef = this.dialog.open(XtenderDocumenttypelistModalComponent, {
      data: { RNumber: rno },
      width: '78%',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  //comes in when upload xtender document is clicked
  async uploadXtenderDocument(rno: any, termcode: any) {
    let dialogRef = this.dialog.open(XtenderDocumentuploadModalComponent, {
      data: { RNumber: rno, Term: termcode },
      width: '78%',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  //comes in when Add HVAD button is clicked
  async InsertHvad(rno: any, term: any) {
    if (rno != null && term != null) {
      const snackbarRef = this.showSnackbarAction('Please wait... Value is getting updated.', null, 0);
      const body = {
        "SPRIDEN_ID": rno,
        "SGRSACT_ACTC_CODE": "HVAD",
        "SGRSACT_TERM_CODE": term,
      };

      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/insertSGRSACT';
      try {
        var response = await this.http.put<any>(api, body, options).toPromise();
        snackbarRef.dismiss();
        if (response.status === 'SGRSACT Inserted!') {
          this.getTransferBoardTable(
            this.transferboard_form.get('termCodesListsform').value.slice(-6),
            this.transferboard_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1 || 'NA'
          );
          console.log('Value updated.');
          this.showSnackbarAction('HVAD value Inserted Successfully.', null);
          // Set all filters to 'All'. I fi dont set the filter to All the pagination count becomes wrong if I choose filters from the UI
          this.transferFilters.forEach(filter => {
            this.filterDictionary.set(filter.name, 'All');
            filter.defaultValue = 'All';
          });
          // Apply the updated filters
          this.applyCombinedFilters();

        } else {
          console.log('Error:', response.status);
          this.showSnackbarAction('Error: ' + response.status, null);
        }
      } catch (error) {
        snackbarRef.dismiss();
        console.log('Error:', error);
      }
    } else {
      this.showSnackbarAction('something went wrong rno and term not found', null);
    }
  }
  showSnackbarAction(content, action, duration = 4000) {
    let snack = this.snackBar.open(content, action, { duration: duration });
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
    return snack;
  }


}
