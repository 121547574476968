import { Component, OnInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-deans-school',
  templateUrl: './deans-school.component.html',
  styleUrls: ['./deans-school.component.css']
})
export class DeansSchoolComponent implements OnInit {
  infoTabLoading: boolean = false;
  infoTabLoadingCampus: boolean = false;

  gsbs: any;
  hp: any;
  medicine: any;
  nursing: any;
  pharmacy: any;
  terms = [
    { value: 'Spring', viewValue: 'Spring' },
    { value: 'Summer', viewValue: 'Summer' },
    { value: 'Fall', viewValue: 'Fall' }
  ];
  deanschool_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'schoolListsform': new FormControl('', Validators.required)
  });
  constructor(private http: HttpClient) { }
  ngOnInit() {
    this.infoTabLoading = true;
    this.getStackedBarBySchool("NoTerm");  
  }
  dean_submitSchool() {
    var term = this.deanschool_form.get('termCodesListsform').value;   
    this.getStackedBarBySchool(term);
  }

  async getStackedBarBySchool(term: string) {
    this.gsbs = await this.http.get('dashboard/studentapi/studentdetail/' + term + '/term/getDeansCountGsbs').toPromise();
    this.hp = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountHp').toPromise();
    this.medicine = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountMedicine').toPromise();
    this.nursing = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountNursing').toPromise();
    this.pharmacy = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountPharmacy').toPromise();

    this.barChartDataSchool = [
      { data: this.gsbs, label: 'GSBS', stack: 'a', backgroundColor: "#1E0604", hoverBackgroundColor: "#1E0604" },
      { data: this.hp, label: 'Health Professions', stack: 'a', backgroundColor: "#3B0D08", hoverBackgroundColor: "#3B0D08" },
      { data: this.medicine, label: 'Medicine', stack: 'a', backgroundColor: "#59130C", hoverBackgroundColor: "#59130C" },
      { data: this.nursing, label: 'Nursing', stack: 'a', backgroundColor: "#761910", hoverBackgroundColor: "#761910" },
      { data: this.pharmacy, label: 'Pharmacy', stack: 'a', backgroundColor: "#941F14", hoverBackgroundColor: "#941F14 " },
      /* { data: this.lubbock, label: 'Lubbock', stack: 'a', backgroundColor: "#B22618", hoverBackgroundColor: "#B22618" },
       { data: this.midland, label: 'Midland', stack: 'a', backgroundColor: "#CF2C1C", hoverBackgroundColor: "#CF2C1C" },
       { data: this.medicine, label: 'Medicine', stack: 'a', backgroundColor: "#ED3220", hoverBackgroundColor: "#ED3220" }*/

    ];
  }

  //stack school chart
  public barChartOptionsSchool: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
      callbacks: {
        footer: (tooltipItems, data) => {
          let total = tooltipItems.reduce((a, e) => a + parseInt(e.value), 0);
          return 'Total: ' + total;
        }
      },
      titleFontSize: 25,
      bodyFontSize: 15,
      footerFontSize: 20
    }
  };
  public barChartLabelsSchool: Label[] = ['2017', '2018', '2019', '2020', '2021'];
  public barChartTypeSchool: ChartType = 'bar';
  public barChartLegendSchool = true;
  public barChartPluginsSchool = [];

  public barChartDataSchool: ChartDataSets[];
}
