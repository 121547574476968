import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-ods-reports',
  templateUrl: './ods-reports.component.html',
  styleUrls: ['./ods-reports.component.css']
})
export class OdsReportsComponent implements OnInit {
  loadingIndicator: boolean = false;
  disableButton: boolean = false;
  clearingHouseReportForm: FormGroup;
  acadYears = ['2025', '2024', '2023', '2022'];
  semesters = ['SPRING', 'SUMMER', 'FALL'];
  branches = ['00', '81', '83', '85', '87', '88'];
  hps = ['HP1', 'HP2', 'BOTH'];
  showHP = false;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.clearingHouseReportForm = new FormGroup({
      acadYear: new FormControl('', Validators.required),
      semester: new FormControl('', Validators.required),
      branch: new FormControl('', Validators.required),
      hp: new FormControl({ value: '', disabled: true }, Validators.required)
    });

    this.onBranchChange();
  }

  onBranchChange(branch: string = '') {
    this.showHP = branch === '00';
    if (!this.showHP) {
      this.clearingHouseReportForm.get('hp').setValue('');
      this.clearingHouseReportForm.get('hp').disable();
    } else {
      this.clearingHouseReportForm.get('hp').enable();
    }
  }

  submitForm() {
    if (this.allDropdownsSelected() && !this.disableButton) {
      this.reportDownload_form_submit();
    }
  }

  allDropdownsSelected(): boolean {
    return (
      this.clearingHouseReportForm.get('acadYear').valid &&
      this.clearingHouseReportForm.get('semester').valid &&
      this.clearingHouseReportForm.get('branch').valid &&
      (!this.showHP || this.clearingHouseReportForm.get('hp').valid)
    );
  }
  timeoutError: boolean = false;
  // This function will be triggered when you submit the form
  async reportDownload_form_submit() {
    const year = this.clearingHouseReportForm.get('acadYear').value
    const sem = this.clearingHouseReportForm.get('semester').value;
    const branch = this.clearingHouseReportForm.get('branch').value;
    let program = 'BLANK';

    // Check if the selected branch is '00'. If so, use the selected HP value; otherwise, leave program as an empty string.
    if (branch === '00') {
      program = this.clearingHouseReportForm.get('hp').value;
    }

    // Get the current date in the specified format
    const currentDate: string = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }).replace(/\//g, '');

    // Modify the file name based on the provided variables and current date
    const fileName: string = `CH-${branch}-${sem}-${currentDate}.xlsx`;

    // Set the timeout duration (in milliseconds)
    const timeoutDuration = 2500; // 2.5 seconds

    // Start the timer
    const timeoutTimer = setTimeout(() => {
      console.warn('Excel download is taking too long.');
      // Handle timeout message here (e.g., show a message to the user)
      this.timeoutError = true;
    }, timeoutDuration);

    try {
      this.loadingIndicator = true;
      this.disableButton = true;
      const response = await this.http.get('dashboard/studentapi/ods/' + year + '/' + sem + '/' + branch + '/' + program + '/exportToExcel', {
        responseType: 'blob'
      }).toPromise();

      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = fileName;
      anchor.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error occurred during the download:', error);
    } finally {
      // Set loading indicator to false when the download is complete (or if an error occurs)
      this.loadingIndicator = false;
      this.disableButton = false;
      clearTimeout(timeoutTimer);
      this.timeoutError = false;
    }
  }

}
