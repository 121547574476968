import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { SharedService } from './Services/students.service';
import { Router, NavigationStart, Event as NavigationEvent, GuardsCheckStart, GuardsCheckEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { AuthorizationGuardService } from './Services/authorization-guard.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  rNumber: any;
  navBar: boolean = true;
  infoTabLoading: boolean = true;
  event$
  ipAddress = '';
  urlValues: any;
  validOrNot: any;
  cookieValue: any;
  roleDateCheck: any;
  ip: any;
  currentCookie: any;
  constructor(private http: HttpClient, private data: SharedService, private router: Router,
    private cookies: CookieService, @Inject(DOCUMENT) private document: Document, private authGaurd: AuthorizationGuardService) {
    console.log(this.router.events);
    this.event$
      = this.router.events
        .subscribe(
          async (event: NavigationEvent) => {
            console.log(event);
            if (event instanceof GuardsCheckStart) {
              this.infoTabLoading = true;
              console.log("GuardStart")
            }
            if (event instanceof GuardsCheckEnd) {

              this.infoTabLoading = false;
              console.log("GuardEnd")
            }
            if (event instanceof NavigationStart) {
              console.log("inside");
              console.log(event.url);
              if (event.url.substring(0, 11) == '/index.html' || event.url.substring(5, 16) == '/index.html') {
                this.urlValues = event.url;
                const elu = this.urlValues.split("elu=")[1].split("&")[0]
                console.log("going in");
                localStorage.setItem('elu', elu);
                console.log('localstorageElu', localStorage.getItem('elu'));
                this.getRnumberFromElu(elu);
                this.router.navigate(['/welcome']);
                console.log("going out");
              }
            }
          });
    //disable this while publishing(used to emulate users in local for issues)
    /*localStorage.setItem('elu', 'adnaik');
    this.getRnumberFromElu('adnaik');*/
  }

  ngOnInit() {
    this.data.currentRNum.subscribe(message => this.rNumber = message)
  }

  async getRnumberFromElu(elu: any) {
    console.log('came to broadcast getRnumberFromElu');
    this.data.updateElu(elu);
  }
}
