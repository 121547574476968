<div  style="margin-top: 20px;" class="container">
  <h1>Institutional Support</h1>
  <form [formGroup]="userIDForm" (ngSubmit)="eremrnoSubmit()">
    <table>
      <tr>
        <td>
          <mat-form-field style="width:365px;" class="example-form">
            <input type="text"
                   #rNumber
                   placeholder="RNumber, eRaider, or Email"
                   aria-label="Number"
                   matInput
                   (keydown.enter)="onKeyRNumEnter($event)"
                   (keyup)="onKeyeremrno($event)"
                   formControlName="userID">
            <button *ngIf="userIDForm.get('userID').value" type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="userIDForm.get('userID').setValue('');onKeyClear();"><mat-icon>close</mat-icon></button>
          </mat-form-field>
        </td>
      </tr>
      <tr> <td><button class="btn btn-dark" type="submit">Search</button></td></tr>
    </table>
    <br />
    <div *ngIf="!formValid" class="alert alert-warning" style="width:365px;">
      Please enter a valid input.
    </div>
    <div *ngIf="!hideNoRecords" style="width:365px;">
      <p>No records found</p>
    </div>
  </form>
</div>
<br />
<div *ngIf="!hideData" class="container">
  <form [formGroup]="allMatForm" (ngSubmit)="allMatSubmit()">
    <mat-card class="mat-card-wide">
      <mat-card-subtitle><b>Student Information</b></mat-card-subtitle>
      <mat-card-title class="common-div"><b><u>{{IsData[0]?.RNUMBER}}</u></b></mat-card-title>
      <div class="row common-div">
        <div class="col-md">
          <b>Name : </b> {{IsData[0]?.FULLNAME}}<br />
          <b>Email : </b> {{IsData[0]?.EMAIL}}<br />
          <b>Address : </b> {{IsData[0]?.ADDRESS}}<br />
          <b>Program : </b> {{IsData[0]?.PROGRAM}}<br />
          <b>Last Active Term : </b> {{IsData[0]?.LAST_ACTIVE}}<br />
          <b>Start Date : </b> {{IsData[0]?.ENROLLMENT_START_DATE}}<br />
          <b>End Date :</b> {{IsData[0]?.ENROLLMENT_END_DATE}}<br />
        </div>
        <div class="col-md">
          <b>Classification :</b> {{IsData[0]?.CLASSIFICATION}}<br />
          <b>Graduation Date :</b> {{IsData[0]?.GRADUATION_DATE}}<br />
          <b>Phone Number</b>
          <ul>
            <li><b>LO :</b>  {{IsData[0]?.LO_PHONE}}</li>
            <li><b>PR :</b>  {{IsData[0]?.PR_PHONE}}</li>
            <li><b>CELL :</b>{{IsData[0]?.CELL_PHONE}}</li>
            <li><b>WK :</b>  {{IsData[0]?.WK_PHONE}}</li>
          </ul>
        </div>
      </div>
    </mat-card>
    <mat-card *ngIf="!relationCodeValues.length==0" class="mat-card-wide">
      <mat-card-subtitle>Soafolk</mat-card-subtitle>
      <div class="common-div">
        <mat-form-field style="width:200px;" appearance="outline">
          <mat-label>Select Relation Code</mat-label>
          <mat-select [(value)]="selectedSoafolkVal">
            <mat-option *ngFor="let relationCodes of relationCodeValues" [value]="relationCodes.SORFOLKRELT" (onSelectionChange)="soafolkSelect($event)">{{relationCodes.SORFOLKRELTDESC}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row common-div">
        <div *ngIf="soaFolkRelaionCode && soaFolkRelaionCode.length > 0" class="col-sm">
          <div class="row">
            <div *ngFor="let item of soaFolkRelaionCode" class="col-md">
              <b>Name: </b> {{item.SOAFOLKPARENTNAME}}<br />
              <b>Address: </b> {{item.ADDRESS}}<br />
              <b>Phone Number: </b> {{item.PHONENUMBER}}<br />
            </div>

          </div>
        </div>
        <div class="row common-div" *ngIf="soaFolkRelaionCode.length == 0">
          <p><b>Information not Available</b></p>
        </div>
      </div>
    </mat-card>
    <mat-card *ngIf="!emergencyContactValues.length==0" class="mat-card-normal">
      <mat-card-subtitle>Emergency Contact (Address)</mat-card-subtitle>
      <div class="common-div">
        <mat-form-field style="width:200px;" appearance="outline">
          <mat-label>Select Priority Number</mat-label>
          <mat-select [(value)]="selectedEmergVal">
            <mat-option *ngFor="let emergencyContacts of emergencyContactValues" [value]="emergencyContacts.PRIORITYCODE" (onSelectionChange)="emergPrioritySelect($event)">{{emergencyContacts.PRIORITYCODE}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="emergPriorityCode && emergPriorityCode.length > 0" class="row common-div">
        <div class="col-sm">
          <table>
            <tr class="example-row">
              <td>
                <b>Name : </b>
              </td>
              <td>
                {{emergPriorityCode[0]?.FULLNAME}}
                <br />
              </td>
            </tr>
            <tr class="example-row">
              <td>
                <b>Address : </b>
              </td>
              <td>
                {{emergPriorityCode[0]?.ADDRESS}}
                <br />
              </td>
            </tr>
            <tr class="example-row">
              <td>
                <b>Phone Number : </b>
              </td>
              <td>
                {{emergPriorityCode[0]?.PHONE}}
                <br />
              </td>
            </tr>
          </table>
        </div>
      </div>
    </mat-card>
  </form>
</div>

