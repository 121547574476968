import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface DialogInfo {
  modalMsg: string;
  hideButton: boolean;
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  message: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    private dialogRef: MatDialogRef<NotificationsComponent>) {
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // If modal just displays "Error" then this did not update, confirm msg was passed properly
    document.getElementById('pageMsg').innerHTML = this.data.modalMsg;
    if (this.data.hideButton) { document.getElementById('btnOK').style.display = "none"; }
    else { document.getElementById('btnOK').style.display = ""; }
  }

  updateText(newText: string) {
    var i = 0;
    document.getElementById('pageMsg').innerHTML = newText;
  }
}
