import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './students.service';
import { take, map, zip, tap, flatMap } from 'rxjs/operators';
import { Observable, forkJoin, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuardService implements CanActivate {
  cookie: any;
  localStorageElu: string = 'elu';
  constructor(private http: HttpClient, private router: Router, private data: SharedService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    //comments - getting the elu(username entered) and if its null then redirect to login page OR if not null get the roles data and allow users to enter websites
    const elu = localStorage.getItem(this.localStorageElu);
    console.log('elu->', elu)
    if (elu == '' || elu == null) {
      window.location.href = 'https://eraider.ttuhsc.edu/signin.asp?redirect=https://registrar.ttuhsc.edu/dashboard/index.html';
    }
    return this.http.get('dashboard/studentapi/studentdetail/cookieDetails/elu/' + elu, { responseType: 'text' })
      .pipe(take(1), map(roles => {
        this.data.setUsername(elu);
        console.log('cookie->', roles)
        const rolesData = JSON.parse(roles)[0]
        this.data.updateRoles(rolesData);
        //check if cookie is present and it means user is valid come inside
        if (state.url.slice(1, state.url.length) == 'roles') {
          if (rolesData.HSCREG_USER_ROLES == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (state.url.slice(1, state.url.length) == 'crn-access') {
          if (rolesData.HSCREG_USER_CRNACCESS == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (state.url.slice(1, state.url.length) == '') {
          if (rolesData.HSCREG_USER_DASHBOARD == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'roster') {
          if (rolesData.HSCREG_USER_ROSTER == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'grade') {
          if (rolesData.HSCREG_USER_GRADES == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'deans-dashboard') {
          if (rolesData.HSCREG_USER_DEANSDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'work-board') {
          if (rolesData.HSCREG_USER_WORKBOARD == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'transfer-board') {
          if (rolesData.HSCREG_USER_TRANSFERBOARD == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'institutional-support') {
          if (rolesData.HSCREG_USER_INSSUPPORT == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (state.url.slice(1, state.url.length) == 'enrollment-dashboard') {
          if (rolesData.HSCREG_USER_ENROLLMENTDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (state.url.slice(1, state.url.length) == 'graduation-dashboard') {
          if (rolesData.HSCREG_USER_GRADDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'school-graduation-dashboard') {
          if (rolesData.HSCREG_USER_SCHOOLGRADDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'combined-graduation-dashboard') {
          if (rolesData.HSCREG_USER_COMBINEDGRADDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'residency-dashboard') {
          if (rolesData.HSCREG_USER_RESIDENCYDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        } else if (state.url.slice(1, state.url.length) == 'ods-reports') {
          if (rolesData.HSCREG_USER_ODSREPORTSDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        else if (state.url.slice(1, state.url.length) == 'dualdegree-dashboard') {
          if (rolesData.HSCREG_USER_DUALREPORTSDASH == 'YES') {
            return true;
          }
          this.router.navigate(['/unauthorized']);
          return false;
        }
        return true;
      }))
  }
}

