import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../Services/students.service';

@Component({
  selector: 'app-school-graduation-dashboard',
  templateUrl: './school-graduation-dashboard.component.html',
  styleUrls: ['./school-graduation-dashboard.component.css']
})
export class SchoolGraduationDashboardComponent implements OnInit {
  rNumber: any;
  nameList: any;
  rNumList: any;
  combinedList: any;
  selectedQuantity: any;
  termCodesList: any;
  allLevels: any;
  allPrograms: any;
  infoTabLoading: boolean = false;
  enrollmentData: any;
  enrollmentTableData: any
  hideData: boolean = true;
  frameworkComponents: any;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  constructor(private http: HttpClient, private toastr: ToastrService, private data: SharedService) { }
  ngOnInit() {
    this.gridOptions = {
      domLayout: 'normal'
    };
    this.http.get('dashboard/studentapi/studentdetail/AllGradDashBoardTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }
  mixed_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'levelCodesListsform': new FormControl('', Validators.required),
    'programCodesListsform': new FormControl('', Validators.required),
  });
  termCodeFieldHasError(): boolean {
    const termControl = this.mixed_form.get('termCodesListsform');
    return termControl.hasError('required') && termControl.touched;
  }
  levelFieldHasError(): boolean {
    const levelControl = this.mixed_form.get('levelCodesListsform');
    return levelControl.hasError('required') && levelControl.touched;
  }
  programFieldHasError(): boolean {
    const programControl = this.mixed_form.get('programCodesListsform');
    return programControl.hasError('required') && programControl.touched;
  }
  termSelect(event: any) {
    if (event.isUserInput) {
      this.getLevels(event.source.value.slice(-6));
    }
  }
  async getLevels(termcode: any) {
    const levels = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/getLevelFromTerm').toPromise();
    this.allLevels = levels;
    this.mixed_form.get('levelCodesListsform').setValue('');
    this.mixed_form.get('programCodesListsform').setValue('');
  }
  levelSelect(event: any) {
    if (event.isUserInput) {
      const termcode = this.mixed_form.get('termCodesListsform').value.slice(-6);
      const level = event.source.value.STVLEVL_CODE;
      this.getPrograms(termcode, level);
    }
  }
  async getPrograms(termcode: any, level: string) {
    const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + level + '/level/getProgramFromTermAndLevel').toPromise();
    this.allPrograms = programs;
    this.mixed_form.get('programCodesListsform').setValue('');
  }
  async schoolGradboard_form_submit() {
    const termcode = this.mixed_form.get('termCodesListsform').value.slice(-6);
    const level = this.mixed_form.get('levelCodesListsform').value.STVLEVL_CODE;
    const program = this.mixed_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1;
    const graduationtableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + level + '/level/' + program + '/program/getSchoolGraduationTableDetails').toPromise();
    this.graduationTable = graduationtableData;
    this.showNoRecordsMessage = this.graduationTable.length === 0;
    if (this.graduationTable.length === 0) {
      this.hideNoRecords = true
      this.hideData = true;
    } else {
      this.rowData = this.graduationTable;
      this.hideData = false;
      this.hideNoRecords = false;
    }
  }
  graduationTable: any;
  showNoRecordsMessage: boolean = false;
  hideNoRecords: boolean = false;
  //School side Graduation Dashboard Table definition
  gridApi: any;
  gridColumnApi: any;
  rowData: any;
  public gridOptions;
  columnDefs = [
    {
      headerName: "",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 60, width: 60
    },
    {
      headerName: 'School Decision',
      field: 'SCHOOL_DECISION_CHECKBOX',
      width: 60, maxWidth: 250,
      cellRenderer: params => {
        const inputElement = document.createElement('input');
        inputElement.type = 'checkbox';
        inputElement.checked = params.value === 'YES';
        inputElement.addEventListener('click', async () => {
          const decision = inputElement.checked ? 'YES' : 'NO';
          params.data.SCHOOL_DECISION_CHECKBOX = decision;
          await this.updateSingleRow(params.data);
        });
        return inputElement;
      }
    },
    {
      headerName: 'R Number',
      field: 'RID', sortable: true, filter: true, width: 150, maxWidth: 145
    },
    {
      headerName: 'Name',
      field: 'NAME', sortable: true, filter: true
    },
    {
      headerName: 'Honor',
      field: 'REGISTRAR_DECISION_HONOR', sortable: true, filter: true
    },
    {
      headerName: 'Registrar Decision',
      field: 'REG_DECISION',
      sortable: true,
      filter: true,
      maxWidth: 200,
      cellStyle: function (params) {
        if (params.value === 'NO') {
          return { color: 'red' };
        } else if (params.value === 'YES') {
          return { color: 'green' };
        }
        return null;
      }
    },
    {
      headerName: 'User',
      field: 'USERNAME', sortable: true, filter: true, width: 150, maxWidth: 145
    },   
  ];
  defaultColDef = {
    resizable: true,
    minWidth: 100,
    filter: true,
    sortable: true,
    floatingFilter: true,
    flex: 1
  };
  rowSelection = 'multiple';
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.autoSizeColumns();
  }
  onFirstDataRendered(params) {
    params.api.forEachNode((node) => {
      if (node.data.SCHOOL_DECISION_CHECKBOX === 'YES') {
        node.setSelected(true);
      }
    });
  }
  paginationPageSize = 10;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi.setQuickFilter(globalSearchValue.value);
  }
  isBulkOperation: boolean = false;
  async showInfoToast(): Promise<number | undefined> {
    const activeToast = this.toastr.info("Update in progress, please wait...", 'Info', { timeOut: 0, closeButton: false });
    return activeToast ? activeToast.toastId : undefined;
  }
  async removeToast(toastId: number | undefined): Promise<void> {
    if (toastId !== undefined) {
      this.toastr.remove(toastId);
    }
  }
  async handleRows(decision: string) {
    this.isBulkOperation = true;
    const updatedRows = [];
    const toastId = await this.showInfoToast();
    this.gridApi.forEachNode(node => {
      node.setDataValue('SCHOOL_DECISION_CHECKBOX', decision);
      updatedRows.push(node.data);
    });
    try {
      await this.saveMultipleData(updatedRows);
      await this.removeToast(toastId);
      this.toastr.success("All values updated", 'Success', { timeOut: 2000 });
    } catch (error) {
      await this.removeToast(toastId);
      this.toastr.error(`Error while saving data. Please contact adnaik@ttuhsc.edu`, 'Error', { timeOut: 5000 });
    }
    this.isBulkOperation = false;
  }
  async selectAllRows(decision: string) {
    await this.handleRows(decision);
  }
  async unselectAllRows(decision: string) {
    await this.handleRows(decision);
  }

  /*onSelectionChanged() {
    if (this.isBulkOperation) {
      const selectedNodes = this.gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map(node => node.data);
      if (selectedData.length > 0) {
        this.saveMultipleData(selectedData)
          .then(() => {
            let message = selectedData.length > 1 ? "Values updated" : 'Value updated';
            this.toastr.success(message, 'Success', { timeOut: 2000 });
          })
          .catch(error => {
            this.toastr.error(`Error while saving data. Please contact adnaik@ttuhsc.edu`, 'Error', { timeOut: 5000 });
          });
      }
      this.isBulkOperation = false;
      return;
    }
    else {
      this.isBulkOperation = false;
    }
  }*/
  async saveMultipleData(dataArray) {
    let api = 'dashboard/studentapi/studentdetail/saveSchoolGradDecisionsData';
    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };

    let bulkDataToSend = dataArray.map(data => {
      return {
        'RID': data.RID,
        'TERM': this.mixed_form.get('termCodesListsform').value.slice(-6),
        'SCHOOL_DECISION_CHECKBOX': data.SCHOOL_DECISION_CHECKBOX === 'YES' ? 'YES' : 'NO',
        'USERNAME': this.data.getUsername()
      };
    });
    var responseData = await this.http.post(api, bulkDataToSend, options).toPromise();
    if (responseData['status'] !== 'complete') {
      throw new Error('Error saving data');
    }
  }
  async updateSingleRow(data) {
    const payload = [{
      'RID': data.RID,
      'TERM': this.mixed_form.get('termCodesListsform').value.slice(-6),
      'SCHOOL_DECISION_CHECKBOX': data.SCHOOL_DECISION_CHECKBOX,
      'USERNAME': this.data.getUsername()    
    }];
    let api = 'dashboard/studentapi/studentdetail/saveSchoolGradDecisionsData';
    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    const toastId = await this.showInfoToast();
    try {
      const responseData = await this.http.post(api, payload, options).toPromise();
      if (responseData['status'] !== 'complete') {
        throw new Error('Error saving data');
      }
      await this.removeToast(toastId);
      this.toastr.success('Value updated', 'Success', { timeOut: 2000 });
    } catch (error) {
      await this.removeToast(toastId);
      this.toastr.error(`Error while saving data. Please contact adnaik@ttuhsc.edu`, 'Error', { timeOut: 5000 });
    }
  }

}
