<div style="margin-top: 20px;" class="container-fluid">
  <h2>Audit Board</h2>
  <br />
  <form [formGroup]="workboard_form" (ngSubmit)="workboard_submit()">
    <div class="row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <mat-option *ngFor="let termcode of termCodesList;let i =index" [value]="termcode.BACKVALUE_NAME" (onSelectionChange)="termSelect($event)">{{termcode.FRONTVALUE_NAME}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field style="width:500px;" appearance="outline">
                  <mat-label>Select Program</mat-label>
                  <mat-select formControlName="programCodesListsform">
                    <mat-option *ngFor="let programs of allPrograms;let i =index" [value]="programs">{{programs.SGBSTDN_PROGRAM_1}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td><button class="btn btn-dark" type="submit">Submit</button></td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 20px 20px 20px 4px;" [hidden]="!dropdownSelectError">
          <p>Please select either one or both drop downs.</p>
        </div>
        <div style="padding: 20px 20px 20px 4px;" [hidden]="!hideNoRecords">
          <p>No records found. Please try with another Term.</p>
        </div>
      </div>
    </div>
  </form>
  <br />
  <form [formGroup]="workboardDetailsTable_form">
    <div *ngIf="infoTabLoading" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    <div [hidden]="!hidePagination">
      <table class="table-borderless">
        <tbody>
          <tr>
            <td>
              <div *ngIf="workFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ workFilters[0].name }}</mat-label>
                  <mat-select  [(value)]="workFilters[0].defaultValue"
                              (selectionChange)="applyWorkFilter($event, workFilters[0])">
                    <mat-option *ngFor="let op of workFilters[0].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div *ngIf="workFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ workFilters[1].name }}</mat-label>
                  <mat-select  [(value)]="workFilters[1].defaultValue"
                              (selectionChange)="applyWorkFilter($event, workFilters[1])">
                    <mat-option *ngFor="let op of workFilters[1].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div *ngIf="workFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ workFilters[2].name }}</mat-label>
                  <mat-select  [(value)]="workFilters[2].defaultValue"
                              (selectionChange)="applyWorkFilter($event, workFilters[2])">
                    <mat-option *ngFor="let op of workFilters[2].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div *ngIf="workFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ workFilters[3].name }}</mat-label>
                  <mat-select  [(value)]="workFilters[3].defaultValue"
                              (selectionChange)="applyWorkFilter($event, workFilters[3])">
                    <mat-option *ngFor="let op of workFilters[3].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td><div><button class="btn btn-dark" (click)="clearFilters()">Clear All Filters</button></div></td>
          </tr>
        </tbody>
      </table>
      <div style="float:left">
        <mat-form-field appearance="standard">
          <mat-label>Search Audit-Board Data</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex.R123456789" #inputo>
        </mat-form-field>
      </div>
      <div class="example-header" style="float:right">
        <mat-paginator #myPaginator [pageSizeOptions]="[5,10,15]" showFirstLastButtons></mat-paginator>
      </div>
      <div class="mat-elevation-z8" style="clear: both;">
        <mat-table #table
                   [dataSource]="dataSource"
                   class="table">
          <!-- Common Column -->
          <ng-container matColumnDef="COMMON">
            <mat-header-cell *matHeaderCellDef> AUDIT-BOARD LIST </mat-header-cell>
            <mat-cell *matCellDef="let WorkItem; let i = index">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6 col-md-4">
                    <b>R Number:</b> {{WorkItem.RNUMBER}} <br />
                    <b>Full Name:</b> {{WorkItem.FULLNAME}}<br />
                    <b>Gender:</b> {{WorkItem.GENDER}} <br /><br />
                    <b>Classification:</b> <span [style.background-color]="WorkItem.CLASSIFICATION == 'NULL'?'#ff4040':null">{{WorkItem.CLASSIFICATION}}</span><br />
                    <b>Level:</b> {{WorkItem.LEVELL}}<br /><br /><br />
                    <div style="display: flex; align-items: center;">
                      <b>HVAD: </b><span [style.background-color]="WorkItem.HVAD == 'NULL'?'#ff4040':null"> {{WorkItem.HVAD}}</span>
                      <button *ngIf="WorkItem.HVAD !== 'HVAD'" class="btn btn-dark" style="margin-left: 75px; font-size: 80%; padding: 4px 8px; line-height: 1.0;" (click)="InsertHvad(WorkItem.RNUMBER, WorkItem.TERM)">Add HVAD</button>
                    </div>
                    <b>HVAD Term:</b> {{WorkItem.HVAD_TERM}}<br /><br />
                    <b>Drop Count Limit:</b> {{WorkItem.DROP_COUNT_LIMIT}}<br />
                    <b>Drop Count:</b> {{WorkItem.DROP_COUNT}}<br /><br />
                    <b>Enrolled:</b> {{WorkItem.ENROLLED}} <br />
                    <b>Student Satus:</b> {{WorkItem.STUDENT_STATUS}}<br />
                    <div style="position: relative; left: -15px;"><a mat-button (click)="openDialog(WorkItem.RNUMBER)"><b>Active Holds:</b></a>{{WorkItem.HOLD}} </div>
                    <div style="float: left; margin-right: 5px;margin-bottom: 2px;">
                      <button class="btn btn-dark" type="submit" (click)=showXtenderDocumentType(WorkItem.RNUMBER)>Display Xtender Documents</button>
                    </div>
                    <div style="float: left; margin-right: 5px;margin-bottom: 2px;">
                      <button class="btn btn-dark" type="submit" (click)=uploadXtenderDocument(WorkItem.RNUMBER,WorkItem.TERM)>Upload Xtender Document</button>
                    </div>
                    <div style="float: left;">
                      <button class="btn btn-dark" type="submit" (click)="openEditModal(WorkItem.RNUMBER,WorkItem.TERM)">Update</button>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                  <div class="col-6 col-md-4">
                    <b>Term:</b> {{WorkItem.TERM}}<br />
                    <b>Residency:</b> {{WorkItem.RESIDENCY}}<br />
                    <b>Citizenship:</b> {{WorkItem.CITIZENSHIP}}<br /><br />
                    <b>Program:</b> {{WorkItem.PROGRAM_CODE_WITHDESC}}<br />
                    <b>Concentration:</b> {{WorkItem.CONCENTRATION}}<br />
                    <b>Campus:</b> {{WorkItem.CAMPUS}}<br /><br />
                    <b>Grad Date:</b> {{WorkItem.GRAD_DATE}} <br />
                    <b>Grad Term: </b> <span [style.background-color]="WorkItem.GRAD_TERM == 'NULL'?'#ff4040':null">{{WorkItem.GRAD_TERM}}</span><br /><br />
                    <b>TSI Status:</b> {{WorkItem.TSI_STATUS}}<br />
                    <b>Core Complete:</b> {{WorkItem.CORE_COMPLETE}}<br /><br />
                    <b>SGASTDN create Date:</b> {{WorkItem.SGBSTDN_CREATE_DATE}}<br />
                    <b>Latest Admit Decision:</b> {{WorkItem.LATEST_ADMIT_DECISION}}<br />                 
                  </div>
                  </div>
                <br />
                <div class="row" style="position: relative; left: -15px;">
                  <div class="col-6">
                    <app-work-board-ferpa-table [rowDataCurrentRnumberFerpa]="WorkItem.RNUMBER"></app-work-board-ferpa-table>
                  </div>
                </div>
                <br />
                <div class="row" style="position: relative; left: -15px;">
                  <div class="col">
                    <app-work-board-table [WorkItemHoldData]="WorkItem" [rowDataSelectedTerm]="selectedTerm" [rowDataCurrentRnumber]="WorkItem.RNUMBER"></app-work-board-table>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div style="padding: 20px 20px 20px 4px;" [hidden]="!hideFilteredError">
        <p>No records found after filtering. Please try again with another filter.</p>
      </div>
      <br />
      <br />
      <br />
    </div>
    <br />
  </form>
</div>
