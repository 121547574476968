<div style="height: 275px; width: 100%; margin: auto; margin-left:14px; margin-right:2px; overflow:auto">
  <ag-grid-angular style="height: 275px;"
                   class="ag-theme-alpine"
                   [rowData]="rowData"
                   [columnDefs]="columnDefs"
                   [defaultColDef]="defaultColDef"
                   [pagination]="false"
                   [paginationPageSize]="5"
                   [rowSelection]="rowSelection"
                   [rowMultiSelectWithClick]="true"
                   (gridReady)="onGridReady($event)"
                   [gridOptions]="gridOptions"
                   (cellValueChanged)="onCellValueChanged($event)"
                   (firstDataRendered)="onFirstDataRendered($event)">
  </ag-grid-angular>
</div>
