<div>
  <h5>
    <b>R Number: </b>{{Rnumber}}
  </h5>
</div>
<form [formGroup]="documentType_form" [hidden]="!hideUploadForm">
  <table class="table-borderless">
    <tbody>
      <tr>
        <td>
          <mat-form-field style="width:400px;" appearance="outline">
            <mat-label>Document Type</mat-label>
            <mat-select formControlName="_documentType" required>
              <mat-option *ngFor="let xDocumentype of xDocumentypeList" [value]="xDocumentype" (onSelectionChange)="xDocumentypeSelect($event)">{{xDocumentype}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td> <span style="color: red;" *ngIf="documentType && documentType.invalid && documentType.touched">Please select Document Type</span></td>
      </tr>
      <tr>
        <td>
          <mat-form-field style="width:400px;" appearance="outline">
            <mat-label>College Type</mat-label>
            <mat-select formControlName="_collegeType" required>
              <mat-option *ngFor="let xAcademicCollege of xAcademicCollegeList" [value]="xAcademicCollege" (onSelectionChange)="xAcademicCollegeSelect($event)">{{xAcademicCollege}}</mat-option>
            </mat-select>
          </mat-form-field>
         </td>
        <td> <span style="color: red;" *ngIf="collegeType && collegeType.invalid && collegeType.touched">Please select College Type</span></td>
      </tr>
      <tr>
        <td>
          <mat-form-field class="example-full-width">
            <mat-label>Enter Description</mat-label>
            <textarea formControlName="description" matInput placeholder="Description" required></textarea>
          </mat-form-field>
        </td>
        <td> <span style="color: red;" *ngIf="description && description.invalid && description.touched">Please enter description</span></td>
      </tr>
      <tr>
        <td>
          <input #fileInput formControlName="file" id="ngexcelfile" (change)="onFileSelected($event)" type="file" accept="application/pdf" />
          <br />
          <br />
          <button class="btn btn-dark" *ngIf="!disableSubmitButton" (click)="Upload()">Submit</button>
          <div *ngIf="disableSubmitButton"><div class="lds-ring"><div></div><div></div><div></div><div></div></div><br /><br />Processing, please be patient...</div>
        </td>
      </tr>

    </tbody>
  </table>
</form>
<div [hidden]="hideErrorUploadMessage">
  <p>Error occured while uploading documents, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu.</p>
  <div style="position: relative; left: -15px;" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Close</button>
  </div>
</div>
<br />
<div [hidden]="hideSuccessUploadMessage">
  <div><p>Document Uploaded Sucessfully</p></div>
  <div style="position: relative; left: -15px;" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Close</button>
  </div>
</div>
