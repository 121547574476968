import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ButtonGroupActionRendererComponent } from '../Button Renderer/button-group-action-renderer/button-group-action-renderer.component';
import { EnrollmentPromptModalComponent } from '../allmodals/enrollment-prompt-modal/enrollment-prompt-modal.component';

@Component({
  selector: 'app-enrollment-dashboard',
  templateUrl: './enrollment-dashboard.component.html',
  styleUrls: ['./enrollment-dashboard.component.css']
})
export class EnrollmentDashboardComponent implements OnInit {
  enrollmentTermCodesList: any;
  rNumber: any;
  ssnList: any;
  rNumList: any;
  combinedList: any;
  selectedQuantity: any;
  hideTermPick: boolean = false;
  termNotFoundPick: boolean = false;
  hideNoRecords: boolean = false;
  hideSavedCrn: boolean = false;
  hideUnsavedCrn: boolean = false;
  infoTabLoading: boolean = false;
  enrollmentData: any;
  enrollmentTableData: any
  hideData: boolean = true;
  frameworkComponents: any;
  
  constructor(private http: HttpClient, public dialog: MatDialog) {
    this.frameworkComponents = {
      actionButtonRenderer: ButtonGroupActionRendererComponent
    }
    this.combinedList = []; 
  }

  ngOnInit() {
    this.gridOptions = {
      domLayout: 'normal'
    };
  }
  //Transfer board Table definition
  buttonDeleteRendererConfig = {
    buttonText: 'View',
    buttonClass: 'btn btn-dark'
  };
  gridApi: any;
  gridColumnApi: any;
  rowData: any;
  public gridOptions;
  columnDefs = [
    {
      headerName: 'Term Code',
      field: 'TERM_CODE', sortable: true, filter: true, rowGroup: true, hide: true ,
      maxWidth: 160, minWidth: 100
    }, {
      headerName: 'Action',
      cellRenderer: 'actionButtonRenderer',
      cellRendererParams: {
        isChildRow: (params) => {
          return !params.node.group;
        },
        onClick: this.ViewTerm.bind(this),
        config: this.buttonDeleteRendererConfig,
      },
    },
    {
      headerName: 'Hours Enrolled',
      field: 'HOURS_ENROLLED', sortable: true, filter: true,
      maxWidth: 145
    },{
      headerName: 'Student Status',
      field: 'STUDENT_STATUS', sortable: true, filter: true,
      maxWidth: 160, minWidth: 100
    },{
      headerName: 'Time Status Code',
      field: 'SFRTHST_TMST_CODE',
      sortable: true,
      filter: true,
      maxWidth: 160,
      minWidth: 100
    },{
      headerName: 'Time Status Date',
      field: 'SFRTHST_TMST_DATE',
      sortable: true,
      filter: true,
      maxWidth: 160,
      minWidth: 100
    }
  ];
  defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection = 'single';
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  onRowDataChanged() {
    if (this.gridApi) {
      this.gridApi.forEachNodeAfterFilterAndSort((node: any) => {
        if (node.group) {
          node.setExpanded(true);
        }
      });
    }
  }
  paginationPageSize = 20;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi.setQuickFilter(globalSearchValue.value);
  }

  rNum_form = new FormGroup({
    '_rNumber': new FormControl('', Validators.required)
  });
  termpick_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required)
  });
  get rNumberForm() {
    return this.rNum_form.get('_rNumber');
  }

  onKeyClear() {
    this.rNumber = '';
    this.termNotFoundPick = false;
    this.hideTermPick = false;
    this.hideUnsavedCrn = false;
    this.hideSavedCrn = false;
    this.hideNoRecords = false;
    this.hideData = true;
  }
  onKeyRNum(event: any) {
    if (event.target.value == '') {
      this.rNumber = '';
      this.termNotFoundPick = false;
      this.hideTermPick = false;
      this.hideUnsavedCrn = false;
      this.hideSavedCrn = false;
      this.hideNoRecords = false;
      this.hideData = true;
    } else {
      if (event.target.value.startsWith('R')) {
        // Make API call for RNumber
        this.http.get('dashboard/studentapi/studentdetail/' + event.target.value + '/user/userrolernumber').subscribe(
          response => {
            this.rNumList = response;
            this.combinedList = [...this.rNumList]; // update combined list
          },
          error => {
            console.log(error);
          })
      } else {
        // Make API call for SSN
        this.http.get('dashboard/studentapi/studentdetail/' + event.target.value + '/user/userssn').subscribe(
          response => {
            this.ssnList = response;
            this.combinedList = [...this.ssnList]; // update combined list
          },
          error => {
            console.log(error);
          })
      }
      if (this.rNum_form.invalid) {
        this.rNumber = '';
      }
    }
  }
  onKeyRNumEnter(event: any) {
    this.rNum_form.controls['_rNumber'].markAsTouched();
  }
  rNumSelect(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value);
      var value = event.source.value;

      // Declare rNumber to be used in the API calls
      var rNumber;

      // Check if the value contains an SSN or RNUMBER
      if (value.includes('(') && value.includes(')')) {
        // This is an SSN, so we extract the RNumber from the brackets 
        rNumber = value.slice(value.indexOf("(") + 1, value.indexOf(")"));
        this.selectedRNumber = rNumber; 
      } else {
        // This is an RNUMBER, so we extract it from the start of the string
        rNumber = value.split(' - ')[0];
        this.selectedRNumber = rNumber; 
      }

      if (rNumber.startsWith('R') && rNumber.length == 9) {
        this.getDropDownValues(rNumber);
      } else {
        console.log("Invalid value selected");
      }
    } else {
      console.log("Unexpected event: " + event);
    }
  }
  selectedRNumber: any
  async getDropDownValues(id: any) {
    const enrollmenttableData = await this.http.get('dashboard/studentapi/studentdetail/' + id + '/termCodes').toPromise();
    this.enrollmentTermCodesList = enrollmenttableData;
    if (this.enrollmentTermCodesList.length === 0) {
      this.termNotFoundPick = true;
      this.hideTermPick = false;
      this.hideData = true;
    } else {
      this.termNotFoundPick = false;
      this.hideTermPick = true;
      this.hideData = false;
      this.termpick_form.controls['termCodesListsform'].setValue(this.enrollmentTermCodesList[0]);
      this.selectedQuantity = this.enrollmentTermCodesList[0].substring(0, 6);
      this.populateEnrollmentValues(this.selectedQuantity, id);
    }
  }
  additionalPrograms: string[] = [];

  async populateEnrollmentValues(maxTerm: any, id :any) {
    
    const enrollmentValues = await this.http.get('dashboard/studentapi/studentdetail/' + id + '/rno/' + maxTerm + '/termcode/getEnrollmentDetails').toPromise();
    this.enrollmentData = enrollmentValues;
    
    // After fetching the data from the API
    this.additionalPrograms = this.enrollmentData[0]?.AWARDED_DEGREES_AND_TERM.split(', ');
    const enrollmentTableValues = await this.http.get('dashboard/studentapi/studentdetail/' + id + '/rno/getEnrollmentTableDetails').toPromise();
    this.rowData = enrollmentTableValues;
  

    if (this.enrollmentData.length !== 0 || this.rowData.length !== 0) {
 

      this.hideData = false;
    } else {
      this.hideData = true;
    }
  }
  termSelect(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value.substring(0, 6));
      this.populateEnrollmentValues(event.source.value.substring(0, 6), this.selectedRNumber);
    }
  }


  //Here when we click the button in a child row, the onClick function in the ButtonGroupActionRendererComponent will call the function that was passed as a parameter, which is ViewTerm in this case, with the rowData(i.e., params.data).
  //This way, we have connected the ViewTerm function of this component with the click event of the button in the child row of the ButtonGroupActionRendererComponent.When you click the button, the ViewTerm function gets called with the correct rowData as its argument.
  async ViewTerm(rowData: any) {
    let rNumberInput = this.rNum_form.get('_rNumber').value;
    let rNumberMatch = rNumberInput.match(/R\d{8}/);
    let rNumber = rNumberMatch ? rNumberMatch[0] : '';
    let dialogRef = this.dialog.open(EnrollmentPromptModalComponent, {
      data: { RNumber: rNumber , Term: rowData.TERM_CODE },
      width: '78%',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
