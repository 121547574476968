<div class="container">
  <div class="example-card">
    <form [formGroup]="dean_form" (ngSubmit)="dean_submit()">
      <div class="row">
        <div class="col-sm">
          <h2>Please Select Term and School</h2>
          <table class="table-borderless">
            <tbody>
              <tr>
                <td><p>Please select Term : </p></td>
                <td style="padding-right: 10px">
                  <mat-form-field appearance="outline">
                    <mat-label>Select Term</mat-label>
                    <mat-select formControlName="termCodesListsform">
                      <mat-option *ngFor="let term of terms" [value]="term.value">{{term.viewValue }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>

                <td style="padding-right: 10px"><p>Please select School : </p></td>
                <td>
                <mat-form-field appearance="outline">
                    <mat-label>Schools</mat-label>
                    <mat-select formControlName="schoolListsform">
                      <mat-option *ngFor="let school of SchoolList" [value]="school.value">{{school.viewValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br>
                </td>
              </tr>

              <tr>
                <td><button class="btn btn-dark" type="submit">Submit</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
  <mat-card class="stack-card">
    <mat-card-title-group>
      <mat-card-title>Graph</mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
      <table class="table-borderless">
        <tbody>
          <tr>
            <th> <h6><u>Headcount By Campus</u> ({{termpops == '' ? 'Spring' : termpops}})</h6></th>
          </tr>
          <tr>
            <td>
              <div *ngIf="infoTabLoadingCampus" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
              <div *ngIf="barChartLegendCampus">
                <canvas baseChart
                        [datasets]="barChartDataCampus"
                        [labels]="barChartLabelsCampus"
                        [options]="barChartOptionsCampus"
                        [plugins]="barChartPluginsCampus"
                        [legend]="barChartLegendCampus"
                        [chartType]="barChartTypeCampus">
                </canvas>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
  </mat-card>
  <br /><br />
</div>
<div class="container">
  <div class="example-card">
    <form [formGroup]="deanPie_form" (ngSubmit)="deanPie_submit()">
      <div class="row">
        <div class="col-sm">
          <h2>Please Select Term (Pie Chart)</h2>
          <table class="table-borderless">
            <tbody>
              <tr>
                <td><p>Please select Term : </p></td>
                <td style="padding-right: 10px">
                  <mat-form-field style="width:390px;"appearance="outline">
                    <mat-label>Select Term</mat-label>
                    <mat-select formControlName="termCodesPieListsform">
                      <mat-option *ngFor="let term of termCodesList" [value]="term.BACKVALUE_NAME">{{term.FRONTVALUE_NAME}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>               
                </td>
              </tr>

              <tr>
                <td><button class="btn btn-dark" type="submit">Submit</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>
  <div [hidden]="!hidePieChart">
    <mat-card class="stack-card">
      <mat-card-title-group>
        <mat-card-title>Graph</mat-card-title>
      </mat-card-title-group>
      <mat-card-content>
        <table class="table-borderless">
          <tbody>
            <tr>
              <th> <h6><u>Headcount By Campus</u> ({{piechartTerm}})</h6></th>
            </tr>
            <tr>
              <td>
                <div *ngIf="infoTabLoadingCampus" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
                <!--<div *ngIf="pieChartLegendCampus">-->
                <canvas class="piechartcanvas" baseChart
                        [data]="pieChartDataCampus"
                        [labels]="pieChartLabelsCampus"
                        [options]="pieChartOptionsCampus"
                        [plugins]="pieChartPluginsCampus"
                        [legend]="pieChartLegendCampus"
                        [chartType]="pieChartTypeCampus"
                        [colors]="colors">
                </canvas>
                <!--</div>-->
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
  <br />
  <br />
</div>
<br />
<br />
