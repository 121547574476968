import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { AppRoutingModule, routingComponents } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentOneComponent } from './component-one/component-one.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastrModule } from 'ngx-toastr';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedService } from './Services/students.service';
import { NavSidebarComponent } from './nav-sidebar/nav-sidebar.component';
import { RosterComponent } from './roster/roster.component';
import { GradeComponent } from './grade/grade.component';
import { MsgmodalComponent } from './allmodals/msgmodal/msgmodal.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RolesComponent } from './roles/roles.component';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CrnAccessComponent } from './crn-access/crn-access.component';
import { CookieService } from 'ngx-cookie-service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { DashboardPromptModalComponent } from './allmodals/dashboard-prompt-modal/dashboard-prompt-modal.component';
import { DeansDashboardComponent } from './deans-dashboard/deans-dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { WorkBoardComponent } from './work-board/work-board.component';
import { InstitutionalSupportComponent } from './institutional-support/institutional-support.component';
import { ButtonDeleteRendererComponent } from './Button Renderer/button-delete-renderer/button-delete-renderer.component';
import { MatRadioModule } from '@angular/material/radio';
import { HoldsPromptModalComponent } from './allmodals/holds-prompt-modal/holds-prompt-modal.component';
import { TransferBoardComponent } from './transfer-board/transfer-board.component';
import { TransferBoardTableComponent } from './transfer-board/transfer-board-table/transfer-board-table.component';
import { XtenderDocumenttypelistModalComponent } from './allmodals/xtender-documenttypelist-modal/xtender-documenttypelist-modal.component';
import { XtenderDocumentuploadModalComponent } from './allmodals/xtender-documentupload-modal/xtender-documentupload-modal.component';
import { ScrollToTopComponent } from './scroll-to-top/scroll-to-top.component';
import { NotificationsComponent } from './allmodals/notifications/notifications.component';
import { EnrollmentDashboardComponent } from './enrollment-dashboard/enrollment-dashboard.component';
import { DeansCampusComponent } from './deans-dashboard/deans-campus/deans-campus.component';
import { DeansLevelComponent } from './deans-dashboard/deans-level/deans-level.component';
import { DeansSchoolComponent } from './deans-dashboard/deans-school/deans-school.component';
import { DeansPiegraphComponent } from './deans-dashboard/deans-piegraph/deans-piegraph.component';
import { WorkBoardTableComponent } from './work-board/work-board-table/work-board-table.component';
import { WorkBoardFerpaTableComponent } from './work-board/work-board-ferpa-table/work-board-ferpa-table.component';
import { ComponentTwoComponent } from './component-one/component-two/component-two.component';
import { ComponentThreeComponent } from './component-one/component-three/component-three.component';
import { ButtonGroupActionRendererComponent } from './Button Renderer/button-group-action-renderer/button-group-action-renderer.component';
import { EnrollmentPromptModalComponent } from './allmodals/enrollment-prompt-modal/enrollment-prompt-modal.component';
import { WorkboardUpdateModalComponent } from './allmodals/workboard-update-modal/workboard-update-modal.component';
import { WorkboardtableDegreeModalComponent } from './allmodals/workboardtable-degree-modal/workboardtable-degree-modal.component';
import { GraduationDashboardComponent } from './graduation-dashboard/graduation-dashboard.component';
import { SchoolGraduationDashboardComponent } from './school-graduation-dashboard/school-graduation-dashboard.component';
import { GraduationDashboardTableComponent } from './graduation-dashboard/graduation-dashboard-table/graduation-dashboard-table.component';
import { CombinedGraduationDashboardComponent } from './combined-graduation-dashboard/combined-graduation-dashboard.component';
import { ResidencyDashboardComponent } from './residency-dashboard/residency-dashboard.component';
import { OdsReportsComponent } from './ods-reports/ods-reports.component';
import { DualdegreeDashboardComponent } from './dualdegree-dashboard/dualdegree-dashboard.component';
@NgModule({
  declarations: [
    AppComponent,
    RosterComponent,
    ComponentOneComponent,
    ComponentTwoComponent,
    ComponentThreeComponent,
    NavSidebarComponent,
    GradeComponent,
    MsgmodalComponent,
    RolesComponent,
    routingComponents,
    CrnAccessComponent,
    UnauthorizedComponent,
    WelcomeComponent,
    PageNotFoundComponent,
    DashboardPromptModalComponent,
    DeansDashboardComponent,
    WorkBoardComponent,
    WorkBoardTableComponent,
    InstitutionalSupportComponent,
    DeansCampusComponent,
    DeansLevelComponent,
    DeansSchoolComponent,
    DeansPiegraphComponent,
    ButtonDeleteRendererComponent,
    HoldsPromptModalComponent,
    WorkBoardFerpaTableComponent,
    TransferBoardComponent,
    TransferBoardTableComponent,
    XtenderDocumenttypelistModalComponent,
    XtenderDocumentuploadModalComponent,
    ScrollToTopComponent,
    NotificationsComponent,
    EnrollmentDashboardComponent,
    ButtonGroupActionRendererComponent,
    EnrollmentPromptModalComponent,
    WorkboardUpdateModalComponent,
    WorkboardtableDegreeModalComponent,
    GraduationDashboardComponent,
    SchoolGraduationDashboardComponent,
    GraduationDashboardTableComponent,
    CombinedGraduationDashboardComponent,
    ResidencyDashboardComponent,
    OdsReportsComponent,
    DualdegreeDashboardComponent,
  ],
  entryComponents: [MsgmodalComponent, DashboardPromptModalComponent],
  imports: [
    MatGridListModule, MatSelectModule, MatFormFieldModule, MatInputModule,
    MatTabsModule, MatExpansionModule, MatButtonModule, MatSidenavModule,
    MatListModule, ReactiveFormsModule, MatDatepickerModule, MatNativeDateModule,
    MatTableModule, MatDialogModule, MatCheckboxModule, MatPaginatorModule,
    MatSortModule, MatSidenavModule, MatToolbarModule, MatSlideToggleModule, MatCardModule,
    MatSnackBarModule, MatProgressSpinnerModule, ToastrModule.forRoot({
      closeButton: true,
      timeOut: 5000, // 5 seconds
      progressBar: true,
    }), FlexLayoutModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule, MatIconModule, StorageServiceModule,
    MatAutocompleteModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ChartsModule,
    AgGridModule.withComponents([]),
    NgMultiSelectDropDownModule.forRoot(),
    MatRadioModule,
    MatMenuModule,
    MatBadgeModule
  ],
  providers: [SharedService, CookieService, { provide: APP_BASE_HREF, useValue: '/dashboard/' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
