<div style="margin-top: 20px;"  class="container">
  <form [formGroup]="toggleFormGroup" (ngSubmit)="onToggleSubmit()">
    <p [ngClass]="{highlightcrncopy: copycrn_div}" style="display: inline-block;padding-right: 8px">Copy CRN</p><mat-slide-toggle [ngClass]="{highlightcrnaccess: crnacess_div}" style="display: inline-block" formControlName="CopyCRN" (change)="toggleChange($event)">CRN Access</mat-slide-toggle>
  </form>
</div>
<div class="container" [hidden]="!copycrn_div">
  <form [formGroup]="copycrn_form" (ngSubmit)="copycrn_submit()">
    <p><b>Pick your School</b></p>
    <mat-radio-group formControlName="radioButtonSchoolform" aria-label="Select School">
      <mat-radio-button *ngFor="let school of schools" [value]="school.value" (change)="radioChange($event)">{{school.viewValue }}</mat-radio-button>
    </mat-radio-group>

    <div class="row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select From Term</mat-label>
                  <mat-select formControlName="fromtermCodeform">
                    <mat-option *ngFor="let fromtermcode of fromTermCodeValues;let i =index" [value]="fromtermcode" (onSelectionChange)="fromtermSelect($event)">{{fromtermcode.TERM}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td style="padding: 50px;">
                <mat-icon>arrow_forward_ios</mat-icon>
              </td>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select To Term</mat-label>
                  <mat-select formControlName="totermCodeform">
                    <mat-option *ngFor="let totermcode of toTermCodeValues;let i =index" [value]="totermcode">{{totermcode.HSCREG_USER_TERM}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div [hidden]="!hideCopyCrn">
      <div *ngIf="infoTabLoading3" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      <div style="margin-bottom: 5px;float:left">
        <button class="btn btn-dark" type="submit">Submit</button>
      </div>
      <div style="margin-bottom: 5px;float:left;clear: left">
        <input type="text"
               (input)="onQuickFilterChanged3()"
               id="quickFilter3"
               placeholder="quick filter..." />
      </div>
      <!--<div class="example-header" style="float:right">
        Page Size:
        <select (change)="onPageSizeChanged3()" id="page-size3">
          <option value="10" selected="">10</option>
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
      </div>-->
      <div style="clear: both;">
        <ag-grid-angular style="width: 1110px; height: 567px;"
                         class="ag-theme-alpine"
                         [modules]="modules"
                         [autoGroupColumnDef]="autoGroupColumnDef"
                         [groupSelectsChildren]="true"
                         [suppressRowClickSelection]="true"
                         [suppressAggFuncInHeader]="true"
                         [rowData]="rowData3"
                         [columnDefs]="columnDefs3"
                         [defaultColDef]="defaultColDef3"
                         [pagination]="true"
                         [paginationPageSize]="paginationPageSize3"
                         [paginationNumberFormatter]="paginationNumberFormatter3"
                         [rowSelection]="rowSelection3"
                         [rowMultiSelectWithClick]="true"
                         (gridReady)="onGridReady3($event)">
        </ag-grid-angular>
      </div>
    </div>
  </form>
</div>
<br />
<div class="container" [hidden]="!crnacess_div">
  <p><b>CRN Access</b></p>
  <!--search box form-->
  <form [formGroup]="rNum_form">
    <table>
      <tr>
        <td>Please Enter Rid: </td>

        <td>
          <mat-form-field class="example-form">
            <input type="text"
                   #rNumber
                   #trigger="matAutocompleteTrigger"
                   placeholder="RID"
                   aria-label="Number"
                   matInput
                   [matAutocomplete]="rNumAuto" (keyup)="onKeyRNum($event)" (keydown.enter)="onKeyRNumEnter($event)"
                   formControlName="_rNumber"
                   [attr.disabled]="isDisabled ? true : null">

            <mat-autocomplete #rNumAuto="matAutocomplete">
              <mat-option *ngFor="let theRNumber of rNumberList" [value]="theRNumber" (onSelectionChange)="rNumSelect($event)">
                {{theRNumber}}
              </mat-option>
            </mat-autocomplete>
            <button *ngIf="rNum_form.get('_rNumber').value" type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="rNum_form.get('_rNumber').setValue('');onKeyClear();"><mat-icon>close</mat-icon></button>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td colspan="2" *ngIf="rNumberForm.touched && rNumberForm.invalid " class="alert alert-danger">
          <p *ngIf="rNumberForm.errors.required">R# is required.</p>
        </td>
      </tr>
    </table>
  </form>
  <p></p>
  <div class="container">
    <div div id="first" class="row">
      <form [formGroup]="termpick_form"
            (ngSubmit)="TermPick_submit()"
            [hidden]="!hideTermPick">

        <table class="table-borderless">
          <tbody>
            <tr>
              <td><p>Please select Term : </p></td>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform" [disabled]="isDisabled ? true : null">
                    <mat-option *ngFor="let termcode of rolesTermCodesList" [value]="termcode" (onSelectionChange)="termSelect($event)">{{termcode.HSCREG_USER_TERM}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <p [hidden]="!termNotFoundPick">R number with terms not found. Please search with a diferent R number.</p>
    </div>
    <div id="second" class="row">
      <div [hidden]="!hideUnsavedCrn">
        <form [formGroup]="crnUnsaved_form" (ngSubmit)="crnUnsaved_submit()">
          <div *ngIf="infoTabLoading" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
          <div style="margin-bottom: 5px;float:left">
            <input type="text"
                   (input)="onQuickFilterChanged()"
                   id="quickFilter"
                   placeholder="quick filter..." />
          </div>
          <div class="example-header" style="float:right">
            Page Size:
            <select (change)="onPageSizeChanged()" id="page-size">
              <option value="10" selected="">10</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
          <div style="clear: both;">
            <ag-grid-angular style="width: 1110px; height: 567px;"
                             class="ag-theme-alpine"
                             [rowData]="rowData1"
                             [columnDefs]="columnDefs1"
                             [defaultColDef]="defaultColDef1"
                             [pagination]="true"
                             [paginationPageSize]="paginationPageSize1"
                             [paginationNumberFormatter]="paginationNumberFormatter1"
                             [rowSelection]="rowSelection1"
                             [rowMultiSelectWithClick]="true"
                             (gridReady)="onGridReady1($event)">
            </ag-grid-angular>
          </div>
          <br />
          <button [attr.disabled]="isDisabled ? true : null" style="float:left" class="btn btn-dark" type="submit">Grant CRN Access</button>
          <br /><br /><br />
        </form>
      </div>
      <br />
      <!--There were two of these in case any one of the table had empty crn. But ag-grid is handling the error with no rows to show message<p [hidden]="!hideNoRecords">No CRN's found</p>-->
    </div>
    <div id="third" class="row">
      <div [hidden]="!hideSavedCrn">
        <form [formGroup]="crnSaved_form" (ngSubmit)="crnSaved_submit()">
          <div *ngIf="infoTabLoading2" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
          <div style="margin-bottom: 5px;float:left">
            <input type="text"
                   (input)="onQuickFilterChanged2()"
                   id="quickFilter2"
                   placeholder="quick filter..." />
          </div>
          <div class="example-header" style="float:right">
            Page Size:
            <select (change)="onPageSizeChanged2()" id="page-size2">
              <option value="10" selected="">10</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
          <div style="clear: both;">
            <ag-grid-angular style="width: 1110px; height: 567px;"
                             class="ag-theme-alpine"
                             [rowData]="rowData2"
                             [columnDefs]="columnDefs2"
                             [defaultColDef]="defaultColDef2"
                             [pagination]="true"
                             [paginationPageSize]="paginationPageSize2"
                             [paginationNumberFormatter]="paginationNumberFormatter2"
                             [rowSelection]="rowSelection2"
                             [rowMultiSelectWithClick]="true"
                             (gridReady)="onGridReady2($event)">
            </ag-grid-angular>
          </div>
          <br />
          <button [attr.disabled]="isDisabled ? true : null" style="float:left" class="btn btn-dark" type="submit">Remove CRN Access</button>
          <br /><br /><br />
        </form>
      </div>
      <br />
      <p [hidden]="!hideNoRecords">No CRN's found</p>
    </div>
    <br />
  </div>
</div>

