<div *ngIf="showComponentTwo">
  <div class="container">
    <div class="student-details">
      <br />
      <div class="row">
        <div class="col-sm">
          <img style="height:300px" class="rounded-circle img-responsive" [src]="preparedUrl" *ngIf="!isImageLoading; else noImageFound">
          <ng-template #noImageFound>
            <img src="assets/p1.jpg" alt="Fallbackimage">
          </ng-template>
        </div>
        <div class="col-sm">
          <b>RNumber:</b> {{ componentTwo[0]?.ID }} <br />
          <b>Name:</b> {{componentTwo[0]?.NAME}}<br />
          <b>Email:</b> {{ componentTwo[0]?.EMAIL}} <br />
          <b>Classification:</b> {{ componentTwo[0]?.CLASSIFICATION }}<br />
          <b>TTUHSC GPA by Term:</b> {{ componentTwo[0]?.GPA }}<br />
          <b>TTUHSC Cumulative GPA:</b> {{ componentTwo[0]?.CUMLGPA }}<br />
          <b>Current Major:</b> {{componentTwo[0]?.MAJOR }}<br />
          <b>Expected Graduation:</b> {{ componentTwo[0]?.EXP_GRAD }}<br />
          <b>Address:</b> {{componentTwo[0]?.ADDRESS}}<br />
          <b>Phone:</b> {{componentTwo[0]?.TELEPHONE}}<br />
        </div>
        <div class="col-sm">
          <b>Site:</b> {{ componentTwo[0]?.SITE }} <br />
          <b>Campus:</b> {{componentTwo[0]?.CAMPUS}}<br />
          <ul>
            <li *ngFor="let componentTwoTotalValues of componentTwoTotal">
              <b>{{componentTwoTotalValues.SFRSTCR_CAMP_CODE}}:</b> <span> {{componentTwoTotalValues.PERCENTAGE}} &#37;</span>
            </li>
          </ul>
          <mat-card class="example-card">
            <ul>
              <li><a href="https://banner9.texastech.edu/StudentSelfService/ssb/termSelection?mepCode=HSC" target="_blank">Student Profile – Includes Student Records, Transcript, Holds, Test Scores, Registration History, Email, Address and Phone Number</a></li>
              <li><a href="https://registration.texastech.edu" target="_blank">Registration – Add/Drop and Current Schedule/Classes – Reg Link</a></li>
              <li><a href="https://registration.texastech.edu" target="_blank">Course Schedule and Catalog</a></li>
              <li><a href="https://registration.texastech.edu" target="_blank">DegreeWorks</a></li>
              <li><a href="https://registration.texastech.edu" target="_blank">Xtender</a></li>
            </ul>
          </mat-card>
        </div>
      </div>
    </div>
    <hr />
  </div>
</div>
