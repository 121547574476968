import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';

export class Tile {
  text: string;
  content: any;
}
@Component({
  selector: 'app-deans-campus',
  templateUrl: './deans-campus.component.html',
  styleUrls: ['./deans-campus.component.css']
})
export class DeansCampusComponent implements OnInit {
  terms = [
    { value: 'Spring', viewValue: 'Spring' },
    { value: 'Summer', viewValue: 'Summer' },
    { value: 'Fall', viewValue: 'Fall' }
  ];
  SchoolList = [
    { value: 'GB', viewValue: 'GSBS' },
    { value: 'MD', viewValue: 'Medicine' },
    { value: 'NR', viewValue: 'Nursing' },
    { value: 'PH', viewValue: 'Pharmacy' },
    { value: 'PL', viewValue: 'Population and Public Health' },
    { value: 'HP', viewValue: 'SHP' }
  ];
  termHeading: any;
  infoTabLoading: boolean = false;
  infoTabLoadingCampus: boolean = false;
  termCodesList: any;
  termpops: any = '';
  dean_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'schoolListsform': new FormControl('', Validators.required)
  });
  deanPie_form = new FormGroup({
    'termCodesPieListsform': new FormControl('', Validators.required)
  });
  constructor(private http: HttpClient) { }
  ngOnInit() {
    this.infoTabLoading = true;
    this.getStackedBarByCampus("NoTerm", "NA");
    this.getTermsForPie();    
   /* this.http.get('dashboard/studentapi/studentdetail/allTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });*/    
  }
  async getTermsForPie() {
    const data = await this.http.get('dashboard/studentapi/studentdetail/allTermCodes').toPromise();
    this.termCodesList = data;
    /*for (var i = 0; i < this.termCodesList.length; i++) {
      if (this.termCodesList[i].includes('HSC')) {
        this.termCodesList[i]=this.termCodesList[i].replace('HSC', 'Nursing')
      }
    } console.log(this.termCodesList);*/
  }

  dean_submit() {
    var term = this.dean_form.get('termCodesListsform').value;
    this.termpops = term;
    var school = this.dean_form.get('schoolListsform').value;
    this.getStackedBarByCampus(term, school);
  }
  async getStackedBarByCampus(termValue: string, school: string) {
    this.infoTabLoadingCampus = true;
    this.barChartLegendCampus = false;

    let searchString = '';
    console.log(this.dean_form.value.termCodesListsform);
    Object.keys(this.dean_form.controls).forEach(key => {
      if (this.dean_form.controls[key].value == '' || this.dean_form.controls[key].value == null) {
        searchString = searchString + 'NA/'
      }
      else {
        searchString = searchString + this.dean_form.controls[key].value + '/';
      }
    });
    console.log(searchString);

    this.abeline = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Abeline") + 'term/getDeansCountAbeline').toPromise();
    this.abeline = this.getSum(this.abeline);

    this.amarillo = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Amarillo") + 'term/getDeansCountAmarillo').toPromise();
    this.amarillo = this.getSum(this.amarillo);

    this.covenant = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Covenant") + 'term/getDeansCountCovenant').toPromise();
    this.covenant = this.getSum(this.covenant);

    this.dallas = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Dallas") + 'term/getDeansCountDallas').toPromise();
    this.dallas = this.getSum(this.dallas);

    this.distanceEducation = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "DistanceEducation") + 'term/getDeansCountDistanceEducation').toPromise();
    this.distanceEducation = this.getSum(this.distanceEducation);

    this.lubbock = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Lubbock") + 'term/GetDeansCountLubbock').toPromise();
    this.lubbock = this.getSum(this.lubbock);

    this.midland = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Midland") + 'term/getDeansCountMidland').toPromise();
    this.midland = this.getSum(this.midland);

    this.odessa = await this.http.get('dashboard/studentapi/studentdetail/' + this.getSchools(searchString, termValue, "Odessa") + 'term/getDeansCountOdessa').toPromise();
    this.odessa = this.getSum(this.odessa);

    this.barChartDataCampus = [
      { data: this.abeline, label: 'Abilene', stack: 'a', backgroundColor: "#3B0D08", hoverBackgroundColor: "#1E0604" },
      { data: this.amarillo, label: 'Amarillo', stack: 'a', backgroundColor: "#1E0604", hoverBackgroundColor: "#3B0D08" },
      { data: this.covenant, label: 'Covenant', stack: 'a', backgroundColor: "#59130C", hoverBackgroundColor: "#59130C" },
      { data: this.dallas, label: 'Dallas', stack: 'a', backgroundColor: "#761910", hoverBackgroundColor: "#761910" },
      { data: this.distanceEducation, label: 'Distance Education', stack: 'a', backgroundColor: "#941F14", hoverBackgroundColor: "#941F14 " },
      { data: this.lubbock, label: 'Lubbock', stack: 'a', backgroundColor: "#B22618", hoverBackgroundColor: "#B22618" },
      { data: this.midland, label: 'Midland', stack: 'a', backgroundColor: "#CF2C1C", hoverBackgroundColor: "#CF2C1C" },
      { data: this.odessa, label: 'Odessa', stack: 'a', backgroundColor: "#ED3220", hoverBackgroundColor: "#ED3220" }
    ];
    this.barChartLegendCampus = true;
    this.infoTabLoadingCampus = false;
  }
  getSchools(searchString: string, termValue: string, currentSchool: string): string {
    let finalSearchString = ''
    if (currentSchool == "Abeline") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5/4")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "6/7")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "1/2")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm/NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5/4")
        termValue = "Spring"
      }
    } else if (currentSchool == "Amarillo") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5/4/6")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "6/7/8")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "1/2/3")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm/NoTerm/NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5/4/6")
        termValue = "Spring"
      }
    } else if (currentSchool == "Covenant" || currentSchool == "Dallas") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "8")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "2")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5")
        termValue = "Spring"
      }
    } else if (currentSchool == "DistanceEducation") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5/4/4/4")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "6/7/8/9")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "1/2/3/3")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm/NoTerm/NoTerm/NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5/4/4/4")
        termValue = "Spring"
      }
    } else if (currentSchool == "Lubbock") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5/4/4")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "6/7/8")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "1/2/2")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm/NoTerm/NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5/4/4")
        termValue = "Spring"
      }
    } else if (currentSchool == "Midland") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "7")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "2")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5")
        termValue = "Spring"
      }
    } else if (currentSchool == "Odessa") {
      if (searchString.slice(0, 6) == "Spring") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "5/5")
      } else if (searchString.slice(0, 6) == "Summer") {
        finalSearchString = searchString.replace(searchString.slice(0, 6), "6/7")
      } else if (searchString.slice(0, 4) == "Fall") {
        finalSearchString = searchString.replace(searchString.slice(0, 4), "2/2")
      } else if (termValue == "NoTerm") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "NoTerm/NoTerm")
      } else if (searchString.slice(0, 2) == "NA") {
        finalSearchString = searchString.replace(searchString.slice(0, 2), "5/5")
        termValue = "Spring"
      }
    }
    return finalSearchString;
  }
  getSum(school: any): string[] {
    var term = this.dean_form.get('termCodesListsform').value;

    const usersJson = [];
    let total = 0, i = 17, k = 22, j;
    if (term == "Fall") {
      i++; k++;
    }
    for (i; i < k; i++) {
      for (j = 0; j < school.length; j++) {
        if (school[j].SGBSTDN_TERM_CODE_EFF.toString().indexOf(i, 2) == "2") {
          total += school[j].BARCHARTDATAHC;
        }
      }
      usersJson.push(total)
      total = 0;
    }
    return usersJson
  }
  //1)stack chart by campus
  public barChartOptionsCampus: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      //below filter is used to discard the elements(campuses) that have value zero in the tooltip
      filter: x => { return x.yLabel > 0 },
      mode: 'label',
      callbacks: {
        footer: (tooltipItems, data) => {
          let total = tooltipItems.reduce((a, e) => a + parseInt(e.value), 0);
          return 'Total: ' + total;
        }
      },
      titleFontSize: 35,
      bodyFontSize: 25,
      footerFontSize: 30
    }
  };
  public barChartLabelsCampus: Label[] = ['2017', '2018', '2019', '2020', '2021'];
  public barChartTypeCampus: ChartType = 'bar';
  public barChartLegendCampus = false;
  public barChartPluginsCampus = [];

  public barChartDataCampus: ChartDataSets[];
  amarillo: any;
  abeline: any;
  covenant: any;
  dallas: any;
  distanceEducation: any;
  lubbock: any;
  midland: any;
  odessa: any;

  //2)stack chart by specific campus
  public pieChartOptionsCampus: ChartOptions = {
    responsive: true,
    /* maintainAspectRatio: false,
     tooltips: {
       //below filter is used to discard the elements(campuses) that have value zero in the tooltip
       filter: x => { return x.yLabel > 0 },
       mode: 'label',
       callbacks: {
         footer: (tooltipItems, data) => {
           let total = tooltipItems.reduce((a, e) => a + parseInt(e.value), 0);
           return 'Total: ' + total;
         }
       },
       titleFontSize: 35,
       bodyFontSize: 25,
       footerFontSize: 30
     }*/
  };
  public pieChartLabelsCampus: Label[] = ['Abilene', 'Amarillo', 'Covenant', 'Distance Education', 'Dallas', 'Lubbock', 'Midland', 'Odessa'];
  public pieChartTypeCampus: ChartType = 'pie';
  public pieChartLegendCampus = false;
  public pieChartPluginsCampus = [];
  public pieChartDataCampus: SingleDataSet = [0, 0, 0, 0, 0, 0];
  colors: Color[] = [
    {
      backgroundColor: ['#3B0D08','#1E0604','#59130C','#761910','#941F14','#B22618','#CF2C1C','#ED3220']
    }
  ];
  deanPie_submit() {
    this.piechartTerm = this.deanPie_form.get('termCodesPieListsform').value.split(",")[0]
    this.getPieBarByCampus(this.deanPie_form.get('termCodesPieListsform').value.slice(-6));
  }
  piechartTerm: any;
  pieChartData: any;
  hidePieChart: boolean = false;
  async getPieBarByCampus(termValue: string) {
    this.pieChartData = await this.http.get('dashboard/studentapi/studentdetail/' + termValue + '/term/getDeansPieChartCampus').toPromise();
    if (this.pieChartData.length != 0) {
      this.pieChartDataCampus = this.pieChartData;
      this.hidePieChart = true;
    } else {
      this.hidePieChart = false;
    }
  }
}
