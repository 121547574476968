<div style="margin-top: 20px;"  class="container">
  <h2>Roster form</h2>
  <br />
  <form [formGroup]="roster_form" (ngSubmit)="roster_submit()">
    <div class="example-container row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td><p>Please select Term : </p></td>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <mat-option *ngFor="let termcode of termCodesList" [value]="termcode.BACKVALUE_NAME" (onSelectionChange)="termSelect($event)">{{termcode.FRONTVALUE_NAME}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>

            <tr>
              <td>

                <mat-form-field>
                  <mat-label>CRN</mat-label>
                  <input matInput placeholder="Ex. CRN" formControlName="crnNumberText"
                         (keyup)="crnSelect($event)" autocomplete="off">
                </mat-form-field>
              </td>
            </tr>
            <tr>
            <tr>
              <td style="padding: 50px;">
                Please Enter a CRN or Select a Subject
              </td>
            </tr>
            <tr>

            <tr>
              <td><p>Please select Subject : </p></td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Select Subject</mat-label>
                  <mat-select formControlName="subjectListsform" (selectionChange)="subjectSelect($event)">
                    <mat-option value="None">None</mat-option>
                    <mat-option *ngFor="let subjects of allSubjects" [value]="subjects">{{subjects}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>

            <tr>
              <td><p>Please select Section : </p></td>
              <td>
                <mat-form-field style="width:500px;" appearance="outline">
                  <mat-label>Select Section</mat-label>
                  <mat-select formControlName="programCodesListsform">
                    <mat-option *ngFor="let programs of allPrograms" [value]="programs.BACK_DISPLAY_VALUE" (onSelectionChange)="programSelect($event)">{{programs.FRONT_DISPLAY_VALUE}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td><button class="btn btn-dark" type="submit">Display Roster</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
  <p></p>
    <mat-table mat-elevation-z8 [dataSource]="dataSource"
               matSort
               [hidden]="!hidePagination">

      <!-- Position Column -->
      <ng-container matColumnDef="NUMBER">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let j = index">
          {{ (j+1) + (myPaginator.pageIndex * myPaginator.pageSize) }}
        </mat-cell>
      </ng-container>
      <!-- RNumber Column -->
      <ng-container matColumnDef="RID">
        <mat-header-cell *matHeaderCellDef mat-sort-header> RID </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.RID}}</div>
        </mat-cell>
      </ng-container>
      <!-- NAME Column -->
      <ng-container matColumnDef="NAME">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.NAME}}</div>
        </mat-cell>
      </ng-container>
      <!-- EMAIL Column -->
      <ng-container matColumnDef="EMAIL">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index" style="column-gap: 10px;">
          <div>{{RosterItem.EMAIL}}</div>
        </mat-cell>
      </ng-container>
      <!-- CLASSIFICATION Column -->
      <ng-container matColumnDef="CLASSIFICATION">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Classification </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.CLASSIFICATION}}</div>
        </mat-cell>
      </ng-container>
      <!-- COLLEGE Column -->
      <ng-container matColumnDef="COLLEGE">
        <mat-header-cell *matHeaderCellDef mat-sort-header> College </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.COLLEGE}}</div>
        </mat-cell>
      </ng-container>
      <!-- DEGREE Column -->
      <ng-container matColumnDef="DEGREE">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Degree </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.DEGREE}}</div>
        </mat-cell>
      </ng-container>
      <!-- MAJOR Column -->
      <ng-container matColumnDef="MAJOR">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Major </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.MAJOR}}</div>
        </mat-cell>
      </ng-container>
      <!-- CREDIT HOURS Column -->
      <ng-container matColumnDef="HOURS">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Registered Hours </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.HOURS}}</div>
        </mat-cell>
      </ng-container>
      <!-- FINAL GRADES Column -->
      <ng-container matColumnDef="LIVE_GRADE">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Final Grades </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.LIVE_GRADE}}</div>
        </mat-cell>
      </ng-container>
      <!-- LAST_ATTENDANCE_DATE Column -->
      <ng-container matColumnDef="LAST_ATTENDANCE_DATE">
        <mat-header-cell *matHeaderCellDef mat-sort-header>LAST_ATTENDANCE_DATE </mat-header-cell>
        <mat-cell *matCellDef="let RosterItem; let i = index">
          <div>{{RosterItem.LAST_ATTENDANCE_DATE}}</div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <p [hidden]="!hideNoRecords">No records found</p>
    <br />
    <div [hidden]="!hidePagination">
      <mat-paginator #myPaginator [pageSizeOptions]="[100,25,10,5]" showFirstLastButtons></mat-paginator>
    </div>

</div>
