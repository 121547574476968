import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

export interface DialogInfo {
  RNumber: string;
  Term: string;
}
@Component({
  selector: 'app-xtender-documentupload-modal',
  templateUrl: './xtender-documentupload-modal.component.html',
  styleUrls: ['./xtender-documentupload-modal.component.css']
})
export class XtenderDocumentuploadModalComponent implements OnInit {

  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    public dialogRef: MatDialogRef<XtenderDocumentuploadModalComponent>
    , private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getXDocTypes();
  }
  async getXDocTypes() {
    const DocumentypeList = await this.http.get('dashboard/studentapi/studentdetail/GetXtenderDocumentTypes').toPromise();
    this.xDocumentypeList = DocumentypeList;
    const AcademicCollegeList = await this.http.get('dashboard/studentapi/studentdetail/GetXtenderAcademicCollege').toPromise();
    this.xAcademicCollegeList = AcademicCollegeList;
  }

  Rnumber = this.data.RNumber;
  Term = this.data.Term;
  selectedFileName: string;
  documentType_form = new FormGroup({
    '_documentType': new FormControl('', Validators.required),
    '_collegeType': new FormControl('', Validators.required),
    'description': new FormControl('', Validators.required),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  get description() {
    return this.documentType_form.get('description');
  }
  get documentType() {
    return this.documentType_form.get('_documentType');
  }
  get collegeType() {
    return this.documentType_form.get('_collegeType');
  }
  xDocumentypeSelect(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value);
    }
  }
  xAcademicCollegeSelect(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value);
    }
  }
  get documentTypeForm() {
    return this.documentType_form.get('_documentType');
  }
  disableSubmitButton: boolean = false;
  hideSuccessUploadMessage: boolean = true;
  hideErrorUploadMessage: boolean = true;
  hideUploadForm: boolean = true;
  //xDocType: any;
  xDocumentypeList: any;
  xAcademicCollegeList: any;
  /*onKeyClear() {
    this.xDocType = '';
  }*/

  /*onKeyRNumEnter(event: any) {
    this.documentType_form.controls['_documentType'].markAsTouched();
  }*/
  srcResult: any;
  completeFileName: any;
  fileName: any;
  async Upload() {
    if (this.documentType_form.valid) {

      this.disableSubmitButton = true;

      let applicationDetails = this.documentType_form.get('_documentType').value + "&" + this.Rnumber + "&" + this.Term + "&" + "HSC" + "&" + this.documentType_form.get('description').value + "&" + this.documentType_form.get('_collegeType').value;
      console.log(applicationDetails);
      let files = this.documentType_form.get('fileSource').value;

      let fData: FormData = new FormData;
      if (files.length > 0) {
        files = files[0];
      }
      let fileExtension = files.name.match(/\.[0-9a-z]+$/i)[0];
      this.fileName = files.name.substring(0, files.name.lastIndexOf('.'));
      fData.append(applicationDetails, files, this.fileName + fileExtension);
      console.log(fData);
      const response = await this.http.post<string>('dashboard/studentapi/studentdetail/UploadFile', fData, { reportProgress: true, observe: 'events' }).toPromise();
      if (response['body'].status == 'success') {
        this.hideSuccessUploadMessage = false;
        this.hideErrorUploadMessage = true;
        this.hideUploadForm = false;
      } else if (response['body'].status == 'error') {
        this.hideErrorUploadMessage = false;
        this.hideSuccessUploadMessage = true;
        this.hideUploadForm = false;
      }
    }
  }

  onFileSelected(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.documentType_form.patchValue({
        fileSource: file
      });
    }
  }
}
