<div>
  <h5>
    <b>R Number: </b>{{Rnumber}}
  </h5>
</div>
<!--<div [hidden]="NoHoldInfo">
  <h5 class="alert alert-danger">No Hold information found.</h5>
</div>
<div [hidden]="!NoHoldInfo">-->
  <div>
    <ag-grid-angular style="width: 98%; height: 375px;"
                     class="ag-theme-alpine"
                     pagination
                     [gridOptions]="gridOptions"
                     (gridReady)="onGridReady($event)"
                     [rowData]="rowData"
                     [columnDefs]="columnDefs"
                     [pagination]="true"
                     [paginationPageSize]="5"
                     [suppressRowClickSelection]="true"
                     [suppressMenuHide]="true"
                     [frameworkComponents]="frameworkComponents"                     
                     (rowClicked)="onRowClicked($event)">
    </ag-grid-angular>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="Close()">Close</button>
</div>
