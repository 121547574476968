import { Component, OnInit, Inject } from '@angular/core';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogInfo {
  Term: any;
  RNumber: string;
}
@Component({
  selector: 'app-enrollment-prompt-modal',
  templateUrl: './enrollment-prompt-modal.component.html',
  styleUrls: ['./enrollment-prompt-modal.component.css']
})
export class EnrollmentPromptModalComponent implements OnInit {

  Rnumber = this.data.RNumber;
  Term = this.data.Term;
  NoEnrollmentInfo: boolean = false;
  public rowData: any;
  public gridOptions;
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  frameworkComponents: any;
  public defaultColDef;

  columnDefs = [
   
    {
      headerName: 'CRN',
      field: 'SFRSTCA_CRN',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Level Code',
      field: 'SFRSTCA_LEVL_CODE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Campus Code',
      field: 'SFRSTCA_CAMP_CODE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Add Date',
      field: 'SFRSTCA_ADD_DATE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Activity Date',
      field: 'SFRSTCA_ACTIVITY_DATE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'RSTS Code',
      field: 'SFRSTCA_RSTS_CODE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'RSTS Date',
      field: 'SFRSTCA_RSTS_DATE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Error Flag',
      field: 'SFRSTCA_ERROR_FLAG',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Message',
      field: 'SFRSTCA_MESSAGE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Bill Hours',
      field: 'SFRSTCA_BILL_HR',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Credit Hours',
      field: 'SFRSTCA_CREDIT_HR',
      sortable: true,
      filter: true
    },
    {
      headerName: 'GMOD Code',
      field: 'SFRSTCA_GMOD_CODE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'User',
      field: 'SFRSTCA_USER',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Source Code',
      field: 'SFRSTCA_SOURCE_CDE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Data Origin',
      field: 'SFRSTCA_DATA_ORIGIN',
      sortable: true,
      filter: true
    },
    {
      headerName: 'RMSG Code',
      field: 'SFRSTCA_RMSG_CDE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'User ID',
      field: 'SFRSTCA_USER_ID',
      sortable: true,
      filter: true
    }
  ];
  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    public dialogRef: MatDialogRef<EnrollmentPromptModalComponent>) { }

  ngOnInit(): void {
    this.getHoldsTable();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
      filter: true,
      sortable: true,
      floatingFilter: true,

    };
    this.gridOptions = {
      domLayout: 'normal',
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      rowSelection: 'single',
    };
  }
  async getHoldsTable() {
    if (this.Rnumber != "" && this.Term != "") {
      const EnrollmentModalTableDetails = await this.http.get('dashboard/studentapi/studentdetail/' + this.Rnumber + '/rno/' + this.Term + '/term/getEnrollmentModalTableDetails').toPromise();
      this.rowData = EnrollmentModalTableDetails;
      if (this.rowData.length != 0) {

        this.NoEnrollmentInfo = true;
      }
      else {
        this.NoEnrollmentInfo = false;
      }
    }
  }
  Close(): void {
    this.dialogRef.close(true);
  }
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
}
