import { Component, OnInit, HostListener, ElementRef, ViewChild, Input, Inject, Injector, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.css']
})
export class ScrollToTopComponent implements OnInit {
  

  //public scrollContainer: ElementRef;
  isShow: boolean;

  constructor() {
    this.isShow = false;
  }

  ngOnInit(): void {
  }
  private _scrollContainer: ElementRef;

  @Input() set scrollContainer(value: ElementRef) {
    this._scrollContainer = value;
    if (this._scrollContainer && this._scrollContainer.nativeElement) {
      this._scrollContainer.nativeElement.addEventListener('scroll', () => {
        this.onContainerScroll();
      });
    }
  }

  get scrollContainer(): ElementRef {
    return this._scrollContainer;
  }

  onContainerScroll(): void {
    this.isShow = this.scrollContainer.nativeElement.scrollTop > 400;
  }

  scrollToTop(): void {
    if (this.scrollContainer) {
      this.scrollContainer.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
}
