<div style="margin-top: 20px;" class="container-fluid">
  <h2>ClearingHouse Reports</h2>
  <form [formGroup]="clearingHouseReportForm" (ngSubmit)="submitForm()">
    <div class="dropdown-container">
      <mat-form-field appearance="outline">
        <mat-label>Academic Year</mat-label>
        <mat-select formControlName="acadYear">
          <mat-option *ngFor="let year of acadYears" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Semester</mat-label>
        <mat-select formControlName="semester">
          <mat-option *ngFor="let semester of semesters" [value]="semester">
            {{ semester }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Branch</mat-label>
        <mat-select formControlName="branch" (selectionChange)="onBranchChange($event.value)">
          <mat-option *ngFor="let branch of branches" [value]="branch">
            {{ branch }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="showHP">
        <mat-label>HP</mat-label>
        <mat-select formControlName="hp">
          <mat-option *ngFor="let hp of hps" [value]="hp">
            {{ hp }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <div style="float: left; margin-right: 5px;margin-bottom: 2px;">
        <button mat-raised-button class="btn btn-dark" type="submit" [disabled]="disableButton">
          {{ disableButton ? 'Downloading...' : 'Download Excel' }}
        </button>
      </div>
      <div style="float: left; margin-right: 5px;margin-bottom: 2px;">
        <mat-spinner *ngIf="loadingIndicator" diameter="20" class="spinner"></mat-spinner>
      </div>
      <div style="clear: both;"></div>
      <div style="margin-top: 2px">
        <p *ngIf="disableButton && !allDropdownsSelected()">Please select all dropdowns before clicking the button.</p>
        <p *ngIf="disableButton && loadingIndicator">
          <ng-container *ngIf="timeoutError; else professionalMessage">
            Please wait while the Excel sheet is being processed. This may take a moment.
          </ng-container>
          <ng-template #professionalMessage>
            Please wait while the Excel sheet is being downloaded.
          </ng-template>
        </p>

      </div>
    </div>
     
  </form>
</div>
