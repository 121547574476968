import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MsgmodalComponent } from '../allmodals/msgmodal/msgmodal.component';
import { ButtonDeleteRendererComponent } from '../Button Renderer/button-delete-renderer/button-delete-renderer.component';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
export interface RolesItem {
  RID: string;
  NAME: string;
  EMAIL: string;
  rid: string;
  name: string;
  email: string;
}
const ROLES_DATA: RolesItem[] = [];

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  rNumber: any;
  rNumberList: any;
  hideNoRecords: boolean = false;
  hideGradingAccess: boolean = false;
  hideAccessDropDowns: boolean = false;
  hidePageAccess: boolean = false;
  hidePageTermAccess: boolean = false;
  hidePageSelectAccess: boolean = false;
  formValid: boolean = true;
  termCodesList: any;
  accessTermCodesList: any;
  allSubjects: any;
  allCrns: any;
  itemsCrn: any;
  frameworkComponents: any;
  hideAccessDisplayRow: boolean = false;
  hideAccessDisplayRowButton: boolean = false;
  hideAccessDisplayRowError: boolean = false;
  constructor(private http: HttpClient, public dialog: MatDialog) {
    this.frameworkComponents = {
      ButtonDeleteRendererComponent: ButtonDeleteRendererComponent
    }
  }
  ngOnInit() {
  }

  displayedColumns: string[] = ['RID', 'NAME', 'EMAIL', 'SCHOOLS'];
  dataSource = new MatTableDataSource<RolesItem>(ROLES_DATA);
  windowScrolled: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  rNum_form = new FormGroup({
    '_rNumber': new FormControl('', Validators.required)
  });
  pageaccesspick_form = new FormGroup({
  });
  pagecrnaccesspick_form = new FormGroup({
  });
  get rNumberForm() {
    return this.rNum_form.get('_rNumber');
  }
  onKeyClear(event: any) {
    this.rNumber = '';
    this.hideAccessDisplayRowError = false;
    this.hideAccessDisplayRowButton = false;
    this.hideAccessDisplayRow = false;
  }
  onKeyRNum(event: any) {

    if (event.target.value == '') {
      this.rNumber = '';
      this.hideAccessDisplayRowError = false;
      this.hideAccessDisplayRowButton = false;
      this.hideAccessDisplayRow = false;
    }
    else {

      this.http.get('dashboard/studentapi/studentdetail/' + event.target.value + '/user/userrolernumber').subscribe(
        response => {
          this.rNumberList = response;
        },
        error => {
          console.log(error);
        })

      if (this.rNum_form.invalid) {
        this.rNumber = '';
      }
    }
  }

  onKeyRNumEnter(event: any) {
    this.rNum_form.controls['_rNumber'].markAsTouched();
  }

  rNumSelect(event: any) {

    if (event.isUserInput) {

      console.log(event.source.value);
      var rNumArrSplit = event.source.value.split(" -");
      if (event.source.value.startsWith('R') && rNumArrSplit[0].length == 9) {
        this.getAccessTable(rNumArrSplit[0]);
      } else {
        console.log("not cames");
      }
    } else {
      console.log("cames" + event);
    }
  }
  //Role View definition
  buttonDeleteRendererConfig = {
    buttonText: 'Delete',
    buttonClass: 'btn btn-danger'
  };
  gridApi: any;
  gridColumnApi: any;
  rowData: any;
  columnDefs = [
    { field: 'TERMS', sortable: true, filter: true },
    { field: 'DASHBOARD', sortable: true, filter: true },
    { field: 'GRADES', sortable: true, filter: true },
    { field: 'ROSTER', sortable: true, filter: true },
    {
      headerName: 'Action',
      cellRenderer: 'ButtonDeleteRendererComponent',
      cellRendererParams: {
        onClick: this.DeleteApplication.bind(this),
        config: this.buttonDeleteRendererConfig,
      },
    },
  ];
  rowSelection = 'single';
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  paginationPageSize = 5;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi.setQuickFilter(globalSearchValue.value);
  }
  //School grid Defintion
  gridApi1: any;
  gridColumnApi1: any;
  rowData1 = [
    { SCHOOLS: 'Medicine' }, { SCHOOLS: 'Pharmacy' }
  ];
  columnDefs1 = [
    {
      field: 'SCHOOLS', sortable: true, checkboxSelection: true
    }
  ];
  defaultColDef1 = {
    flex: 1,
    minWidth: 100,
    resizable: true
  };
  rowSelection1 = 'multiple';
  onGridReady1(params1) {
    this.gridApi1 = params1.api;
    this.gridColumnApi1 = params1.columnApi;
  }
  //Term grid Defintion
  gridApi2: any;
  gridColumnApi2: any;
  rowData2: any;
  columnDefs2 = [
    {
      field: 'TERM', sortable: true, filter: true, checkboxSelection: true, headerCheckboxSelection: true
    }
  ];
  defaultColDef2 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection2 = 'multiple';
  onGridReady2(params) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }
  //Page grid Defintion
  gridApi3: any;
  gridColumnApi3: any;
  rowData3 = [
    { PAGES: 'DashBoard' },
    { PAGES: 'Roster' },
    { PAGES: 'Grades' },
    { PAGES: 'Roles' },
    { PAGES: 'CrnAccess' },
    { PAGES: 'DeansDashboard' },
    { PAGES: 'WorkBoard' },
    { PAGES: 'TransferBoard' },
    { PAGES: 'InstitutionalSupport' },
    { PAGES: 'EnrollmentDashboard' },
    { PAGES: 'GraduationDashboard' },
    { PAGES: 'SchoolGraduationDashboard' },
    { PAGES: 'CombinedGraduationDashboard' },
    { PAGES: 'ResidencyDashboard' },
    { PAGES: 'OdsReportsDashboard' },
    { PAGES: 'DualDegreeDashboard'}
  ];
  columnDefs3 = [
    {
      field: 'PAGES', sortable: true, filter: true, checkboxSelection: true, headerCheckboxSelection: true
    }
  ];
  defaultColDef3 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection3 = 'multiple';
  onGridReady3(params) {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
  }

  async getAccessTable(rNumArrSplit: any) {
    const rolestableData = await this.http.get('dashboard/studentapi/studentdetail/' + rNumArrSplit + '/user/userroletable').toPromise();
    this.rowData = rolestableData;

    if (this.rowData.length === 0) {
      //display a message saying no access and button to give them access
      this.hideAccessDisplayRowError = true;
      this.hideAccessDisplayRowButton = true;
      this.hideAccessDisplayRow = false;
    } else {
      this.hideAccessDisplayRowError = false;
      this.hideAccessDisplayRowButton = true;
      this.hideAccessDisplayRow = true;
    }
  }
  async assignnewrole_submit() {
    this.hidePageAccess = true;
  }
  async onRowSelected1(event) {
    if (this.gridApi1.getSelectedNodes().length > 0) {
      const accesstableData = await this.http.get('dashboard/studentapi/studentdetail/AccessAllTermCodesMedicine').toPromise();
      this.rowData2 = accesstableData;
      this.hidePageTermAccess = true;
    } else {
      this.hidePageTermAccess = false;
      this.hidePageSelectAccess = false;
      this.gridApi2.deselectAll();
      this.gridApi3.deselectAll();
    }
  }
  onRowSelected2(event) {
    if (this.gridApi2.getSelectedNodes().length > 0) {
      this.hidePageSelectAccess = true;
    } else {
      this.hidePageSelectAccess = false;
      this.gridApi3.deselectAll();
    }
  }
  onRowSelected3(event) {
    if (this.gridApi3.getSelectedNodes().length > 0) {
      console.log(event);
    }
  }
  async pageaccesspick_submit() {
    if (this.gridApi1.getSelectedNodes().length > 0 &&
      this.gridApi2.getSelectedNodes().length > 0 &&
      this.gridApi3.getSelectedNodes().length > 0) {
      this.formValid = true;
      this.sendRolesForSubmit('save');
    }
    else {
      this.formValid = false;
    }
  }
  DashBoard: string = 'NO';
  Roster: string = 'NO';
  Grades: string = 'NO';
  Roles: string = 'NO';
  CrnAccess: string = 'NO';
  DeansDashboard: string = 'NO';
  WorkBoard: string = 'NO';
  TransferBoard: string = 'NO';
  InstitutionalSupport: string = 'NO';
  EnrollmentDashboard: string = 'NO';
  GraduationDashboard: string = 'NO';
  SchoolGraduationDashboard: string = 'NO';
  CombinedGraduationDashboard: string = 'NO';
  ResidencyDashboard: string = 'NO';
  OdsReportsDashboard: string = 'NO';
  DualDegreeDashboard: string = 'NO';
  async sendRolesForSubmit(task: string) {
    var count = 0;
    var i = 0, j = 0;
    let selectedRowsSchools = this.gridApi1.getSelectedRows();
    let selectedRowsTerms = this.gridApi2.getSelectedRows();
    let selectedRowsPages = this.gridApi3.getSelectedRows();
    console.log(selectedRowsTerms); console.log(selectedRowsPages); console.log();

    for (i = 0; i < selectedRowsTerms.length; i++) {
      for (j = 0; j < selectedRowsPages.length; j++) {
        if (this.DashBoard === 'NO') { this.DashBoard = selectedRowsPages[j].PAGES === 'DashBoard' ? 'YES' : 'NO'; }
        if (this.Roster === 'NO') { this.Roster = selectedRowsPages[j].PAGES === 'Roster' ? 'YES' : 'NO'; }
        if (this.Grades === 'NO') { this.Grades = selectedRowsPages[j].PAGES === 'Grades' ? 'YES' : 'NO'; }
        if (this.Roles === 'NO') { this.Roles = selectedRowsPages[j].PAGES === 'Roles' ? 'YES' : 'NO'; }
        if (this.CrnAccess === 'NO') { this.CrnAccess = selectedRowsPages[j].PAGES === 'CrnAccess' ? 'YES' : 'NO'; }
        if (this.DeansDashboard === 'NO') { this.DeansDashboard = selectedRowsPages[j].PAGES === 'DeansDashboard' ? 'YES' : 'NO'; }
        if (this.WorkBoard === 'NO') { this.WorkBoard = selectedRowsPages[j].PAGES === 'WorkBoard' ? 'YES' : 'NO'; }
        if (this.TransferBoard === 'NO') { this.TransferBoard = selectedRowsPages[j].PAGES === 'TransferBoard' ? 'YES' : 'NO'; }
        if (this.InstitutionalSupport === 'NO') { this.InstitutionalSupport = selectedRowsPages[j].PAGES === 'InstitutionalSupport' ? 'YES' : 'NO'; }
        if (this.EnrollmentDashboard === 'NO') { this.EnrollmentDashboard = selectedRowsPages[j].PAGES === 'EnrollmentDashboard' ? 'YES' : 'NO'; }
        if (this.GraduationDashboard === 'NO') { this.GraduationDashboard = selectedRowsPages[j].PAGES === 'GraduationDashboard' ? 'YES' : 'NO'; }
        if (this.SchoolGraduationDashboard === 'NO') { this.SchoolGraduationDashboard = selectedRowsPages[j].PAGES === 'SchoolGraduationDashboard' ? 'YES' : 'NO'; }
        if (this.CombinedGraduationDashboard === 'NO') { this.CombinedGraduationDashboard = selectedRowsPages[j].PAGES === 'CombinedGraduationDashboard' ? 'YES' : 'NO'; }
        if (this.ResidencyDashboard === 'NO') { this.ResidencyDashboard = selectedRowsPages[j].PAGES === 'ResidencyDashboard' ? 'YES' : 'NO'; }
        if (this.OdsReportsDashboard === 'NO') { this.OdsReportsDashboard = selectedRowsPages[j].PAGES === 'OdsReportsDashboard' ? 'YES' : 'NO'; }
        if (this.DualDegreeDashboard === 'NO') { this.DualDegreeDashboard = selectedRowsPages[j].PAGES === 'DualDegreeDashboard' ? 'YES' : 'NO'; }
      }
      var tempItems = '{'
        + '"RID" : "' + this.rNum_form.get('_rNumber').value.substring(0, 9) + '",'
        + '"TERM" : "' + selectedRowsTerms[i].TERM.substring(0, 6) + '",'
        + '"SCHOOL" : "' + selectedRowsSchools[i].SCHOOLS + '",'
        + '"DASHBOARD" : "' + this.DashBoard + '",'
        + '"GRADES" : "' + this.Grades + '",'
        + '"ROSTER" : "' + this.Roster + '",'
        + '"ROLES" : "' + this.Roles + '",'
        + '"CRNACCESS" : "' + this.CrnAccess + '",'
        + '"DEANSDASH" : "' + this.DeansDashboard + '",'
        + '"WORKBOARD" : "' + this.WorkBoard + '",'
        + '"TRANSFERBOARD" : "' + this.TransferBoard + '",'
        + '"INSSUPPORT" : "' + this.InstitutionalSupport + '",'
        + '"ENROLLMENTDASH" : "' + this.EnrollmentDashboard + '",'
        + '"GRADDASH" : "' + this.GraduationDashboard + '",'
        + '"SCHOOLGRADDASH" : "' + this.SchoolGraduationDashboard + '",'
        + '"COMBINEDGRADDASH" : "' + this.CombinedGraduationDashboard + '",'
        + '"RESIDENCYDASH" : "' + this.ResidencyDashboard + '",'
        + '"ODSREPORTSDASH" : "' + this.OdsReportsDashboard + '",'
        + '"DUALREPORTSDASH" : "' + this.DualDegreeDashboard + '"'    
        + '}';
      console.log(tempItems);
      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/saveroles';

      var data = await this.http.post<string>(api, tempItems, options).toPromise();
      if (data['status'] == 'complete') {
        count++;
      } else if (data['status'] == 'error') {
        count = 999;
        break;
      }
      this.DashBoard === 'NO';
      this.Roster === 'NO';
      this.Grades === 'NO';
      this.Roles === 'NO';
      this.CrnAccess === 'NO';
      this.DeansDashboard === 'NO';
      this.WorkBoard === 'NO';
      this.TransferBoard === 'NO';
      this.InstitutionalSupport === 'NO';
      this.EnrollmentDashboard === 'NO';
      this.GraduationDashboard === 'NO';
      this.SchoolGraduationDashboard === 'NO';
      this.CombinedGraduationDashboard === 'NO';
      this.ResidencyDashboard === 'NO';
      this.OdsReportsDashboard === 'NO';
      this.DualDegreeDashboard === 'NO';
    }
    //if status complete for all records show popup
    if (count == selectedRowsTerms.length) {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Roles Saved Successfully!'
        }
      });

      dialogRef.afterClosed().subscribe(data => {

        //Reset the form after dialog is closed.
        this.loadData(count);
        window.location.reload();
      });
    }
    else {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Error Occured while Saving, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu!'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
  }
  async loadData(count: number) {
    if (count != 0) {
      this.hidePageAccess = false;
      this.getAccessTable(this.rNum_form.get('_rNumber').value.substring(0, 9));
    } else {
      this.rNum_form.reset();
      this.hideAccessDisplayRowError = false;
      this.hideAccessDisplayRowButton = false;
      this.hidePageAccess = false;
      this.hidePageTermAccess = false;
      this.hidePageSelectAccess = false;
      this.gridApi1.deselectAll();
      this.gridApi2.deselectAll();
      this.gridApi3.deselectAll();
    }
  }
  async DeleteApplication(element: any) {
    var tempItems
    tempItems = '{'
      + '"RID" : "' + this.rNum_form.get('_rNumber').value.substring(0, 9) + '",'
      + '"TERM" : "' + element.rowData.TERMS + '"'
      + '}';
    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    let api = 'dashboard/studentapi/studentdetail/deleteroles';
    var data = await this.http.post<string>(api, tempItems, options).toPromise();
    //await this.http.post<string>('dashboard/studentapi/studentdetail/deleteroles', tempItems, options).subscribe(data => {

    if (data['status'] == 'complete') {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Roles and Term Successfully Deleted!'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.getAccessTable(this.rNum_form.get('_rNumber').value.substring(0, 9));
      });
    }
    else {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Error Occured while Deleting, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu!'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
      });

    }

  }

}
