import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-dualdegree-dashboard',
  templateUrl: './dualdegree-dashboard.component.html',
  styleUrls: ['./dualdegree-dashboard.component.css']
})
export class DualdegreeDashboardComponent implements OnInit {
  dualDegreeReportForm: FormGroup;
  loadingIndicator = false;
  disableButton = false;
  semesters = ['Spring', 'Fall', 'Summer'];
  acadYears = ['2025', '2024', '2023', '2022'];
  hideData: boolean = true;
  dualDegreeTable: any;
  showNoRecordsMessage: boolean = false;
  constructor(private http: HttpClient,) { }

  ngOnInit() {
    this.dualDegreeReportForm = new FormGroup({
      acadYear: new FormControl('', Validators.required),
      semester: new FormControl('', Validators.required),
    });
  }
  //Grad board Table definition
  gridApi: any;
  gridColumnApi: any;
  rowData: any;
  public gridOptions;
  columnDefs = [
    { field: 'SPRIDEN_ID', headerName: 'ID', sortable: true, filter: true, width: 140, maxWidth: 145 },
    { field: 'SPRIDEN_LAST_NAME', headerName: 'Last Name', sortable: true, filter: true },
    { field: 'SPRIDEN_FIRST_NAME', headerName: 'First Name', sortable: true, filter: true },
    { field: 'SPRIDEN_MI', headerName: 'MI', sortable: true, filter: true },
    { field: 'SGBSTDN_TERM_CODE_EFF', headerName: 'Term Code', sortable: true, filter: true },
    { field: 'SGBSTDN_PROGRAM_1', headerName: 'Program', sortable: true, filter: true },
    { field: 'GRADUATION_DATE', headerName: 'Graduation Date', sortable: true, filter: true },
    { field: 'SGBSTDN_CAMP_CODE', headerName: 'Campus Code', sortable: true, filter: true },
    { field: 'LEVEL_DESCRIPTION', headerName: 'Classification', sortable: true, filter: true },
    { field: 'RESIDENCY', headerName: 'Residency', sortable: true, filter: true },
    { field: 'HOURS_ENROLLED', headerName: 'Hours Enrolled', sortable: true, filter: true },
    { field: 'ENROLLMENT_STATUS', headerName: 'Enrollment Status', sortable: true, filter: true },
  ];

  defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection = 'single';
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.autoSizeAllColumns();
  }
  onFirstDataRendered(params) {
    this.autoSizeAllColumns();
  }

  autoSizeAllColumns() {
    const allColumnIds = this.gridColumnApi.getAllColumns().map(column => column.getId());
    this.gridColumnApi.autoSizeColumns(allColumnIds);
  }
  paginationPageSize = 10;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi.setQuickFilter(globalSearchValue.value);
  }
  submitForm() {
    if (this.dualDegreeReportForm.valid) {
      const acadYear = this.dualDegreeReportForm.get('acadYear')?.value;
      const semester = this.dualDegreeReportForm.get('semester')?.value;
      const termCodes = this.getTermCodes(acadYear, semester);
      console.log(termCodes);
      this.getGraduationDetailRowData(termCodes);
    }
  }
  async getGraduationDetailRowData(termCodes: any) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let api = 'dashboard/studentapi/studentdetail/getDualDegreeStudents';
    var dualDegreeData = await this.http.post<any>(api, termCodes, options).toPromise();
    //console.log(finalRowData);
    this.dualDegreeTable = dualDegreeData;
    if (dualDegreeData.length === 0) {
      this.showNoRecordsMessage = true;
      this.hideData = true;
    } else {
      this.showNoRecordsMessage = false;
      this.rowData = this.dualDegreeTable;
      this.hideData = false;
    }
  }
  getTermCodes(acadYear: string, semester: string): string[] | null {
    const year = parseInt(acadYear);
    const termMapping: { [key: string]: string[] } = {
      Spring: [`${year}43`, `${year}44`, `${year}51`, `${year}52`, `${year}53`, `${year}54`, `${year}55`, `${year}56`, `${year}64`],
      Summer: [`${year}61`, `${year}71`, `${year}72`, `${year}73`, `${year}74`, `${year}75`, `${year}83`],
      Fall: [`${year + 1}13`, `${year + 1}14`, `${year + 1}21`, `${year + 1}22`, `${year + 1}23`, `${year + 1}24`, `${year + 1}25`, `${year + 1}34`]
    };

    return termMapping[semester] || null;
  }

  allDropdownsSelected(): boolean {
    const acadYear = this.dualDegreeReportForm.get('acadYear');
    const semester = this.dualDegreeReportForm.get('semester');
    return acadYear?.value && semester?.value;
  }
}
