<div class="container">
  <!--<div class="example-card">
    <form [formGroup]="deanschool_form" (ngSubmit)="dean_submitSchool()">
      <div class="row">
        <div class="col-sm">
          <h2>Please Select Term</h2>
          <table class="table-borderless">
            <tbody>
              <tr>
                <td><p>Please select Term : </p></td>
                <td style="padding-right: 10px">
                  <mat-form-field appearance="outline">
                    <mat-label>Select Term</mat-label>
                    <mat-select formControlName="termCodesListsform">
                      <mat-option *ngFor="let term of terms" [value]="term.value">{{term.viewValue }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>

               
                <td>-->
                  <!--<mat-form-field>
                    <mat-select multiselect multiple="multiple"
                                disableOptionCentering
                                placeholder="Select schools"
                                formControlName="schoolListsform">
                      <mat-option *ngFor="let school of SchoolList" [value]="school.value">
                        {{school.viewValue}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>-->
                  <!--<mat-form-field appearance="outline">
                    <mat-label>Schools</mat-label>
                    <mat-select formControlName="schoolListsform">
                      <mat-option *ngFor="let school of SchoolList" [value]="school.value">{{school.viewValue}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br>-->
                <!--</td>
              </tr>

              <tr>
                <td><button class="btn btn-dark" type="submit">Submit</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  </div>-->
  <mat-card class="stack-card">
    <mat-card-title-group>
      <mat-card-title>Graph</mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
      <table class="table-borderless">
        <tbody>
          <tr>
            <th> <h6><u>Headcount By School (All Academic Terms)</u></h6></th>
          </tr>
          <tr>
            <td>
              <div *ngIf="infoTabLoadingCampus" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
              <div *ngIf="barChartDataSchool">
                <canvas baseChart
                        [datasets]="barChartDataSchool"
                        [labels]="barChartLabelsSchool"
                        [options]="barChartOptionsSchool"
                        [plugins]="barChartPluginsSchool"
                        [legend]="barChartLegendSchool"
                        [chartType]="barChartTypeSchool">
                </canvas>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </mat-card-content>
  </mat-card>


</div>
