import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';
@Injectable()
export class SharedService {

  private messageSourceRNum = new BehaviorSubject<string>("");
  currentRNum = this.messageSourceRNum.asObservable();

  private rolesSubject = new Subject<Object>();
  roles = this.rolesSubject.asObservable();

  private eluSubject = new BehaviorSubject<Object>("");
  userElu = this.eluSubject.asObservable();

  private gradeSourceRNum = new BehaviorSubject<Object>("");
  latestRNum = this.gradeSourceRNum.asObservable();

  private institutionalIdentifier = new BehaviorSubject<Object>("");
  latestIdentifier = this.institutionalIdentifier.asObservable();

  private username: string;

  setUsername(username: string) {
    this.username = username;
  }

  getUsername() {
    return this.username;
  }

  constructor() {
  }
  changeRNum(message: string) {
    this.messageSourceRNum.next(message)
  }
  updateRoles(roles: Object) {
    this.rolesSubject.next(roles)
  }
  updateElu(userElu: Object) {
    this.eluSubject.next(userElu)
  }
  transmitRNum(message: Object) {
    this.gradeSourceRNum.next(message)
  }
  transmitIdentifier(message: Object) {
    this.institutionalIdentifier.next(message)
  }
}
