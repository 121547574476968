<div style="height: 275px; width: 100%; margin: auto; margin-left:14px; margin-right:2px; overflow:auto">
  <ag-grid-angular style="height: 275px;"
                   class="ag-theme-alpine"
                   [rowData]="rowData1"
                   [columnDefs]="columnDefs1"
                   [defaultColDef]="defaultColDef1"
                   [pagination]="false"
                   [paginationPageSize]="5"
                   [rowSelection]="rowSelection1"
                   [rowMultiSelectWithClick]="true"
                   (gridReady)="onGridReady1($event)"
                   (cellValueChanged)="onCellValueChanged($event)"
                   [gridOptions]="gridOptions">
  </ag-grid-angular>
</div>
