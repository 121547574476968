import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-residency-dashboard',
  templateUrl: './residency-dashboard.component.html',
  styleUrls: ['./residency-dashboard.component.css']
})
export class ResidencyDashboardComponent implements OnInit {

  periods: any;
  years: any;
  constructor() { }

  ngOnInit() {
  }
  residency_form = new FormGroup({
    'periodCodesListsform': new FormControl('', Validators.required),
    'yearsListsform': new FormControl('', Validators.required)
  });
  residency_submit() { }
}
