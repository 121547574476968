<div class="container-fluid">
  <h2>Dual Degree Dashboard</h2>
  <form [formGroup]="dualDegreeReportForm" (ngSubmit)="submitForm()">
    <div class="dropdown-container">
      <mat-form-field appearance="outline">
        <mat-label>Academic Year</mat-label>
        <mat-select formControlName="acadYear">
          <mat-option *ngFor="let year of acadYears" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Semester</mat-label>
        <mat-select formControlName="semester">
          <mat-option *ngFor="let semester of semesters" [value]="semester">
            {{ semester }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="action-container">
      <button mat-raised-button class="btn btn-dark" type="submit" [disabled]="disableButton">
        {{ disableButton ? 'Loading...' : 'Submit' }}
      </button>
      <mat-spinner *ngIf="loadingIndicator" diameter="20" class="spinner"></mat-spinner>
      <p *ngIf="disableButton && !allDropdownsSelected()" class="error-message">
        Please select all dropdowns before clicking the button.
      </p>
    </div>
  </form>
  <br />
  <div *ngIf="showNoRecordsMessage" class="alert alert-warning">
    No records found, please try with another value.
  </div>
  <br />
  <div *ngIf="!hideData && dualDegreeTable.length > 0" class="ag-grid-controls-container">
    <div class="ag-grid-controls">
      <input type="text" (input)="onQuickFilterChanged()" id="quickFilter" placeholder="quick filter..." />
      <div class="page-size-selector">
        Page Size:
        <select (change)="onPageSizeChanged()" id="page-size">
          <option value="10" selected="">10</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
    <ag-grid-angular style="width: 100%; height: 600px;"
                     class="ag-theme-alpine"
                     [rowData]="rowData"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     [pagination]="true"
                     [paginationPageSize]="paginationPageSize"
                     [paginationNumberFormatter]="paginationNumberFormatter"
                     [rowSelection]="rowSelection"
                     (gridReady)="onGridReady($event)"
                     (rowDataChanged)="onRowDataChanged()">
    </ag-grid-angular>
  </div>
</div>
