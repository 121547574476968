<div style="margin-top: 20px;" class="container">
  <h1>Enrollment Reporting Dashboard</h1>
  <form [formGroup]="rNum_form">
    <table>
      <tr>
        <td>Please Enter SSN or R#: </td>
        <td>
          <mat-form-field style="width:500px;" class="example-form">
            <input type="text"
                   #rNumber
                   #trigger="matAutocompleteTrigger"
                   placeholder="SSN/RNum"
                   aria-label="Number"
                   matInput
                   [matAutocomplete]="rNumAuto" (keyup)="onKeyRNum($event)" (keydown.enter)="onKeyRNumEnter($event)"
                   formControlName="_rNumber">
            <mat-autocomplete #rNumAuto="matAutocomplete">
              <mat-option *ngFor="let theRNumber of combinedList" [value]="theRNumber" (onSelectionChange)="rNumSelect($event)">
                {{theRNumber}}
              </mat-option>
            </mat-autocomplete>
            <button *ngIf="rNum_form.get('_rNumber').value" type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="rNum_form.get('_rNumber').setValue('');onKeyClear();"><mat-icon>close</mat-icon></button>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td colspan="2" *ngIf="rNumberForm.touched && rNumberForm.invalid " class="alert alert-danger">
          <p *ngIf="rNumberForm.errors.required">SSN or R# is required.</p>
        </td>
      </tr>
    </table>
  </form>
  <div class="container">
    <div div id="first" class="row">
      <form [formGroup]="termpick_form"
            [hidden]="!hideTermPick">

        <table class="table-borderless">
          <tbody>
            <tr>
              <td><p>Please Select Term : </p></td>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <mat-option *ngFor="let termcode of enrollmentTermCodesList" [value]="termcode" (onSelectionChange)="termSelect($event)">{{termcode}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
      <p [hidden]="!termNotFoundPick">Term not found. Please search with a diferent SSN or R#.</p>
    </div>
  </div>
  <div *ngIf="!hideData" class="container">
    <mat-card class="mat-card-wide">
      <div class="row common-div">
        <div class="col-md">
          <b>School and Program : </b> {{enrollmentData[0]?.SCHOOL_AND_PROGRAM || 'NULL'}}<br />
          <b>Expected Graduation Date : </b> {{enrollmentData[0]?.EXPECTED_GRAD_DATE || 'NULL'}}<br />
          <b>Federal Grants and Loans : </b> {{enrollmentData[0]?.FUNDING || 'NULL'}}<br />
          <b>Awarded Programs and Term :</b>
          <ng-container *ngIf="enrollmentData[0]?.AWARDED_DEGREES_AND_TERM">
            <ul>
              <li *ngFor="let program of enrollmentData[0]?.AWARDED_DEGREES_AND_TERM.split(', ')">{{ program }}</li>
            </ul>
          </ng-container>
          <ng-container *ngIf="!enrollmentData[0]?.AWARDED_DEGREES_AND_TERM">
            NULL
          </ng-container>
        </div>
      </div>
    </mat-card>
    <br />
    <div style="margin-bottom: 5px;float:left">
      <input type="text"
             (input)="onQuickFilterChanged()"
             id="quickFilter"
             placeholder="quick filter..." />
    </div>
    <div class="example-header" style="float:right">
      Page Size:
      <select (change)="onPageSizeChanged()" id="page-size">
        <option value="20" selected="">20</option>
        <option value="50">50</option>
      </select>
    </div>
    <div style="clear: both;">
      <ag-grid-angular style="width: 1110px; height: 1057px;"
                       class="ag-theme-alpine"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="defaultColDef"
                       [pagination]="true"
                       [paginationPageSize]="paginationPageSize"
                       [paginationNumberFormatter]="paginationNumberFormatter"
                       [rowSelection]="rowSelection"
                       [frameworkComponents]="frameworkComponents"
                       (gridReady)="onGridReady($event)"
                       (rowDataChanged)="onRowDataChanged()">
      </ag-grid-angular>
    </div>
  </div>
</div>
