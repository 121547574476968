import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, SingleDataSet, monkeyPatchChartJsTooltip, monkeyPatchChartJsLegend } from 'ng2-charts';
@Component({
  selector: 'app-deans-piegraph',
  templateUrl: './deans-piegraph.component.html',
  styleUrls: ['./deans-piegraph.component.css']
})
export class DeansPiegraphComponent implements OnInit {
  terms = [
    { value: 'Spring', viewValue: 'Spring' },
    { value: 'Summer', viewValue: 'Summer' },
    { value: 'Fall', viewValue: 'Fall' }
  ];
  SchoolList = [
    { value: 'GB', viewValue: 'GSBS' },
    { value: 'MD', viewValue: 'Medicine' },
    { value: 'NR', viewValue: 'Nursing' },
    { value: 'PH', viewValue: 'Pharmacy' },
    { value: 'PL', viewValue: 'Population and Public Health' },
    { value: 'HP', viewValue: 'SHP' }
  ];
  termHeading: any;
  infoTabLoading: boolean = false;
  infoTabLoadingCampus: boolean = false;
  termCodesList: any;
  termpops: any = '';
  dean_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'schoolListsform': new FormControl('', Validators.required)
  });
  constructor(private http: HttpClient) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();}
  ngOnInit() {
    this.infoTabLoading = true;
    //this.getStackedBarByCampus("NoTerm", "NA");
    /*this.http.get('dashboard/studentapi/studentdetail/allTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });*/
    this.http.get('dashboard/studentapi/studentdetail/allTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }

  dean_submit() {
    var term = this.dean_form.get('termCodesListsform').value;
    this.termpops = term;
    var school = this.dean_form.get('schoolListsform').value;
    //this.getStackedBarByCampus(term, school);
  }
 
  
}
