import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatOption } from '@angular/material/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { HoldsPromptModalComponent } from '../allmodals/holds-prompt-modal/holds-prompt-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { XtenderDocumenttypelistModalComponent } from '../allmodals/xtender-documenttypelist-modal/xtender-documenttypelist-modal.component';
import { XtenderDocumentuploadModalComponent } from '../allmodals/xtender-documentupload-modal/xtender-documentupload-modal.component';
import { WorkboardUpdateModalComponent } from '../allmodals/workboard-update-modal/workboard-update-modal.component';
import { DialogServiceService } from '../Services/modalDialogservices/dialog-service.service';

export interface WorkItem {
  COMMON: string;
  CLASSIFICATION: string;
  GRAD_TERM: string;
  HVAD: string;
  STUDENT_STATUS: string;
}
const WORK_DATA: WorkItem[] = [];
export interface workFilter {
  name: string;
  options: string[];
  defaultValue: string;
}
@Component({
  selector: 'app-work-board',
  templateUrl: './work-board.component.html',
  styleUrls: ['./work-board.component.css']
})
export class WorkBoardComponent implements OnInit {
  termCodesList: any;
  allPrograms: any;
  infoTabLoading: boolean = false;
  hidePagination: boolean = false;
  hideNoRecords: boolean = false;
  selectedTerm: string;
  allTermsArray: string[] = [];
  selectedTermsListValues: any;
  selectedProgramsListValues: any;
  dropdownSelectError: boolean = false;
  hideFilteredError: boolean = false;
  defaultValue = "All";
  filterDictionary = new Map<string, string>();
  classificationValues: any;
  workfilterClassification: any;
  workfilterGradTerm: any;
  workfilterHvad: any;
  workfilterStatus: any;
  workfiltersLength: any;
  student_details_from_xtender: any;
  Hold: any;//this variable is used to hold value obtained from child component
  displayedColumns: string[] = ['COMMON'];
  dataSource = new MatTableDataSource<WorkItem>(WORK_DATA);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('select') select: MatSelect;
  workFilters: workFilter[] = [];
  constructor(private http: HttpClient, private dialog: MatDialog, private snackBar: MatSnackBar, private dialogService: DialogServiceService) {
    dialogService.updateSuccessful$.subscribe(status => {
      if (status) {
        this.getWorkBoardTable(this.selectedTerm, this.workboard_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1 || 'NA');
      }
    });
  }

  ngOnInit(): void {
    this.http.get('dashboard/studentapi/studentdetail/AllWorkBoardTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }

  //filterControls: FormControl[] = [];
  workboard_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'programCodesListsform': new FormControl(''),
  });
  workboardDetailsTable_form = new FormGroup({
  });
  date1 = new FormControl(new Date())

  optionClick() {
    this.select.options.forEach((item: MatOption) => {
    });
  }

  termSelect(event: any) {
    if (event.isUserInput) {
      console.log(this.workboard_form.get('termCodesListsform').value);
      console.log(event.source.value);
      this.getPrograms(event.source.value.slice(-6));
    }
  }

  async getPrograms(termcode: any) {
    const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/programsFromTerm').toPromise();
    this.allPrograms = programs;
    this.workboard_form.get('programCodesListsform').setValue('');
  }

  workboard_submit() {
    this.infoTabLoading = true;
    if (this.workboard_form.get('termCodesListsform').value == "" && this.workboard_form.get('programCodesListsform').value == "") {
      this.infoTabLoading = false;
      this.dropdownSelectError = true;
    } else if (this.workboard_form.get('termCodesListsform').value != "" && this.workboard_form.get('programCodesListsform').value == "") {
      this.getWorkBoardTable(this.workboard_form.get('termCodesListsform').value.slice(-6), "NA");
    } else if (this.workboard_form.get('termCodesListsform').value != "" && this.workboard_form.get('programCodesListsform').value != "") {
      this.getWorkBoardTable(this.workboard_form.get('termCodesListsform').value.slice(-6), this.workboard_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1);
    }
  }
  student_docs_download: any;

  //comes in when display xtender document is clicked
  async showXtenderDocumentType(rno: any) {
    let dialogRef = this.dialog.open(XtenderDocumenttypelistModalComponent, {
      data: { RNumber: rno },
      width: '78%',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  //comes in when upload xtender document is clicked
  async uploadXtenderDocument(rno: any, termcode: any) {
    let dialogRef = this.dialog.open(XtenderDocumentuploadModalComponent, {
      data: { RNumber: rno, Term: termcode },
      width: '78%',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  //comes in when update/edit workitemdata button is clicked
  async openEditModal(rno: any, termcode: any) {
    let dialogRef = this.dialog.open(WorkboardUpdateModalComponent, {
      data: { RNumber: rno, Term: termcode },
      width: '78%',
      height: 'auto',
    });
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
    });
  }
  applyWorkFilter(ob: MatSelectChange, workfilterKey: workFilter) {
    this.filterDictionary.set(workfilterKey.name, ob.value);
    this.applyCombinedFilters();
    console.log(this.dataSource.filteredData.length);
    if (this.dataSource.filteredData.length != 0) {
      this.hideFilteredError = false;
    } else {
      this.hideFilteredError = true;
    }
  }

  searchValue = '';
  @ViewChild('inputo', { static: true }) searchInput!: ElementRef;
  applyFilter(event: Event) {
    this.searchValue = (event.target as HTMLInputElement).value;
    this.applyCombinedFilters();
  }
  applyCombinedFilters() {
    this.dataSource.filterPredicate = this.filterPredicate.bind(this);

    const jsonString = JSON.stringify({
      searchValue: this.searchValue,
      filters: Array.from(this.filterDictionary.entries()),
    });

    this.dataSource.filter = jsonString;

    if (this.dataSource.filteredData.length !== 0) {
      this.hideFilteredError = false;
    } else {
      this.hideFilteredError = true;
    }
  }
  filterPredicate(record: WorkItem, filter: string) {
    const parsedFilter = JSON.parse(filter);
    const map = new Map<string, string>(parsedFilter.filters);
    const searchValue = parsedFilter.searchValue?.trim().toLowerCase() || '';

    let searchMatch = false;

    if (searchValue) {
      for (const key in record) {
        if (
          record.hasOwnProperty(key) &&
          typeof record[key as keyof WorkItem] === 'string' &&
          record[key as keyof WorkItem].toLowerCase().includes(searchValue)
        ) {
          searchMatch = true;
          break;
        }
      }
    } else {
      searchMatch = true;
    }

    for (const [key, value] of map) {
      if (value !== 'All' && record[key as keyof WorkItem] !== value) {
        return false;
      }
    }

    return searchMatch;
  }
  clearFilters() {
    // Reset filterDictionary
    this.filterDictionary.clear();
    // Reset each filter to "All"
    this.workFilters.forEach((filter, index) => {
      this.filterDictionary.set(filter.name, this.defaultValue);
      this.workFilters[index].defaultValue = this.defaultValue;
    });
    // Reset search input
    this.searchValue = '';
    this.searchInput.nativeElement.value = '';
    // Apply the updated filters
    this.applyCombinedFilters();
  }
  async getWorkBoardTable(termcode: any, programs: any) {
    this.dropdownSelectError = false;
    this.infoTabLoading = true;
    this.hidePagination = false;
    this.selectedTerm = termcode;
    const tableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + programs + '/programs/' + 'workTableData').toPromise();
    this.dataSource = new MatTableDataSource(tableData as WorkItem[]);
    if (this.dataSource.data.length != 0) {

      //clears out the array and adds in the new filters on every submit
      this.workFilters.splice(0);

      this.workfilterClassification = [...new Set(this.dataSource.data.map(item => item.CLASSIFICATION))];
      this.workfilterClassification.unshift(this.defaultValue);
      this.workfilterGradTerm = [...new Set(this.dataSource.data.map(item => item.GRAD_TERM))];
      this.workfilterGradTerm.unshift(this.defaultValue);
      this.workfilterHvad = [...new Set(this.dataSource.data.map(item => item.HVAD))];
      this.workfilterHvad.unshift(this.defaultValue);
      this.workfilterStatus = [...new Set(this.dataSource.data.map(item => item.STUDENT_STATUS))];
      this.workfilterStatus.unshift(this.defaultValue);

      this.workFilters.push({ name: 'CLASSIFICATION', options: this.workfilterClassification, defaultValue: this.defaultValue });
      this.workFilters.push({ name: 'GRAD_TERM', options: this.workfilterGradTerm, defaultValue: this.defaultValue });
      this.workFilters.push({ name: 'HVAD', options: this.workfilterHvad, defaultValue: this.defaultValue });
      this.workFilters.push({ name: 'STUDENT_STATUS', options: this.workfilterStatus, defaultValue: this.defaultValue });
      this.workfiltersLength = this.workFilters.length;
      this.infoTabLoading = false;
      this.dataSource.paginator = this.paginator;
      this.hidePagination = true;
      this.hideNoRecords = false;
    } else {
      this.infoTabLoading = false;
      this.hidePagination = false;
      this.hideNoRecords = true;
    }
  }
  //comes in when Active Holds button is clicked 
  async openDialog(rno: any) {
    console.log(rno);
    let dialogRef = this.dialog.open(HoldsPromptModalComponent, {
      data: { RNumber: rno },
      width: '78%',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  //comes in when Add HVAD button is clicked
  async InsertHvad(rno: any, term: any) {
    if (rno != null && term != null) {
      const snackbarRef = this.showSnackbarAction('Please wait... Value is getting updated.', null, 0);
      const body = {
        "SPRIDEN_ID": rno,
        "SGRSACT_ACTC_CODE": "HVAD",
        "SGRSACT_TERM_CODE": term,
      };

      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/insertSGRSACT';
      try {
        var response = await this.http.put<any>(api, body, options).toPromise();
        snackbarRef.dismiss();
        if (response.status === 'SGRSACT Inserted!') {
          this.getWorkBoardTable(
            this.workboard_form.get('termCodesListsform').value.slice(-6),
            this.workboard_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1 || 'NA'
          );
          console.log('Value updated.');
          this.showSnackbarAction('HVAD value Inserted Successfully.', null);
          // Set all filters to 'All'. If I dont set the filter to All the pagination count becomes wrong if I choose filters from the UI
          this.workFilters.forEach(filter => {
            this.filterDictionary.set(filter.name, 'All');
            filter.defaultValue = 'All';
          });
          // Apply the updated filters
          this.applyCombinedFilters();

        } else {
          console.log('Error:', response.status);
          this.showSnackbarAction('Error: ' + response.status, null);
        }
      } catch (error) {
        snackbarRef.dismiss();
        console.log('Error:', error);
      }
    } else {
      this.showSnackbarAction('something went wrong rno and term not found', null);
    }
  }
  showSnackbarAction(content, action, duration = 4000) {
    let snack = this.snackBar.open(content, action, { duration: duration });
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
    return snack;
  }
}
