<div style="margin-top: 20px;" class="container-fluid">
  <h2>Graduation Board</h2>
  <br />
  <form [formGroup]="gradboard_form" (ngSubmit)="gradboard_form_submit()">
  <!--  <mat-card class="invisible-card">

      <mat-card-content class="card-content-row">


        <span fxFlex="5%">Please Enter Value:</span>

        <mat-form-field fxFlex="13%" class="adjusted-margin">

          <input type="text"
                 #rNumber
                 #trigger="matAutocompleteTrigger"
                 placeholder="Rnumber"
                 aria-label="Number"
                 matInput
                 [matAutocomplete]="rNumAuto"
                 (keyup)="onKeyRNum($event)"
                 (keydown.enter)="onKeyRNumEnter($event)"
                 formControlName="_rNumber">

          <mat-autocomplete #rNumAuto="matAutocomplete">
            <mat-option *ngFor="let theRNumber of rNumberList" [value]="theRNumber" (onSelectionChange)="rNumSelect($event)">
              {{theRNumber}}
            </mat-option>
          </mat-autocomplete>

          <button *ngIf="rNumberForm.value" type="button" mat-button matSuffix mat-icon-button aria-label="Clear"
                  (click)="gradboard_form.get('_rNumber').setValue('');onKeyClear();">
            <mat-icon>close</mat-icon>
          </button>

          <mat-error *ngIf="rNumberForm.hasError('required')">
            Input required.
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <br /><p>OR</p>-->
    <div class="row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <!--<mat-option style=" font-style: italic;color: grey;">-- Clear selection --</mat-option>-->
                    <mat-option *ngFor="let termcode of termCodesList;let i =index" [value]="termcode.BACKVALUE_NAME" (onSelectionChange)="termSelect($event)">{{termcode.FRONTVALUE_NAME}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="termCodeFieldHasError()">
                    Term selection is required.
                  </mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field style="width:500px;" appearance="outline">
                  <mat-label>Select Program</mat-label>
                  <mat-select formControlName="programCodesListsform">
                    <!--<mat-option class="clear-option">-- Clear selection --</mat-option>-->
                    <mat-option *ngFor="let programs of allPrograms;let i =index" [value]="programs">{{programs.SGBSTDN_PROGRAM_1}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td><button class="btn btn-dark" type="submit">Submit</button></td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 20px 20px 20px 4px;" [hidden]="!dropdownSelectError">
          <p>Please select either one or both drop downs.</p>
        </div>
        <div style="padding: 20px 20px 20px 4px;" [hidden]="!hideNoRecords">
          <p>No records found. Please try with another Term.</p>
        </div>
      </div>
    </div>
  </form>
  <br />
  <form [formGroup]="gradboardDetailsTable_form">
    <div *ngIf="infoTabLoading" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    <div [hidden]="!hidePagination">

      <div style="float:left">
        <mat-form-field appearance="standard">
          <mat-label>Search Graduation-Board Data</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex.R123456789" #inputo>
        </mat-form-field>
      </div>
      <div class="example-header" style="float:right">
        <mat-paginator #myPaginator [pageSizeOptions]="[5,10,15]" showFirstLastButtons></mat-paginator>
      </div>
      <div class="mat-elevation-z8" style="clear: both;">
        <mat-table #table
                   [dataSource]="dataSource"
                   class="table">
          <!-- Common Column -->
          <ng-container matColumnDef="COMMON">
            <mat-header-cell *matHeaderCellDef> GRADUATION-BOARD LIST </mat-header-cell>
            <mat-cell *matCellDef="let GradItem; let i = index">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6 col-md-4">
                    <b>R Number:</b> {{GradItem.RNUMBER}} <br />
                    <b>Full Name:</b> {{GradItem.FULLNAME}}<br />
                    <b>Diploma Name:</b> {{GradItem.DIPLOMA_NAME}} <br />
                    <b>Diploma Address:</b> {{GradItem.DIPLOMA_ADDRESS}} <br />
                    <b>Institutional GPA:</b> {{GradItem.INSTITUITIONAL_GPA}} <br />
                    <b>School Decision:</b><span [style.background-color]="GradItem.SCHOOL_DECISION == 'YES'?'#00FF00':'#ff4040'"> {{GradItem.SCHOOL_DECISION}}</span> <br />
                    <b>Approved By:</b> {{GradItem.USERNAME}} <br />
                    <!--<b>Date Mailed:</b> {{01/01/2025}} <br />-->
                  </div>
                  <div class="col-6 col-md-4">
                    <b>Grad Date:</b> {{GradItem.GRAD_DATE}} <br />
                    <b>Grad Term: </b>  {{GradItem.GRAD_TERM}} <br />
                    <b>Program code with Description:</b> {{GradItem.PROGRAM_CODE_WITHDESC}} <br />
                  </div>
                </div>
                <br />
                <div class="row" style="position: relative; left: -15px;">
                  <div class="col">
                    <app-graduation-dashboard-table [rowDataSelectedTerm]="selectedTerm" [rowDataCurrentRnumber]="GradItem.RNUMBER"></app-graduation-dashboard-table>
                  </div>
                </div>
                <br />
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <!--<div style="padding: 20px 20px 20px 4px;" [hidden]="!hideFilteredError">
      <p>No records found after filtering. Please try again with another filter.</p>
    </div>-->
      <br />
      <br />
      <br />
    </div>
    <br />
  </form>
</div>
