import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import jsSHA from 'jssha';

@Component({
  selector: 'app-component-two',
  templateUrl: './component-two.component.html',
  styleUrls: ['./component-two.component.css']
})
export class ComponentTwoComponent implements OnInit {
  preparedUrl: any;
  isImageLoading: boolean;


  showComponentTwo: boolean = false;
  @Input() myValPropTwo: string;
  inputs: any;
  propChanges: any;
  componentTwo: any;
  componentTwoTotal: any;


  constructor(private http: HttpClient) {
  }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.propChanges = changes;
    console.log("propChanges.myValPropTwo.currentValue: " + this.propChanges.myValPropTwo.currentValue);
    if (this.propChanges.myValPropTwo.currentValue != '' && this.propChanges.myValPropTwo.currentValue != 'reset') {
      this.inputs = this.propChanges.myValPropTwo.currentValue.split(',');
      this.getDataComponentTwo();
    }
    else if (this.propChanges.myValPropTwo.currentValue == 'reset') {
      this.showComponentTwo = false;
      this.componentTwo = [];
    }

  }
  async getDataComponentTwo() {
    const compTwo = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/' + this.inputs[1] + '/term/ComponentTwo').toPromise();
    this.componentTwo = compTwo;
    const compTwoTotal = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/' + this.inputs[1] + '/term/ComponentTwoTotal').toPromise();
    this.componentTwoTotal = compTwoTotal
    this.getImageFromService(this.componentTwo[0].PIDM);
    this.showComponentTwo = true;
  }
  getImageFromService(imagePidm) {
    var baseURL = 'https://banapps.texastech.edu/ITIS/studentapi/api/Image?';
    var urlParms = 'pidm=' + imagePidm + '&publicKey=ttus&timeStamp=' + Date.now();
    const shaObj = new jsSHA("SHA-256", "TEXT", {
      hmacKey: { value: "Aq4HqXRagWdRyVVXKY2j", format: "TEXT" },
    });
    shaObj.update(urlParms);
    const hmac = shaObj.getHash("HEX");
    console.log(baseURL + urlParms + '&hmac=' + hmac);
    this.preparedUrl = baseURL + urlParms + '&hmac=' + hmac;
  }
}
