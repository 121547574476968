import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
export interface RosterItem {
  RID: string;
  NAME: string;
  EMAIL: string;
  CLASSIFICATION: string;
  COLLEGE: string;
  DEGREE: string;
  MAJOR: string;
  HOURS: string;
  LIVE_GRADE: string;
  LAST_ATTENDANCE_DATE: string;
}

const ROSTER_DATA: RosterItem[] = [];
@Component({
  selector: 'app-roster',
  templateUrl: './roster.component.html',
  styleUrls: ['./roster.component.css']
})
export class RosterComponent implements OnInit {
  termCodesList: any;
  crnNumber: any;
  allSubjects: any;
  allPrograms: any;
  rosterTable: any;
  hidePagination: boolean = false;
  hideNoRecords: boolean = false;
  roster_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'crnNumberText': new FormControl('', Validators.required),
    'subjectListsform': new FormControl('', Validators.required),
    'programCodesListsform': new FormControl('', Validators.required)
  });
  displayedColumns: string[] = ['NUMBER', 'RID', 'NAME', 'EMAIL', 'CLASSIFICATION', 'COLLEGE', 'DEGREE', 'MAJOR', 'LIVE_GRADE', 'HOURS','LAST_ATTENDANCE_DATE',];
  dataSource = new MatTableDataSource<RosterItem>(ROSTER_DATA);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(private http: HttpClient, private cookies: CookieService) { }

  ngOnInit() {
    this.http.get('dashboard/studentapi/studentdetail/allTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }
  //comes in when term is selected
  termSelect(event: any) {
    if (event.isUserInput) {
      this.hidePagination = false;
      this.hideNoRecords = false;
      console.log("previous->" + this.roster_form.get('termCodesListsform').value + "current->" + event.source.value);
      this.roster_form.get('subjectListsform').setValue('');
      this.roster_form.get('programCodesListsform').setValue('');
      this.getSubjects(event.source.value.slice(-6));
    }
  }
  //comes in when subject is selected
  subjectSelect(event: any) {
    console.log(event.source.viewValue);
    if (event.value != "None") {
      this.roster_form.get('crnNumberText').disable();
      this.roster_form.get('programCodesListsform').enable();
      this.hidePagination = false;
      this.hideNoRecords = false;
      console.log("previous->" + this.roster_form.get('termCodesListsform').value + "test" + this.roster_form.value + "current2->" + event.source.value);
      this.getPrograms(this.roster_form.value.termCodesListsform, event.source.value);
    } else if (event.value == "None") {
      this.hidePagination = false;
      this.hideNoRecords = false;
      this.roster_form.get('programCodesListsform').setValue('');
      this.roster_form.get('programCodesListsform').disable();
      this.roster_form.get('crnNumberText').enable();
    }
  }
  //comes in when program is selected
  programSelect(event: any) {
    if (event.isUserInput) {
      this.hidePagination = false;
      this.hideNoRecords = false;
      console.log(event.source.value);
    }
  }
  //comes in when crn is typed
  crnSelect(event: any) {
    if (event.target.value.length == 5) {
      this.roster_form.get('subjectListsform').disable();
      this.roster_form.get('programCodesListsform').disable();
    } else if (event.target.value.length != 0) {
      this.roster_form.get('subjectListsform').disable();
      this.roster_form.get('programCodesListsform').disable();
      this.hidePagination = false;
      this.hideNoRecords = false;
    } else if (event.target.value.length == 0) {
      this.hidePagination = false;
      this.hideNoRecords = false;
      this.roster_form.get('subjectListsform').enable();
      this.roster_form.get('programCodesListsform').disable();
    }
  }
  //comes in when display roster is clicked
  roster_submit() {
    //this.hidePagination = true;
    this.crnNumber = this.roster_form.get('crnNumberText').value;
    if (this.crnNumber.length == 5) {
      this.getRosterTable(this.roster_form.get('termCodesListsform').value, this.crnNumber);
    }
    else {
      var programArr = this.roster_form.get('programCodesListsform').value;
      var programArrSplit = programArr.split("-");
      this.getRosterTable(this.roster_form.get('termCodesListsform').value, programArrSplit[0]);
    }
  }
  async getSubjects(termcode: any) {
    const subjects = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/term/allSubjects').toPromise();
    this.allSubjects = subjects;
    console.log("all subjects" + this.allSubjects.name);
  }
  async getPrograms(termcode: any, subject: any) {
    if (isNaN(subject)) {
      const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode.slice(-6) + '/termcode/' + subject + '/subject/allPrograms').toPromise();
      this.allPrograms = programs;
    }
  }
  async getRosterTable(termcode: any, programArrSplit: any) {
    const tableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode.slice(-6) + '/termcode/' + programArrSplit + '/crn/' + 'getRosterTableData').toPromise();

    this.dataSource = new MatTableDataSource(tableData as RosterItem[]);
    if (this.dataSource.data.length != 0) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.hidePagination = true;
    } else {
      this.hideNoRecords = true;
    }
  }
}
