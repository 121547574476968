<div [hidden]="!hideFerpaTable">
  <div style="position: relative; left: 15px;">
    <h6>
      <b>Ferpa Information</b>
    </h6>
  </div>
  <div style="height: 200px; width: 125%; margin: auto; margin-left:14px; margin-right:2px; overflow:auto">
    <ag-grid-angular style="height: 200px;"
                     class="ag-theme-alpine"
                     [rowData]="rowData"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     [pagination]="false"
                     [paginationPageSize]="5"
                     [rowMultiSelectWithClick]="true"
                     (gridReady)="onGridReady($event)"
                     [gridOptions]="gridOptions">
    </ag-grid-angular>
  </div>
</div>
