import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MsgmodalComponent } from '../allmodals/msgmodal/msgmodal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DOCUMENT } from '@angular/common';
import { SharedService } from '../Services/students.service';
export interface GradeItem {
  RID: string;
  NAME: string;
  EMAIL: string;
  CLASSIFICATION: string;
  COLLEGE: string;
  DEGREE: string;
  MAJOR: string;
  LIVE_GRADE: string;
  GRADED: string;
  TERM: string;
  CRN: string;
  PTRM: string;
  //no: number;
  rid: string;
  name: string;
  email: string;
  classification: string;
  college: string;
  degree: string;
  major: string;
  current_grade: string;
  last_attendance_date: any;
}
const GRADE_DATA: GradeItem[] = [];

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.css']
})
export class GradeComponent implements OnInit {
  termCodesList: any;
  crnNumber: any;
  allSubjects: any;
  allPrograms: any;
  rosterTable: any;
  hidePagination: boolean = false;
  hideNoRecords: boolean = false;
  formValid: boolean = true;
  successMessage: boolean = true;
  items: any;
  selectedGrade: any;
  elu: any;
  rno: any;
  rnoValue: any
  isDataAvailable: boolean = false;

  selection = new SelectionModel<GradeItem>(true, []);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  windowScrolled: boolean;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    }
    else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }
  displayedColumns: string[] = ['SELECT', 'NUMBER', 'RID', 'NAME', 'EMAIL', 'CLASSIFICATION', 'COLLEGE', 'DEGREE', 'MAJOR', 'LIVE_GRADE', 'GRADED', 'GRADES', 'LAST_ATTENDANCE_DATE', 'TERM', 'CRN', 'PTRM'];
  dataSource = new MatTableDataSource<GradeItem>(GRADE_DATA);
  gradeDropDownData: any;
  roster_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'crnNumberText': new FormControl('', Validators.required),
    'subjectListsform': new FormControl('', Validators.required),
    'programCodesListsform': new FormControl('', Validators.required)
  });
  grade_form = new FormGroup({
    'gradeLastDate': new FormControl('disabled: true', Validators.required)
  });
  constructor(private http: HttpClient, public dialog: MatDialog, @Inject(DOCUMENT) private document: Document, private data: SharedService) {
    console.log("whoami");
    console.log(localStorage.getItem('elu'));
  }

  ngOnInit() {
    this.getUserTerm();
  }
  async getUserTerm() {
    const rno = await this.http.get('dashboard/studentapi/studentdetail/' + localStorage.getItem('elu') + '/getRnumber', { responseType: 'text' }).toPromise();

    //broadcasting rnumber so that it can be used in queries to identify user
    this.data.transmitRNum(rno);
    this.data.latestRNum.subscribe((rnoValue: any) => { this.rno = rnoValue; })
    console.log('gradePageUserRnumber:', this.rno);

    const terms = await this.http.get('dashboard/studentapi/studentdetail/' + rno + '/rno/allTermCodes').toPromise();
    this.termCodesList = terms;
  }
  //comes in when term is selected
  termSelect(event: any) {
    if (event.isUserInput) {
      this.hidePagination = false;
      this.hideNoRecords = false;
      this.roster_form.get('subjectListsform').setValue('');
      this.roster_form.get('programCodesListsform').setValue('');
      this.roster_form.get('programCodesListsform').disable();
      console.log("previous->" + this.roster_form.get('termCodesListsform').value + "current->" + event.source.value);
      this.getSubjects(event.source.value.slice(-6));
    }
  }
  //comes in when subject is selected
  subjectSelect(event: any) {
    console.log(event.source.viewValue);
    if (event.value != "None") {
      this.roster_form.get('crnNumberText').disable();
      this.roster_form.get('programCodesListsform').enable();
      this.hidePagination = false;
      this.hideNoRecords = false;
      console.log("previous->" + this.roster_form.get('termCodesListsform').value + "test" + this.roster_form.value + "current2->" + event.source.value);
      this.getPrograms(this.roster_form.value.termCodesListsform, event.source.value);
    } else if (event.value == "None") {
      this.hidePagination = false;
      this.hideNoRecords = false;
      this.roster_form.get('programCodesListsform').setValue('');
      this.roster_form.get('programCodesListsform').disable();
      this.roster_form.get('crnNumberText').enable();
    }
  }
  //comes in when program is selected
  programSelect(event: any) {
    if (event.isUserInput) {
      this.hidePagination = false;
      this.hideNoRecords = false;
      console.log(event.source.value);
    }
  }
  //comes in when crn is typed
  crnSelect(event: any) {
    if (event.target.value.length == 5) {
      this.roster_form.get('subjectListsform').disable();
      this.roster_form.get('programCodesListsform').disable();
    } else if (event.target.value.length != 0) {
      this.roster_form.get('subjectListsform').disable();
      this.roster_form.get('programCodesListsform').disable();
      this.hidePagination = false;
      this.hideNoRecords = false;
      this.formValid = true;
    } else if (event.target.value.length == 0) {
      this.hidePagination = false;
      this.hideNoRecords = false;
      this.formValid = true;
      this.roster_form.get('subjectListsform').enable();
      this.roster_form.get('programCodesListsform').disable();
    }
  }
  //comes in when display grades button is clicked
  roster_submit() {
    this.crnNumber = this.roster_form.get('crnNumberText').value;
    if (this.crnNumber.length == 5) {
      this.getGradeRosterTable(this.roster_form.get('termCodesListsform').value, this.crnNumber);
    }
    else {
      var programArr = this.roster_form.get('programCodesListsform').value;
      var programArrSplit = programArr.split("-");
      this.getGradeRosterTable(this.roster_form.get('termCodesListsform').value, programArrSplit[0]);
    }
  }

  async getSubjects(termcode: any) {
    const subjects = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/term/subjects/' + this.rno + '/rno').toPromise();
    this.allSubjects = subjects;
    console.log("all subjects" + this.allSubjects.SUBJECT_TITLE);
  }
  async getPrograms(termcode: any, subject: any) {
    if (isNaN(subject)) {
      const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode.slice(-6) + '/termcode/' + subject + '/subject/programs/' + this.rno + '/rno').toPromise();
      this.allPrograms = programs;
    }
  }
  currentHeading: any;
  subjCode: any;
  courseNumber: any;
  section: any;
  async getGradeRosterTable(termcode: any, programArrSplit: any) {
    termcode = termcode.slice(-6);
    const tableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + programArrSplit + '/crn/' + 'allTableData').toPromise();
    const gradeDropDownDataValue = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + programArrSplit + '/crn/' + 'GradeDropDownData').toPromise();

    //using below calls to fetch heading
    //const subjects = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/term/subjects/' + this.rno + '/rno').toPromise();
    const heading = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + programArrSplit + '/crn/' + 'allPrograms').toPromise();
    this.currentHeading = heading[0].FRONT_DISPLAY_VALUE;
    this.subjCode = this.currentHeading.split('-')[0];
    this.courseNumber = this.currentHeading.split('-')[1];
    this.section = this.currentHeading.split('-')[2];
    this.gradeDropDownData = gradeDropDownDataValue;
    //convert integer string grades to integer to seperate from actual string grades
    for (let i = 0; i < this.gradeDropDownData.length; i++) {
      if (!isNaN(this.gradeDropDownData[i])) {
        this.gradeDropDownData[i] = +this.gradeDropDownData[i];
      }
    }
    //sorts grade in Descending order
    this.gradeDropDownData.sort((a, b) => a < b ? 1 : a > b ? -1 : 0);
    this.dataSource = new MatTableDataSource(tableData as GradeItem[]);
    if (this.dataSource.data.length != 0) {
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.hidePagination = true;
    } else {
      this.hideNoRecords = true;
    }
  }
  //stopping propogation of grade click from reaching toggle
  onGradeClick($event) {
    $event.stopPropagation();
  }
  //update the dropdown value when the user clicks to the datasource
  updateDropDownValue(el: GradeItem, grade: string) {
    //at the same time while stopping propogation
    if (!this.selection.isSelected(el)) {
      this.selection.toggle(el)
    }

    console.log("el,grade" + el, grade);
    if (grade == null) {
      return;
    }
    el.current_grade = grade;

    console.log("datasource inbetweeen selected");
    console.log(this.selection.selected.length);
  }
  updateDateValue(el: GradeItem, lastDate: string) {
    console.log("el,grade" + el, lastDate);
    if (lastDate == null) {
      return;
    }
    lastDate = this.changeFormat(lastDate);
    el.last_attendance_date = lastDate;
    console.log(this.dataSource.data);
  }
  //changes the last date attended date format 
  changeFormat(date) {
    let month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let incomingDateChnge: any = new Date(date);
    let incomingDay = incomingDateChnge.getDate();
    let incomingMonth = incomingDateChnge.getMonth();

    let incomingYear = incomingDateChnge.getFullYear();
    if (incomingDay < 10) {
      incomingDay = '0' + incomingDay;
    }
    let dateStr: string = incomingDay + '-' + month_names[incomingMonth] + '-' + incomingYear;
    return dateStr;
  }

  onPaginateChange(event: any) {
    console.log("pagination0");
    console.log(this.dataSource.data);
    console.log("pagination");
    console.log(this.selection)
  }
  //to check save
  grade_submit() {
    let flagCount = 0;
    let length_ = this.selection.selected.length;
    for (let i = 0; i < length_; i++) {
      if (!this.validateRow(i)) {
        flagCount = flagCount + 1;
      }
    }
    if (flagCount < length_) {
      this.formValid = false;
    }
    else {
      this.formValid = true;
      this.sendGradesForSubmit('save');
    }
    console.log(this.selection.selected);
  }

  validateRow(i: number) {
    if (this.selection.selected[i].current_grade == null || this.selection.selected[i].current_grade == undefined) {
      return true;
    }
    else if ((this.selection.selected[i].current_grade == 'F' ||
      this.selection.selected[i].current_grade == 'FA' ||
      (parseInt(this.selection.selected[i].current_grade) >= 0 && parseInt(this.selection.selected[i].current_grade) <= 69)) && this.selection.selected[i].last_attendance_date == null) {
      return true;
    }
    else {
      return false;
    }
  }
  //post api
  async sendGradesForSubmit(task: string) {
    console.log("cames successfully");
    console.log(this.dataSource.data.length);
    console.log(this.selection.selected);
    var p;
    var count = 0;
    for (p = 0; p < this.selection.selected.length; p++) {
      if (this.selection.selected[p].GRADED == 'NO') {
        let dateTime = new Date()
        if (this.selection.selected[p].last_attendance_date == undefined) {

          this.selection.selected[p].last_attendance_date = this.changeFormat(dateTime);
        }
        //this.selection.selected[p].last_attendance_date = this.changeFormat(dateTime);
        var tempItems = '{'
          + '"RID" : "' + this.selection.selected[p].RID + '",'
          + '"EMAIL"  : "' + this.selection.selected[p].NAME + '",'
          + '"EMAIL"  : "' + this.selection.selected[p].EMAIL + '",'
          + '"CLASSIFICATION" : "' + this.selection.selected[p].CLASSIFICATION + '",'
          + '"COLLEGE" : "' + this.selection.selected[p].COLLEGE + '",'
          + '"DEGREE" : "' + this.selection.selected[p].DEGREE + '",'
          + '"MAJOR" : "' + this.selection.selected[p].MAJOR + '",'
          + '"TERM" : "' + this.selection.selected[p].TERM + '",'
          + '"CRN" : "' + this.selection.selected[p].CRN + '",'
          + '"PTRM" : "' + this.selection.selected[p].PTRM + '",'
          + '"CURRENT_GRADE" : "' + this.selection.selected[p].current_grade + '",'
          + '"LAST_ATTENDANCE_DATE" : "' + this.selection.selected[p].last_attendance_date + '",'
          + '"HSC_DATE_ENTERED" : "' + this.changeFormat(dateTime) + '"'
          + '}';
        console.log("json");
        console.log(tempItems);
        let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
        let options = { headers: lclheaders };

        let api = 'dashboard/studentapi/studentdetail/savegrades';
        var data = await this.http.post<string>(api, tempItems, options).toPromise();
        if (data['status'] == 'complete') {
          count++;
        } else if (data['status'] == 'errorrecordalreadyexists') {
          count = 999;
          break;
        }
      }
    }
    //If checkbox is unchecked
    if (count == 0) {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Please use the checkbox to select ungraded records.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
    //if status complete for all records show popup
    else if (count == this.selection.selected.length) {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Grades and Dates Saved Successfully!'
        }
      });

      dialogRef.afterClosed().subscribe(data => {

        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
    else {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Error occured while saving, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
  }
  async loadData(count: number) {
    if (count != 0) {
      this.selection.clear();
      this.items = [{ "RID": "", "NAME": "", "EMAIL": "", "CLASSIFICATION": "", "COLLEGE": "", "DEGREE": "", "MAJOR": "", "GRADES": "", "LAST_ATTENDANCE_DATE": "" }];
      this.dataSource = new MatTableDataSource(this.items);
      this.hidePagination = false;
    } else {
      this.hidePagination = true;
    }
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => {
        if (!this.getBooleanFromBothGrades(row.GRADED, row.LIVE_GRADE)) {
          this.selection.select(row);
        }
      });

  }
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRowsMinusExcluded = this.dataSource.data
      .filter(row => !this.getBooleanFromBothGrades(row.GRADED, row.LIVE_GRADE))
      .length;
    return numSelected === numRowsMinusExcluded;
  }

  getBooleanFromString(gradedString: string) {
    if (gradedString == 'YES') {
      return true;
    } else if (gradedString == 'NO') { return false; }
  }

  //this funtion is used to disable the row for whom have been gradedatleast by temp or from other source(live grades)
  getBooleanFromBothGrades(tempGrades: string, liveGrades: string) {
    if (tempGrades == 'YES' || liveGrades != 'null') {
      return true;
    } else if (tempGrades == 'NO' && liveGrades == 'null') { return false; }
  }

  //this function is to hide grade dropdown of those have been tempgraded AND/OR live graded(other source)
  getBooleanForGradeDropDown(tempGrades: string, liveGrades: string) {
    if (tempGrades == 'YES' || liveGrades != 'null') {
      return false;
    } else if (tempGrades == 'YES' && liveGrades == 'null') {
      return false;
    } else if (tempGrades == 'NO' || liveGrades == 'null') {
      return true;
    } else if (tempGrades == 'NO' && liveGrades == 'null') {
      return true;
    }
  }

  excludeDropDown(event: any) {
    console.log(event)
  }
}


