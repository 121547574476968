import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { SharedService } from '../Services/students.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DashboardPromptModalComponent } from '../allmodals/dashboard-prompt-modal/dashboard-prompt-modal.component';

@Component({
  selector: 'app-component-one',
  templateUrl: './component-one.component.html',
  styleUrls: ['./component-one.component.css']
})
export class ComponentOneComponent implements OnInit {

  studentName: any;
  rNumber: string;
  validStudent: boolean = false;
  rNumberNotFound: boolean = false;
  invalidRNum: boolean = false;
  rNumberList: any;
  termCodes: any;
  selectedQuantity: any;
  rno: any;
  roleDetermination: any;
  searchBar: boolean = false;

  constructor(private http: HttpClient, private data: SharedService, private dialog: MatDialog) { }
  ngOnInit() {
   this.checkUserIsStudentOrAdvisor();
   this.data.currentRNum.subscribe(message => this.rNumber = message)
    
  }
  async checkUserIsStudentOrAdvisor() {
    const rno = await this.http.get('dashboard/studentapi/studentdetail/' + localStorage.getItem('elu') + '/getRnumber', { responseType: 'text' }).toPromise();
    //broadcasting rnumber so that it can be used in queries to identify user
    this.data.transmitRNum(rno);
    this.data.latestRNum.subscribe((rnoValue: any) => { this.rno = rnoValue; })
    console.log('DashboardPageUserRnumber:', this.rno);
    const roles = await this.http.get('dashboard/studentapi/studentdetail/' + rno + '/rno/isStudentOrAdvisor').toPromise();
    this.roleDetermination = roles;
    if (JSON.parse(this.roleDetermination[0].SGBSTDN)) {
      console.log(this.roleDetermination[0].SGBSTDN);
      this.rNumber = this.rno;
      console.log(this.rNumber);
      if (JSON.parse(this.roleDetermination[0].ROLES)) {
        //show prompt
        this.openDialog();
      }
      else if (!JSON.parse(this.roleDetermination[0].ROLES)) {
        //hide search box adn broadcast rnumber
        this.data.changeRNum(this.rNumber);
        this.getUserIfStudent();
      }
    } else if (!JSON.parse(this.roleDetermination[0].SGBSTDN)) {
      //if not a student and an advisor
      console.log("welcome advisor");
      this.searchBar = true;
    }
  }
  async getUserIfStudent() {
    const terms = await this.http.get('dashboard/studentapi/studentdetail/' + this.rNumber + '/termCodes').toPromise();
    this.termCodes = terms;
    this.selectedQuantity = this.termCodes[0].substring(0, 6);
    this.data.changeRNum(this.rNumber + ',' + this.selectedQuantity);// for broadcast
    this.validStudent = true;
    this.rNumberNotFound = false;
    this.searchBar = false;
  }
  openDialog() {
    let dialogRef = this.dialog.open(DashboardPromptModalComponent, { disableClose: true });

    dialogRef.afterClosed().subscribe(result => {
        this.searchBar = result;
      if (!result) {
        this.getUserIfStudent();
      }
    });
  }

  form = new FormGroup({
    'rNumberText': new FormControl(),
    'termCodes': new FormControl()
  })

  submit() {
    this.rNumber = this.form.get('rNumberText').value;
    if (this.rNumber.startsWith('R') && this.rNumber.length == 9) {
      this.invalidRNum = false;
      this.getUser();//not wait
    }
    else {
      this.data.changeRNum('');
      this.invalidRNum = true;//wrong pattern
      this.rNumberNotFound = false;
    }
  }

  async getUser() {
    const name = await this.http.get('dashboard/studentapi/studentdetail/' + this.rNumber + '/name').toPromise();
    this.studentName = name;

    if (this.studentName[0] == 'NA') {
      this.data.changeRNum('');
      this.rNumberNotFound = true;
      this.data.changeRNum('reset');//pattern right but student not found
      this.validStudent = false;
    }
    else {
      this.rNumberNotFound = false;
      const terms = await this.http.get('dashboard/studentapi/studentdetail/' + this.rNumber + '/termCodes').toPromise();
      this.termCodes = terms;
      if (this.termCodes.length == 0) {
        this.data.changeRNum('reset');
        this.rNumberNotFound = true;
        this.validStudent = false;
      }
      else {
        this.selectedQuantity = this.termCodes[0].substring(0, 6);
        this.data.changeRNum(this.rNumber + ',' + this.selectedQuantity);// for broadcast
        this.validStudent = true;
        this.rNumberNotFound = false;
      }
    }
  }
  changeTermCode(event: any) {
    console.log(event);
    //this.data.changeRNum(this.form.get('rNumberText').value + ',' + event.substring(0, 6))
    this.data.changeRNum(this.rNumber.substring(0, 9) + ',' + event.substring(0, 6))
  }

}
