import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-deans-level',
  templateUrl: './deans-level.component.html',
  styleUrls: ['./deans-level.component.css']
})
export class DeansLevelComponent implements OnInit {
  chart: any = [];
  hpUa: any;
  hpGa: any;
  nursingUn: any;
  nursingGn: any;
  infoTabLoading: boolean = false;
  constructor(private http: HttpClient) { }
  ngOnInit() {
    this.infoTabLoading = true;
    this.getStackedBarByLevel();  
  }
  async getStackedBarByLevel() {
    this.hpUa = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountHpUa').toPromise();
    this.hpGa = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountHpGa').toPromise();
    this.nursingUn = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountNursingUn').toPromise();
    this.nursingGn = await this.http.get('dashboard/studentapi/studentdetail/getDeansCountNursingGn').toPromise();

    this.barChartDataLevel = [
      { data: this.hpUa, label: 'Health Professions (UA)', stack: 'a', backgroundColor: "#1E0604", hoverBackgroundColor: "#1E0604" },
      { data: this.hpGa, label: 'Health Professions (GA)', stack: 'a', backgroundColor: "#3B0D08", hoverBackgroundColor: "#3B0D08" },
      { data: this.nursingUn, label: 'Nursing (UN)', stack: 'a', backgroundColor: "#59130C", hoverBackgroundColor: "#59130C" },
      { data: this.nursingGn, label: 'Nursing (GN)', stack: 'a', backgroundColor: "#761910", hoverBackgroundColor: "#761910" }
    ];
  }
  //stack student level chart
  public barChartOptionsLevel: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: 'label',
      callbacks: {
        footer: (tooltipItems, data) => {
          let total = tooltipItems.reduce((a, e) => a + parseInt(e.value), 0);
          return 'Total: ' + total;
        }
      },
      titleFontSize: 25,
      bodyFontSize: 15,
      footerFontSize: 20
    }
  };
  public barChartLabelsLevel: Label[] = ['2017', '2018', '2019', '2020', '2021'];
  public barChartTypeLevel: ChartType = 'bar';
  public barChartLegendLevel = true;
  public barChartPluginsLevel = [];

  public barChartDataLevel: ChartDataSets[];
}
