<div>
  <h5>
    <b>R Number: </b>{{Rnumber}}
  </h5>
</div>
<form [formGroup]="workboardUpdate_form" [hidden]="!hideUpdateForm">
  <table class="table-borderless">
    <tbody>
      <tr>
        <td>
          <mat-form-field style="width:400px;" appearance="outline">
            <mat-label>Residency</mat-label>
            <mat-select formControlName="_residency" required>
              <mat-option *ngFor="let xResidencyType of xResidencyList" [value]="(xResidencyType.RESIDENCY || 'NULL').split('-')[0].trim()">{{xResidencyType.RESIDENCY || 'NULL'}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td> <span style="color: red;" *ngIf="residency && residency.invalid && residency.touched">Please select Residency Type</span></td>
      </tr>
      <tr>
        <td>
          <mat-form-field style="width:400px;" appearance="outline">
            <mat-label>Campus Code</mat-label>
            <mat-select formControlName="_campcode" required>
              <mat-option *ngFor="let xCampusCode of xCampusCodeList" [value]="(xCampusCode.CAMP_CODE || 'NULL').split('-')[0].trim()">{{xCampusCode.CAMP_CODE || 'NULL'}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td> <span style="color: red;" *ngIf="campCode && campCode.invalid && campCode.touched">Please select Campus Type</span></td>
      </tr>
      <tr>
        <td>
          <mat-form-field appearance="outline" style="width:400px;">
            <mat-label>Graduation Term</mat-label>
            <input matInput type="number" formControlName="_gradterm" placeholder="Graduation Term" required min="100000" max="999999">
          </mat-form-field>
        </td>
        <td>
          <span style="color: red;" *ngIf="gradTerm && gradTerm.invalid && gradTerm.touched">
            <ng-container *ngIf="gradTerm.errors.required">Please enter Graduation Term</ng-container>
            <ng-container *ngIf="gradTerm.errors.min || gradTerm.errors.max">Please enter the correct 6-digit Graduation Term</ng-container>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <mat-form-field appearance="outline" style="width:400px;">
            <mat-label>Graduation Date</mat-label>
            <input matInput [matDatepicker]="gradDatePicker" formControlName="_graddate" placeholder="Graduation Date" required>
            <mat-datepicker-toggle matSuffix [for]="gradDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #gradDatePicker></mat-datepicker>
          </mat-form-field>
        </td>
        <td>
          <span style="color: red;" *ngIf="gradDate && gradDate.invalid && gradDate.touched">
            <span *ngIf="gradDate.errors.required">Please enter Graduation Date</span>
            <span *ngIf="gradDate.errors.invalidDate">Please enter a valid date in mm/dd/yyyy format</span>
          </span>
        </td>
      </tr>

      <tr>
        <td>
          <button class="btn btn-dark" *ngIf="!disableSubmitButton" (click)="Update()">Submit</button>
          <div *ngIf="disableSubmitButton"><div class="lds-ring"><div></div><div></div><div></div><div></div></div><br /><br />Processing, please be patient...</div>
        </td>
      </tr>

    </tbody>
  </table>
</form>
<div [hidden]="hideErrorUpdateMessage">
  <p><b>Error:</b> {{ errorMessage }}</p>
  <p>Error occured while updating values, Please report the error to registrar@ttuhsc.edu or adnaik@ttuhsc.edu.</p>
  <div style="position: relative; left: -15px;" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Close</button>
  </div>
</div>
<br />
<div [hidden]="hideSuccessUpdateMessage">
  <div><p>Values Updated Sucessfully</p></div>
  <div style="position: relative; left: -15px;" mat-dialog-actions>
    <button mat-button [mat-dialog-close]="updateSuccessful">Close</button>
  </div>
</div>
