import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MsgmodalComponent } from '../allmodals/msgmodal/msgmodal.component';
import { Module } from 'ag-grid-community';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-crn-access',
  templateUrl: './crn-access.component.html',
  styleUrls: ['./crn-access.component.css']
})
export class CrnAccessComponent implements OnInit {
  rolesTermCodesList: any;
  rNumber: any;
  rNumberList: any;
  hideTermPick: boolean = false;
  termNotFoundPick: boolean = false;
  hideNoRecords: boolean = false;
  hideSavedCrn: boolean = false;
  hideUnsavedCrn: boolean = false;
  infoTabLoading: boolean = false;
  infoTabLoading2: boolean = false;
  isDisabled: boolean = false;
  formValid: boolean = true;
  termCodesList: any;
  accessTermCodesList: any;
  allSubjects: any;
  allCrns: any;
  itemsCrn: any;

  constructor(private http: HttpClient, public dialog: MatDialog) { }

  ngOnInit() {
  }

  rNum_form = new FormGroup({
    '_rNumber': new FormControl('', Validators.required)
  });
  termpick_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required)
  });
  crnUnsaved_form = new FormGroup({
  });
  crnSaved_form = new FormGroup({
  });
  get rNumberForm() {
    return this.rNum_form.get('_rNumber');
  }

  onKeyClear() {
    this.rNumber = '';
    this.termNotFoundPick = false;
    this.hideTermPick = false;
    this.hideUnsavedCrn = false;
    this.hideSavedCrn = false;
    this.hideNoRecords = false;
  }
  onKeyRNum(event: any) {

    if (event.target.value == '') {
      this.rNumber = '';
      this.termNotFoundPick = false;
      this.hideTermPick = false;
      this.hideUnsavedCrn = false;
      this.hideSavedCrn = false;
      this.hideNoRecords = false;
    }
    else {

      this.http.get('dashboard/studentapi/studentdetail/' + event.target.value + '/user/userrolernumber').subscribe(
        response => {
          this.rNumberList = response;
        },
        error => {
          console.log(error);
        })

      if (this.rNum_form.invalid) {
        this.rNumber = '';
      }
    }
  }

  onKeyRNumEnter(event: any) {
    this.rNum_form.controls['_rNumber'].markAsTouched();
  }

  rNumSelect(event: any) {

    if (event.isUserInput) {

      console.log(event.source.value);
      var rNumArrSplit = event.source.value.split(" -");
      if (event.source.value.startsWith('R') && rNumArrSplit[0].length == 9) {
        this.getDropDownValues(rNumArrSplit[0]);
      } else {
        console.log("not cames");
      }
    } else {
      console.log("cames" + event);
    }
  }

  async getDropDownValues(rNumArrSplit: any) {
    const rolestableData = await this.http.get('dashboard/studentapi/studentdetail/' + rNumArrSplit + '/rno/AllTermCodes').toPromise();
    this.rolesTermCodesList = rolestableData;
    if (this.rolesTermCodesList.length === 0) {
      this.termNotFoundPick = true;
      this.hideTermPick = false;
    } else {
      this.termNotFoundPick = false;
      this.hideTermPick = true;
    }
  }

  termSelect(event: any) {
    if (event.isUserInput) {
      console.log(event.source.value.HSCREG_USER_TERM.slice(-6));
      this.crnAccessPick(event.source.value.HSCREG_USER_TERM.slice(-6));
    }
  }
  //Grant CRN Access Table definition
  gridApi1: any;
  gridColumnApi1: any;
  rowData1: any;
  columnDefs1 = [
    {
      field: 'CRNS', sortable: true, filter: true, checkboxSelection: true, headerCheckboxSelection: true
    },
    { field: 'PTRM', sortable: true, filter: true },
    { field: 'SUBJ', sortable: true, filter: true },
    { field: 'CRSE', sortable: true, filter: true },
    { field: 'SECTION', sortable: true, filter: true },
    {
      field: 'HASACCESS', sortable: true, filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['YES', 'NO'],
      },
      editable: true
    }
  ];
  defaultColDef1 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection1 = 'multiple';
  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }
  paginationPageSize1 = 10;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi1.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter1 = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi1.setQuickFilter(globalSearchValue.value);
  }
  //Remove CRN Access Table definition
  gridApi2: any;
  gridColumnApi2: any;
  rowData2: any;
  columnDefs2 = [
    {
      field: 'CRNS', sortable: true, filter: true, checkboxSelection: true, headerCheckboxSelection: true
    },
    { field: 'PTRM', sortable: true, filter: true },
    { field: 'SUBJ', sortable: true, filter: true },
    { field: 'CRSE', sortable: true, filter: true },
    { field: 'SECTION', sortable: true, filter: true },
    {
      field: 'HASACCESS', sortable: true, filter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['YES', 'NO'],
      },
      editable: true
    }
  ];
  defaultColDef2 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection2 = 'multiple';
  onGridReady2(params) {
    this.gridApi2 = params.api;
    this.gridColumnApi2 = params.columnApi;
  }
  paginationPageSize2 = 10;
  onPageSizeChanged2() {
    var pageValue = document.getElementById('page-size2') as HTMLInputElement;
    this.gridApi2.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter2 = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged2() {
    var globalSearchValue = document.getElementById('quickFilter2') as HTMLInputElement;
    this.gridApi2.setQuickFilter(globalSearchValue.value);
  }
  //displayed on clicking terms to display crn for that specific terms
  async crnAccessPick(termcode: any) {
    const unSavedCrns = await this.http.get('dashboard/studentapi/studentdetail/' + this.rNum_form.get('_rNumber').value.substring(0, 9) + '/rid/' + termcode + '/termcode/' + 'allUnsavedRoleTableCrns').toPromise();

    const savedCrns = await this.http.get('dashboard/studentapi/studentdetail/' + this.rNum_form.get('_rNumber').value.substring(0, 9) + '/rid/' + termcode + '/termcode/' + 'allSavedRoleTableCrns').toPromise();

    this.rowData1 = unSavedCrns;
    this.rowData2 = savedCrns;
    if (this.rowData1.length != 0 || this.rowData2.length != 0) {
      this.hideUnsavedCrn = true;
      this.hideSavedCrn = true;
      this.hideNoRecords = false;
    } else {
      this.hideUnsavedCrn = false;
      this.hideSavedCrn = false;
      this.hideNoRecords = true;
    }
  }
  //comes in when clicked on Grant Unsaved CRN access
  async crnUnsaved_submit() {
    var count = 0;
    var p = 0;
    let selectedRows = this.gridApi1.getSelectedRows();
    if (selectedRows.length > 0) {
      this.isDisabled = true;
      this.infoTabLoading = true;
      this.gridColumnApi1.suppressRowClickSelection = true;
    }

    for (p = 0; p < selectedRows.length; p++) {
      var tempItems = '{'
        + '"RID" : "' + this.rNum_form.get('_rNumber').value.substring(0, 9) + '",'
        + '"TERM" : "' + this.termpick_form.get('termCodesListsform').value.HSCREG_USER_TERM.slice(-6) + '",'
        + '"CRNS" : "' + selectedRows[p].CRNS + '",'
        + '"PTRM" : "' + selectedRows[p].PTRM + '",'
        + '"SUBJ" : "' + selectedRows[p].SUBJ + '",'
        + '"CRSE" : "' + selectedRows[p].CRSE + '",'
        + '"SECTION" : "' + selectedRows[p].SECTION + '"'
        + '}';

      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/savecrns';

      var data = await this.http.post<string>(api, tempItems, options).toPromise();
      if (data['status'] == 'complete') {
        count++;
      } else if (data['status'] == 'error') {
        count = 999;
        break;
      }
    }
    //If checkbox is unchecked
    if (count == 0) {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Please use the checkbox to select CRN\'s.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
    else if (count == selectedRows.length) {
      this.crnAccessPick(this.termpick_form.get('termCodesListsform').value.HSCREG_USER_TERM.slice(-6));
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'CRN\'s Assigned Successfully!'
        }
      });

      dialogRef.afterClosed().subscribe(data => {

        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    } else {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Error occured while saving, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }

  }

  //comes in when clicked on Remove CRN access
  async crnSaved_submit() {
    console.log("came to saved");
    var count = 0;
    var p = 0;
    let selectedRows = this.gridApi2.getSelectedRows();
    if (selectedRows.length > 0) {
      this.isDisabled = true;
      this.infoTabLoading2 = true;
    }
    for (p = 0; p < selectedRows.length; p++) {
      var tempItems = '{'
        + '"RID" : "' + this.rNum_form.get('_rNumber').value.substring(0, 9) + '",'
        + '"TERM" : "' + this.termpick_form.get('termCodesListsform').value.HSCREG_USER_TERM.slice(-6) + '",'
        + '"CRNS" : "' + selectedRows[p].CRNS + '"'
        + '}';

      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/deletecrns';

      var data = await this.http.post<string>(api, tempItems, options).toPromise();
      if (data['status'] == 'complete') {
        count++;
      } else if (data['status'] == 'error') {
        count = 999;
        break;
      }
    }
    //If checkbox is unchecked
    if (count == 0) {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Please use the checkbox to select CRN\'s.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
    else if (count == selectedRows.length) {
      this.crnAccessPick(this.termpick_form.get('termCodesListsform').value.HSCREG_USER_TERM.slice(-6));
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'CRN\'s Removed Successfully!'
        }
      });

      dialogRef.afterClosed().subscribe(data => {

        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    } else {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Error occured while saving, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadData(count);
      });
    }
  }

  async loadData(count: number) {

    if (count != 0) {
      /*this.rNum_form.reset();
      this.hideUnsavedCrn = false;
      this.hideTermPick = false;*/
      this.isDisabled = false;
      this.infoTabLoading = false;
      this.infoTabLoading2 = false;
    }
  }
  TermPick_submit() {

  }

  //-------copy terms ts
  copycrn_form = new FormGroup({
    'fromtermCodeform': new FormControl('', Validators.required),
    'totermCodeform': new FormControl('', Validators.required),
    'radioButtonSchoolform': new FormControl('', Validators.required)
  });
  schools = [
    { value: 'GSBS', viewValue: 'GSBS' },
    { value: 'Medicine', viewValue: 'Medicine' },
    { value: 'Pharmacy', viewValue: 'Pharmacy' }
  ];
  fromTermCodeValues: any;
  toTermCodeValues: any;
  hideCopyCrn: boolean = false;
  infoTabLoading3: boolean = false;
  copycrn_div: boolean = true;
  crnacess_div: boolean = false;
  //Grant CRN Access Table definition
  public modules: Module[] = [];
  autoGroupColumnDef = {
    headerName: 'NAME',
    field: 'NAME',
    minWidth: 250,
    cellRenderer: 'agGroupCellRenderer',
    cellRendererParams: { checkbox: true },
  };
  gridApi3: any;
  gridColumnApi3: any;
  rowData3: any;
  columnDefs3 = [
    {
      field: 'CRN', sortable: true, filter: true, checkboxSelection: true, headerCheckboxSelection: true
    },
    {
      field: 'NAME', sortable: true, filter: true, rowGroup: true,
      hide: true
    },
    { field: 'RNUMBER', sortable: true, filter: true }
  ];
  defaultColDef3 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection3 = 'multiple';
  onGridReady3(params) {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
  }
  paginationPageSize3 = 10;
  onPageSizeChanged3() {
    var pageValue = document.getElementById('page-size3') as HTMLInputElement;
    this.gridApi3.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter3 = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged3() {
    var globalSearchValue = document.getElementById('quickFilter3') as HTMLInputElement;
    this.gridApi3.setQuickFilter(globalSearchValue.value);
  }
  async radioChange(event: MatRadioChange) {
    this.hideCopyCrn = false;
    this.toTermCodeValues = null;
    this.copycrn_form.controls['totermCodeform'].setValue('')
    if (event.value == 'GSBS') {
      this.getUniqueCrnsOnloadFromTerm('43', '73', '13');
    } else if (event.value == 'Medicine') {
      this.getUniqueCrnsOnloadFromTerm('53', '83', '23');
    } else if (event.value == 'Pharmacy') {
      this.getUniqueCrnsOnloadFromTerm('55', '75', '25');
    }
  }
  async getUniqueCrnsOnloadFromTerm(valueOne: any, valueTwo: any, valueThree: any) {
    const OnloadFromTerm = await this.http.get('dashboard/studentapi/studentdetail/' + valueOne + '/' + valueTwo + '/' + valueThree + '/crn/FromCrnTerms').toPromise();
    this.fromTermCodeValues = OnloadFromTerm;
  }
  async getUniqueCrnsOnloadToTerm(termcodeLastTwoValue: any) {
    const OnloadFromTerm = await this.http.get('dashboard/studentapi/studentdetail/' + termcodeLastTwoValue + '/crn/ToCrnTerms').toPromise();
    this.toTermCodeValues = OnloadFromTerm;
  }

  fromtermSelect(event: any) {
    if (event.isUserInput) {
      console.log(this.copycrn_form.get('fromtermCodeform').value);
      console.log(event.source.value);
      this.getUniqueCrnsOnloadToTerm(event.source.value.TERM.slice(-2))
      this.getAllCrnDetails(event.source.value.TERM.slice(-6));
    }
  }

  async getAllCrnDetails(termcode: any) {
    const AllCrnDetails = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/crn/CrnTableFromTerm').toPromise();
    this.rowData3 = AllCrnDetails;
    this.hideCopyCrn = true;
    console.log(termcode)
  }
  copycrn_submit() {
    let selectedRows = this.gridApi3.getSelectedRows();
    if (selectedRows.length > 0 && this.copycrn_form.get('totermCodeform').value == '') {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Please select To Term dropdown before you click on Submit'
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        //Clear the spinner if above msgmodal is displayed
        this.infoTabLoading3 = false;
      });
    } else {
      this.sendCrnForCopy();
    }
  }
  async sendCrnForCopy() {
    console.log("came to copy selected CRN's");
    var count = 0;
    var p = 0;
    let selectedRows = this.gridApi3.getSelectedRows();
    if (selectedRows.length > 0) {
      this.infoTabLoading3 = true;
    }

    for (p = 0; p < selectedRows.length; p++) {
      var tempItems = '{'
        + '"FROMTERM" : "' + this.copycrn_form.get('fromtermCodeform').value.TERM.slice(-6) + '",'
        + '"TOTERM" : "' + this.copycrn_form.get('totermCodeform').value.HSCREG_USER_TERM.slice(-6) + '",'
        + '"RID" : "' + selectedRows[p].RNUMBER + '",'
        + '"CRNS" : "' + selectedRows[p].CRN + '"'
        + '}';

      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/copycrns';

      var data = await this.http.post<string>(api, tempItems, options).toPromise();
      if (data['status'] == 'complete') {
        count++;
      } else if (data['status'] == 'error') {
        count = 999;
        break;
      }
    }
    //If checkbox is unchecked
    if (count == 0) {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Please use the checkbox to select the CRN\'s.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadDataCopy(count);
      });
    } else if (count == selectedRows.length) {
      //this.radioChange(this.copycrn_form.get('radioButtonSchoolform').value);(you need to copy the original method duplicate it since event.value is causing issue)
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'CRN\'s Copied Successfully!'
        }
      });

      dialogRef.afterClosed().subscribe(data => {

        //Reset the form after dialog is closed.
        this.loadDataCopy(count);
      });
    } else {
      const dialogRef = this.dialog.open(MsgmodalComponent, {
        data: {
          modalMsg: 'Error occured while copying CRN\'s, Please contact registrar@ttuhsc.edu or adnaik@ttuhsc.edu.'
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //Reset the form after dialog is closed.
        this.loadDataCopy(count);
      });
    }
  }
  async loadDataCopy(count: number) {
    if (count != 0) {
      this.infoTabLoading3 = false;
    }
  }

  toggleFormGroup = new FormGroup({
    'CopyCRN': new FormControl('', Validators.required),
    'GiveCRNAccess': new FormControl('', Validators.required)
  });
  toggleChange(event: any) {
    console.log(event.source.checked)
    if (event.source.checked == false) {
      this.crnacess_div = false;
      this.copycrn_div = true;
    } else if (event.source.checked == true) {
      this.copycrn_div = false;
      this.crnacess_div = true;
    }
  }
  onToggleSubmit() { }
}


