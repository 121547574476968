<div  style="margin-top: 20px;" class="container">
  <h2>Roles</h2>
  <br />
  <!--search box form-->
  <form [formGroup]="rNum_form">
    <table>
      <tr>
        <td>Please Enter Rid: </td>

        <td>
          <mat-form-field class="example-form">
            <input type="text"
                   #rNumber
                   #trigger="matAutocompleteTrigger"
                   placeholder="RID"
                   aria-label="Number"
                   matInput
                   [matAutocomplete]="rNumAuto" (keyup)="onKeyRNum($event)" (keydown.enter)="onKeyRNumEnter($event)"
                   formControlName="_rNumber">

            <mat-autocomplete #rNumAuto="matAutocomplete">
              <mat-option *ngFor="let theRNumber of rNumberList" [value]="theRNumber" (onSelectionChange)="rNumSelect($event)">
                {{theRNumber}}
              </mat-option>
            </mat-autocomplete>
            <button *ngIf="rNum_form.get('_rNumber').value" type="button" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="rNum_form.get('_rNumber').setValue('');onKeyClear($event);"><mat-icon>close</mat-icon></button>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td colspan="2" *ngIf="rNumberForm.touched && rNumberForm.invalid " class="alert alert-danger">
          <p *ngIf="rNumberForm.errors.required">R# is required.</p>

          <!--<p *ngIf="rNumberForm.errors.pattern">R# must be in the format "R12345678"</p>-->
        </td>
        <!--<td colspan="2" *ngIf="rNumberForm.touched && !rNumberForm.invalid && studentName == '' " class="alert alert-danger">
          R# not selected or not found.
        </td>-->
      </tr>
    </table>
  </form>
  <p></p>
  <!--Page Access Grant form-->
  <div id="inner1">
    <!--<div id="first">-->
    <div>
      <form [formGroup]="pagecrnaccesspick_form" [hidden]="!hideAccessDisplayRow">
        <h5>Current Access</h5>
        <div style="margin-bottom: 5px;float:left">
          <input type="text"
                 (input)="onQuickFilterChanged()"
                 id="quickFilter"
                 placeholder="quick filter..." />
        </div>
        <div class="example-header" style="float:right">
          Page Size:
          <select (change)="onPageSizeChanged()" id="page-size">
            <option value="5" selected="">5</option>
            <option value="10">10</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
        <div style="clear: both;">
          <ag-grid-angular style="width: 1110px; height: 309px;"
                           class="ag-theme-alpine"
                           [rowData]="rowData"
                           [columnDefs]="columnDefs"
                           [defaultColDef]="defaultColDef"
                           [pagination]="true"
                           [paginationPageSize]="paginationPageSize"
                           [paginationNumberFormatter]="paginationNumberFormatter"
                           [rowSelection]="rowSelection"
                           [rowMultiSelectWithClick]="true"
                           [frameworkComponents]="frameworkComponents"
                           (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
        <br />
      </form>
      <p [hidden]="!hideAccessDisplayRowError">Details pertaining to the R number not found. Please search with a different R number or assign new role</p>
      <button [hidden]="!hideAccessDisplayRowButton" style="float:left" class="btn btn-dark" type="submit" (click)="assignnewrole_submit()">Assign New Roles</button>
    </div>
  </div>
  <div id="inner2" [hidden]="!hidePageAccess">
    <form [formGroup]="pageaccesspick_form" (ngSubmit)="pageaccesspick_submit()">
      <div id="first">
        <h5>Select School</h5>
        <ag-grid-angular style="width: 220px; height: 267px;"
                         class="ag-theme-alpine"
                         [rowData]="rowData1"
                         [columnDefs]="columnDefs1"
                         [defaultColDef]="defaultColDef1"
                         [rowSelection]="rowSelection1"
                         [rowMultiSelectWithClick]="true"
                         (rowSelected)="onRowSelected1($event)"
                         (gridReady)="onGridReady1($event)">
        </ag-grid-angular>
      </div>
      <div id="second" [hidden]="!hidePageTermAccess">
        <h5>Select Term</h5>
        <ag-grid-angular style="width: 510px; height: 267px;"
                         class="ag-theme-alpine"
                         [rowData]="rowData2"
                         [columnDefs]="columnDefs2"
                         [defaultColDef]="defaultColDef2"
                         [rowSelection]="rowSelection2"
                         [rowMultiSelectWithClick]="true"
                         (rowSelected)="onRowSelected2($event)"
                         (gridReady)="onGridReady2($event)">
        </ag-grid-angular>
      </div>
      <div id="third" [hidden]="!hidePageSelectAccess">
        <h5>Select Pages</h5>
        <ag-grid-angular style="width: 220px; height: 267px;"
                         class="ag-theme-alpine"
                         [rowData]="rowData3"
                         [columnDefs]="columnDefs3"
                         [defaultColDef]="defaultColDef3"
                         [rowSelection]="rowSelection3"
                         [rowMultiSelectWithClick]="true"
                         (rowSelected)="onRowSelected3($event)"
                         (gridReady)="onGridReady3($event)">
        </ag-grid-angular>
      </div>
      <div id="clear"></div>
      <br />
      <div *ngIf="!formValid" class="alert alert-danger">
        Please fill atleast one checkbox in each tile.
      </div>
      <button style="float:left;padding: 10px;margin: 10px;" class="btn btn-dark" type="submit">Grant Access</button>
    </form>
  </div>
  <p></p>
</div>
<br />
