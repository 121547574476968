import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-delete-renderer',
  templateUrl: './button-delete-renderer.component.html',
  styleUrls: ['./button-delete-renderer.component.css']
})
export class ButtonDeleteRendererComponent implements ICellRendererAngularComp  {
  @Input() config: any;
  params;
  buttonText: string; // New property for button text
  buttonClass: string; // New property for button class
  agInit(params): void {
    this.params = params;
    this.config = params.config;
    if (this.config) {
      this.buttonText = this.config.buttonText;
      this.buttonClass = this.config.buttonClass;
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data
        // ...something
      }
      this.params.onClick(params);

    }
  }
}
