<div style="margin-top: 20px;" class="container-fluid">
  <h2>School Graduation Application</h2>
  <br />
  <form [formGroup]="mixed_form" (ngSubmit)="schoolGradboard_form_submit()">
    <div class="row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <!--<mat-option style=" font-style: italic;color: grey;">-- Clear selection --</mat-option>-->
                    <mat-option *ngFor="let termcode of termCodesList;let i =index" [value]="termcode.BACKVALUE_NAME" (onSelectionChange)="termSelect($event)">{{termcode.FRONTVALUE_NAME}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="termCodeFieldHasError()">
                    Term selection is required.
                  </mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field style="width:200px;" appearance="outline">
                  <mat-label>Select Level</mat-label>
                  <mat-select formControlName="levelCodesListsform">
                    <!--<mat-option class="clear-option">-- Clear selection --</mat-option>-->
                    <mat-option *ngFor="let levels of allLevels;let i =index" [value]="levels" (onSelectionChange)="levelSelect($event)">{{levels.STVLEVL_CODE}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="levelFieldHasError()">
                    Level selection is required.
                  </mat-error>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Program</mat-label>
                  <mat-select formControlName="programCodesListsform">
                    <!--<mat-option class="clear-option">-- Clear selection --</mat-option>-->
                    <mat-option *ngFor="let programs of allPrograms;let i =index" [value]="programs">{{programs.SGBSTDN_PROGRAM_1}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="programFieldHasError()">
                    Program selection is required.
                  </mat-error>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td><button class="btn btn-dark" type="submit" [disabled]="mixed_form.invalid">Submit</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
  <br />
  <div *ngIf="showNoRecordsMessage" class="alert alert-warning">
    No records found, please try with another value.
  </div>
  <div *ngIf="!hideData && graduationTable.length > 0" class="ag-grid-controls-container">
    <div class="ag-grid-controls">
      <input type="text"
             (input)="onQuickFilterChanged()"
             id="quickFilter"
             placeholder="quick filter..." />
      <div class="page-size-selector">
        Page Size:
        <select (change)="onPageSizeChanged()" id="page-size">
          <option value="10" selected="">10</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
    
      <ag-grid-angular style="width: 100%; height: 570px;"
                       class="ag-theme-alpine"
                       [rowData]="rowData"
                       [columnDefs]="columnDefs"
                       [defaultColDef]="defaultColDef"
                       [pagination]="true"
                       [paginationPageSize]="paginationPageSize"
                       [paginationNumberFormatter]="paginationNumberFormatter"
                       [rowSelection]="rowSelection"
                       [frameworkComponents]="frameworkComponents"
                       (cellClicked)="checkboxClicked($event)"
                       (selectionChanged)="onSelectionChanged()"
                       (gridReady)="onGridReady($event)"
                       (firstDataRendered)="onFirstDataRendered($event)"
                       (rowDataChanged)="onRowDataChanged()">
      </ag-grid-angular>
    <div style="margin-top: 5px;">
      <button class="btn btn-dark" style="margin-right: 10px;" (click)="selectAllRows('YES')">Check All</button>
      <button class="btn btn-dark" (click)="unselectAllRows('NO')">Uncheck All</button>
    </div>
  </div>
    <br />
  </div>
