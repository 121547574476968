import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkboardtableDegreeModalComponent } from '../../allmodals/workboardtable-degree-modal/workboardtable-degree-modal.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-work-board-table',
  templateUrl: './work-board-table.component.html',
  styleUrls: ['./work-board-table.component.css']
})
export class WorkBoardTableComponent implements OnInit {

  @Input() rowDataSelectedTerm: any;
  @Input() rowDataCurrentRnumber: any;
  activeSnackbar: any;
  //@Output() IndividualHold: EventEmitter<any> = new EventEmitter();
  @Input() WorkItemHoldData: any;
  constructor(private http: HttpClient, private snackBar: MatSnackBar, private dialog: MatDialog,) { }
  checkboxValueSetter = (params) => {
    if (params.colDef.field === "SORPCOL_OFFICIAL_TRANS") {
      if (params.newValue !== params.oldValue) {
        params.data[params.colDef.field] = params.newValue;
        this.updateTableColumnValue(params, params.node, params.colDef.field);
        return true;
      }
    }
    return false;
  }
  ngOnInit() {
    this.gridOptions = {
      domLayout: 'normal'
    };
    this.getWorkDetailRowData(this.rowDataSelectedTerm, this.rowDataCurrentRnumber);
  }
  async getWorkDetailRowData(rowDataTerm: any, rno: any) {
    const finalRowData = await this.http.get('dashboard/studentapi/studentdetail/' + rowDataTerm + '/termcode/' + rno + '/rno/' + 'workTableDetailsData').toPromise();
    this.rowData1 = finalRowData;
    const holdData = await this.http.get('dashboard/studentapi/studentdetail/' + rno + '/rno/' + 'workTableHoldData').toPromise();
    //this.IndividualHold.emit(holdData[0].HOLDS);
    this.WorkItemHoldData.HOLD = holdData[0].HOLDS;
  }

  //Work board Table definition
  gridApi1: any;
  gridColumnApi1: any;
  rowData1: any;
  public gridOptions;
  columnDefs1 = [
    {
      headerName: "",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 50, minWidth: 50
    },
    {
      headerName: 'Institution Code',
      field: 'SORPCOL_SBGI_CODE', sortable: true, filter: true,
      maxWidth: 135

    },
    {
      headerName: 'Institution Name',
      field: 'STVSBGI_DESC', sortable: true, filter: true,
      maxWidth: 300, minWidth: 200

    },
    {
      headerName: 'Date Received',
      field: 'DATE_RECEIVED', sortable: true, filter: true, editable: true,
      maxWidth: 140
    },
    {
      headerName: 'Date Reviewed',
      field: 'DATE_REVIEWED', sortable: true, filter: true, editable: true,
      maxWidth: 145
    },
    {
      headerName: 'Official Transcript',
      field: 'SORPCOL_OFFICIAL_TRANS',
      sortable: true,
      filter: true,
      editable: true,
      maxWidth: 161,
      minWidth: 40,
      valueSetter: this.checkboxValueSetter,
      cellRenderer: function (params) {
        var input = document.createElement('input');
        input.type = 'checkbox';
        input.checked = params.value === 'Y';
        input.addEventListener('change', function () {
          params.node.setDataValue('SORPCOL_OFFICIAL_TRANS', input.checked ? 'Y' : 'N');
        });
        return input;
      },
      valueGetter: function (params) {
        return params.data.SORPCOL_OFFICIAL_TRANS;
      },
    },
    {
      headerName: 'Degree',
      field: 'DEGREE',
      sortable: true,
      filter: true,
      editable: true,
      maxWidth: 140,
      cellRenderer: (params) => {
        const container = document.createElement('div');
        container.style.display = 'flex';
        const valueContainer = document.createElement('div');
        valueContainer.className = 'value-container';
        const valueElement = document.createElement('span');
        valueElement.innerText = params.value;
        valueContainer.appendChild(valueElement);
        const buttonContainer = document.createElement('div');
        const buttonElement = document.createElement('button');
        buttonElement.type = 'button';
        buttonElement.innerText = '...';
        buttonElement.className = 'ellipsis-button custom-degree-button';
        buttonElement.innerHTML = '&hellip;';
        buttonElement.addEventListener('click', (event) => {
          this.openDegreeModal(event, { ...params, node: params.node });
        });
        buttonContainer.appendChild(buttonElement);
        container.appendChild(valueContainer);
        container.appendChild(buttonContainer);
        return container;
      }
    },
    {
      headerName: 'Degree Date',
      field: 'SORDEGR_DEGC_DATE', sortable: true, filter: true, editable: true,
      maxWidth: 140

    } , {
      headerName: 'Attended From',
      field: 'SORDEGR_ATTEND_FROM', sortable: true, filter: true, editable: true,
      maxWidth: 145
    }, {
      headerName: 'Attended To',
      field: 'SORDEGR_ATTEND_TO', sortable: true, filter: true, editable: true,
      maxWidth: 145
    },
    {
      headerName: 'Transferred Hours',
      field: 'SORDEGR_HOURS_TRANSFERRED', sortable: true, filter: true, editable: true,
      maxWidth: 165

    },
    {
      headerName: 'Notes',
      field: 'NOTES',
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      flex: 2,
    },
  ];
  
  defaultColDef1 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection1 = 'multiple';
  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }
  paginationPageSize1 = 10;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi1.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter1 = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi1.setQuickFilter(globalSearchValue.value);
  }

  async onCellValueChanged(event) {
    const rowNode = this.gridApi1.getDisplayedRowAtIndex(event.rowIndex);
    let columnId = event.column.colId;
    if (['DATE_RECEIVED', 'DATE_REVIEWED', 'SORDEGR_DEGC_DATE', 'SORDEGR_ATTEND_FROM', 'SORDEGR_ATTEND_TO', 'DEGREE', 'SORDEGR_HOURS_TRANSFERRED'].includes(columnId)) {
      await this.updateTableColumnValue(event, rowNode, columnId);
    }
    if (event === undefined || event.data.NOTES === undefined) {
      event.data.NOTES = '';
    }
    this.sendNotesToSave(event.data.NOTES, event.data.SORPCOL_SBGI_CODE, event.data.DEGREE);
  }
  async sendNotesToSave(notesValue: any, institutionCode, degree) {
    var tempItems = '{'
      + '"RID" : "' + this.rowDataCurrentRnumber + '",'
      + '"TERM" : "' + this.rowDataSelectedTerm + '",'
      + '"INSTITUTION_CODE" : "' + institutionCode + '",'
      + '"DEGREE" : "' + degree + '",'
      + '"NOTES" : "' + notesValue + '"'
      + '}';

    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    let api = 'dashboard/studentapi/studentdetail/saveNotesData';

    var data = await this.http.post<string>(api, tempItems, options).toPromise();
    if (data['status'] == 'complete') {
      console.log('Notes Saving Success');
    } else if (data['status'] == 'error') {
      console.log('Notes Saving Failed');
    }
  }
  dateFormatValidator(date: string): boolean {
    const format = /^\d{1,2}\/\d{1,2}\/\d{4}$/;  // mm/dd/yyyy format
    if (!format.test(date)) {
      return false;
    }
    // Parse the date string manually
    const [month, day, year] = date.split('/');
    // Build a new Date object
    let parsedDate = new Date(Number(year), Number(month) - 1, Number(day));
    // Check if the date is valid
    if (parsedDate.getFullYear() !== Number(year) || parsedDate.getMonth() + 1 !== Number(month) || parsedDate.getDate() !== Number(day)) {
      // The date string isn't a valid date
      return false;
    }
    // The date string is a valid date
    return true;
  }
  officialTranscriptValidator(value: string): boolean {
    const validValues = ['Y', 'N', 'NULL'];
    return validValues.includes(value);
  }
  degcCodeValidator(value: string): boolean {
    const format = /^[a-zA-Z0-9]+$/;
    return format.test(value) || value.toUpperCase() === 'NULL';
  }
  hoursTransferredValidator(value: string): boolean {
    const format = /^\d{1,5}(\.\d{1,2})?$/;
    if (!format.test(value) || value.toUpperCase() === 'NULL') {
      return false;
    }
    const floatValue = parseFloat(value);
    return floatValue <= 999.99;
  }
  
  async updateTableColumnValue(event, rowNode, columnId) {
    let newValue = event.newValue;
    let tempData = {
      ...event.data
    }

    let isValid = false;

    if (columnId === 'DATE_RECEIVED' || columnId === 'DATE_REVIEWED' || columnId === 'SORDEGR_DEGC_DATE' || columnId === 'SORDEGR_ATTEND_FROM' || columnId === 'SORDEGR_ATTEND_TO') {
      isValid = this.dateFormatValidator(newValue);
    } else if (columnId === 'SORPCOL_OFFICIAL_TRANS') {
      isValid = this.officialTranscriptValidator(newValue);
    } else if (columnId === 'DEGREE') {
      isValid = this.degcCodeValidator(newValue);
    } else if (columnId === 'SORDEGR_HOURS_TRANSFERRED') {
      isValid = this.hoursTransferredValidator(newValue);
    }

    if (!isValid) {
      this.showSnackbarAction(`Please enter a valid value for ${columnId}`, null);
      tempData[columnId] = event.oldValue;
      rowNode.setData(tempData);
    } else {
      let newData = {
        ...event.data,
        [columnId]: newValue === 'NULL' ? null : newValue
      }
      await this.postDataToApi(newData, columnId, rowNode, event);
    }
  }
  async postDataToApi(data, columnId, rowNode, event) {
    console.log(data)
    this.showSnackbarAction('Please wait...Value is getting updated.', null, 0);
    // Set of keys to exclude
    const keysToExclude = new Set(['STVSBGI_DESC']);

    // Initialize new data object
    let newData = {};

    // Iterate over original data
    for (let key in data) {
      // Skip unwanted keys
      if (keysToExclude.has(key)) {
        continue;
      }

      // Rename keys and convert 'NULL' string to null
      if (key === 'DATE_RECEIVED') {
        newData['SORPCOL_TRANS_RECV_DATE'] = data[key] === 'NULL' ? null : data[key];
      } else if (key === 'DATE_REVIEWED') {
        newData['SORPCOL_TRANS_REV_DATE'] = data[key] === 'NULL' ? null : data[key];
      } else if (key === 'DEGREE') {
        newData['SORDEGR_DEGC_CODE'] = data[key] === 'NULL' ? null : data[key];
      } else if (key === 'SORDEGR_DEGC_YEAR') {
        newData['SORDEGR_DEGC_YEAR'] = data[key] === 'NULL' ? null : data[key];
      } else if (key === 'SORDEGR_DEGC_DATE') {
        newData['SORDEGR_DEGC_DATE'] = data[key] === 'NULL' ? null : data[key];
      } else if (key === 'SORDEGR_ATTEND_FROM') {
        newData['SORDEGR_ATTEND_FROM'] = data[key] === 'NULL' ? null : data[key];;
      } else if (key === 'SORDEGR_ATTEND_TO') {
        newData['SORDEGR_ATTEND_TO'] = data[key] === 'NULL' ? null : data[key];;
      } else if (key === 'SORDEGR_HOURS_TRANSFERRED') {
        newData['SORDEGR_HOURS_TRANSFERRED'] = data[key] === 'NULL' ? null : data[key].toString();
      } else {
        newData[key] = data[key] === 'NULL' ? null : data[key];
      }
    }

    // Add SPRIDEN_ID
    newData['SPRIDEN_ID'] = this.rowDataCurrentRnumber;

    
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    let api = 'dashboard/studentapi/studentdetail/updateSOAPCOL';
    console.log(JSON.stringify(newData));

    try {
      this.showSnackbarAction('Please wait...Value is getting updated.', null, 0);
      var response = await this.http.post<any>(api, newData, options).toPromise();

      if (response.status === 'SOAPCOL Updated!') {
        if (this.activeSnackbar) {
          this.activeSnackbar.dismiss();
        }
        this.showSnackbarAction('Value updated.', null);
      } else {
        let tempData = {
          ...data,
          [columnId]: event.oldValue
        };
        rowNode.setData(tempData);
        console.log('Error:', response.status);
        if (this.activeSnackbar) {
          this.activeSnackbar.dismiss();
        }
        this.showSnackbarAction('Error: ' + response.status, null);
      }
    } catch (error) {
      console.log('Error:', error);
      if (this.activeSnackbar) {
        this.activeSnackbar.dismiss();
      }
      this.showSnackbarAction('Error: ' + error.message, null);
    }
  }
  showSnackbarAction(content, action, duration = 4000) {
    this.activeSnackbar = this.snackBar.open(content, action, { duration: duration });
    this.activeSnackbar.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    this.activeSnackbar.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

  openDegreeModal(event, params) {
    const rowNode = params.node;
    const dialogRef = this.dialog.open(WorkboardtableDegreeModalComponent, {
      width: '500px',
      data: { RNumber: this.rowDataCurrentRnumber, params: params }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result.params.data);
        const newValue = result.degreeCode;
        const columnId = 'DEGREE';
        rowNode.setDataValue(columnId, newValue);
        this.updateTableColumnValue({ ...result.params, newValue }, rowNode, columnId);
      }
    });
  }
}
