<div *ngIf="showComponentThree">
  <div class="container">
    <!--Holds-->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Holds
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="example-container row">
          <div class="col-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Hold Code</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Reason</th>
                  <th>Amount Owed</th>
                  <th>Department</th>
                  <th>Sprhold Activity Date</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let componentThreeValues of componentThreecompThreeHolds">
                  <td>{{componentThreeValues.SPRHOLD_HLDD_CODE }}</td>
                  <td>{{componentThreeValues.SPRHOLD_FROM_DATE}}</td>
                  <td>{{componentThreeValues.SPRHOLD_TO_DATE}}</td>
                  <td>{{componentThreeValues.SPRHOLD_REASON}}</td>
                  <td>{{componentThreeValues.SPRHOLD_AMOUNT_OWED}}</td>
                  <td>{{componentThreeValues.DEPARTMENT}}</td>
                  <td>{{componentThreeValues.SPRHOLD_ACTIVITY_DATE}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <p></p>
    <!--TimeTickets-->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            TimeTickets
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="example-container row">
          <div class="col-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Term Code</th>
                  <th>Group Code</th>
                  <th>Registration Start Date</th>
                  <th>Registration Start Hour</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let componentThreeValues of componentThreeTimeTickets">
                  <td>{{componentThreeValues.TERM_CODE}}</td>
                  <td>{{componentThreeValues.GROUP_CODE}}</td>
                  <td>{{componentThreeValues.REGISTRATION_START_DATE}}</td>
                  <td>{{componentThreeValues.REGISTRATION_START_HOUR}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <p></p>
    <!--Permits-->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Permits
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="example-container row">
          <div class="col-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Term Code</th>
                  <th>Permit Code</th>
                  <th>Subject Code</th>
                  <th>Course Number</th>
                  <th>Section</th>
                  <th>CRN</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let componentThreeValues of componentThreePermits">
                  <td>{{componentThreeValues.TERM_CODE}}</td>
                  <td>{{componentThreeValues.PERMIT_CODE }}</td>
                  <td>{{componentThreeValues.SUBJECT_CODE}}</td>
                  <td>{{componentThreeValues.COURSE_NUMBER}}</td>
                  <td>{{componentThreeValues.SECTION}}</td>
                  <td>{{componentThreeValues.CRN}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <p></p>
    <!--Attributes-->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Attributes
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="example-container row">
          <div class="col-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Term Code Effective</th>
                  <th>Attribute</th>
                  <th>End Term Code</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let componentThreeValues of componentThreeAttributes">
                  <td>{{componentThreeValues.TERM_CODE_EFFECTIVE}}</td>
                  <td>{{componentThreeValues.ATTRIBUTE }}</td>
                  <td>{{componentThreeValues.END_TERM_CODE}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <p></p>
    <!--Cohort-->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Cohort
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="example-container row">
          <div class="col-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Term Code Effective</th>
                  <th>Cohort</th>
                  <th>End Term Code</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let componentThreeValues of componentThreeCohort">
                  <td>{{componentThreeValues.TERM_CODE_EFFECTIVE}}</td>
                  <td>{{componentThreeValues.COHORT }}</td>
                  <td>{{componentThreeValues.END_TERM_CODE}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <p></p>
    <!--Emergency Contacts-->
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true"
                           (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Emergency Contacts
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="example-container row">
          <div class="col-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Priority #</th>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Street 1</th>
                  <th>Street 2</th>
                  <th>Street 3</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Nation</th>
                  <th>Zip</th>
                  <th>Phone Number</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let componentThreeValues of componentThreeEmrg">
                  <td>{{componentThreeValues.SPREMRG_PRIORITY}}</td>
                  <td>{{componentThreeValues.SPREMRG_FIRST_NAME }}</td>
                  <td>{{componentThreeValues.SPREMRG_MI}}</td>
                  <td>{{componentThreeValues.SPREMRG_LAST_NAME}}</td>
                  <td>{{componentThreeValues.SPREMRG_STREET_LINE1}}</td>
                  <td>{{componentThreeValues.SPREMRG_STREET_LINE2}}</td>
                  <td>{{componentThreeValues.SPREMRG_STREET_LINE3}}</td>
                  <td>{{componentThreeValues.SPREMRG_CITY}}</td>
                  <td>{{componentThreeValues.SPREMRG_STAT_CODE}}</td>
                  <td>{{componentThreeValues.SPREMRG_NATN_CODE}}</td>
                  <td>{{componentThreeValues.SPREMRG_ZIP}}</td>
                  <td>{{componentThreeValues.SPREMRG_PHONE_NUMBER}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<br />
<br />
