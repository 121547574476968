<div  style="margin-top: 20px;" class="container-fluid">
  <h2>Transfer Board</h2>
  <br />
  <form [formGroup]="transferboard_form" (ngSubmit)="transferboard_submit()">
    <div class="row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td>
                <mat-form-field style="width:400px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <mat-option *ngFor="let termcode of termCodesList;let i =index" [value]="termcode.BACKVALUE_NAME" (onSelectionChange)="termSelect($event)">{{termcode.FRONTVALUE_NAME}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <mat-form-field style="width:500px;" appearance="outline">
                  <mat-label>Select Program</mat-label>
                  <mat-select formControlName="programCodesListsform">
                    <mat-option *ngFor="let programs of allPrograms;let i =index" [value]="programs">{{programs.SGBSTDN_PROGRAM_1}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td><button class="btn btn-dark" type="submit">Submit</button></td>
            </tr>
          </tbody>
        </table>
        <div style="padding: 20px 20px 20px 4px;" [hidden]="!dropdownSelectError">
          <p>Please select either one or both drop downs.</p>
        </div>
        <div style="padding: 20px 20px 20px 4px;" [hidden]="!hideNoRecords">
          <p>No records found. Please try with another Term.</p>
        </div>
      </div>
    </div>
  </form>
  <br />
  <!-- <form [formGroup]="showmeRes" (ngSubmit)="showmeRes_submit()">
    <button class="btn btn-dark" type="submit">Submit</button>
   </form><br />-->
  <form [formGroup]="transferboardDetailsTable_form" (ngSubmit)="transferboardDetailsTable_submit()">
    <div *ngIf="infoTabLoading" style="text-align:center;"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    <div [hidden]="!hidePagination">
      <table class="table-borderless">
        <tbody>
          <tr>
            <td>
              <div *ngIf="transferFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ transferFilters[0].name }}</mat-label>
                  <mat-select [(value)]="transferFilters[0].defaultValue"
                              (selectionChange)="applyTransferFilter($event, transferFilters[0])">
                    <mat-option *ngFor="let op of transferFilters[0].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div *ngIf="transferFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ transferFilters[1].name }}</mat-label>
                  <mat-select [(value)]="transferFilters[1].defaultValue"
                              (selectionChange)="applyTransferFilter($event, transferFilters[1])">
                    <mat-option *ngFor="let op of transferFilters[1].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div *ngIf="transferFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ transferFilters[2].name }}</mat-label>
                  <mat-select [(value)]="transferFilters[2].defaultValue"
                              (selectionChange)="applyTransferFilter($event, transferFilters[2])">
                    <mat-option *ngFor="let op of transferFilters[2].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td>
              <div *ngIf="transferFilters.length !==0">
                <mat-form-field appearance="fill">
                  <mat-label>{{ transferFilters[3].name }}</mat-label>
                  <mat-select [(value)]="transferFilters[3].defaultValue"
                              (selectionChange)="applyTransferFilter($event, transferFilters[3])">
                    <mat-option *ngFor="let op of transferFilters[3].options" [value]="op">
                      {{ op }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </td>
            <td><div><button class="btn btn-dark" (click)="clearFilters()">Clear All Filters</button></div></td>
          </tr>
        </tbody>
      </table>
      <div style="float:left">
        <mat-form-field appearance="standard">
          <mat-label>Search Transfer-Board Data</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex.R123456789" #inputo>
        </mat-form-field>
      </div>
      <div class="example-header" style="float:right">
        <mat-paginator #myPaginator [pageSizeOptions]="[5,10,15]" showFirstLastButtons></mat-paginator>
      </div>
      <div class="mat-elevation-z8" style="clear: both;">
        <mat-table #table
                   [dataSource]="dataSource"
                   class="table">
          <!-- Common Column -->
          <ng-container matColumnDef="COMMON">
            <mat-header-cell *matHeaderCellDef> TRANSFER LIST </mat-header-cell>
            <mat-cell *matCellDef="let TransferItem; let i = index">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-6 col-md-4">
                    <b>R Number:</b> {{TransferItem.RNUMBER}} <br />
                    <b>Program:</b> {{TransferItem.PROGRAM_CODE}}<br />
                    <b>Classification:</b> {{TransferItem.CLASSIFICATION}}<br />
                    <b>Core Complete:</b> {{TransferItem.CORE_COMPLETE}}<br />
                    <b>Degreework Status (Prerequisite): </b> <span [style.background-color]="TransferItem.PREREQUISITE == 'COMPLETE'?'#00FF00':'#ff4040'">{{TransferItem.PREREQUISITE}}</span><br />
                    <b>Residency:</b><span [style.background-color]="TransferItem.RESIDENCY == 'NULL'?'#ff4040':null"> {{TransferItem.RESIDENCY}}</span> <br />
                    <div style="float: left; margin-right: 5px;margin-bottom: 2px;">
                      <button class="btn btn-dark" type="submit" (click)=showXtenderDocumentType(TransferItem.RNUMBER)>Display Xtender Documents</button>
                    </div>
                    <!--<div style="float: left;">
    <button class="btn btn-dark" type="submit" (click)=uploadXtenderDocument(TransferItem.RNUMBER,TransferItem.TERM)>Upload Xtender Document</button>
  </div>-->
                    <div style="clear: both;"></div>
                  </div>
                  <div class="col-6 col-md-4">
                    <b>Full Name:</b> {{TransferItem.FULLNAME}}<br />
                    <b>Concentration:</b> {{TransferItem.CONCENTRATION}}<br />
                    <b>Level:</b> {{TransferItem.STV_LEVEL}}<br />
                    <b>Core Complete Institution:</b> {{TransferItem.CORE_COMPLETE_INSTITUTION}}<br />
                    <b>Student Satus:</b> {{TransferItem.STUDENT_STATUS}}
                  </div>
                  <div class="col-6 col-md-4">
                    <div style="display: flex; align-items: center;">
                      <b>HVAD: </b><span [style.background-color]="TransferItem.HVAD == 'NULL'?'#ff4040':null"> {{TransferItem.HVAD}}</span>
                      <button *ngIf="TransferItem.HVAD !== 'HVAD'" class="btn btn-dark" style="margin-left: 75px; font-size: 80%; padding: 4px 8px; line-height: 1.0;" (click)="InsertHvad(TransferItem.RNUMBER, TransferItem.TERM)">Add HVAD</button>
                    </div>
                    <b>Admit Term:</b> {{TransferItem.HVAD_ADMIT_TERM}}<br />
                    <b>TSI Status:</b> {{TransferItem.TSI_STATUS}}<br />
                    <b>Drop Count:</b> {{TransferItem.DROP_COUNT}}<br />
                    <b>Drop Limit:</b> {{TransferItem.DROP_LIMIT}}<br />
                  </div>
                </div>
                <br />
                <br />
                <div class="row" style="position: relative; left: -15px;">
                  <div class="col">
                    <app-transfer-board-table [dataSoureElements]="TransferItem" [rowDataSelectedTerm]="selectedTerm" [rowDataCurrentRnumber]="TransferItem.RNUMBER"></app-transfer-board-table>
                  </div>
                </div>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </div>
      <div style="padding: 20px 20px 20px 4px;" [hidden]="!hideFilteredError">
        <p>No records found after filtering. Please try again with another filter.</p>
      </div>
      <br />

      <br />
      <br />

    </div>
    <br />
    <!--<div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
      <button type="button" class="btn active" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
        <i class="arrow up"></i>
      </button>
    </div>-->
  </form>
  <form [formGroup]="showmeRes" (ngSubmit)="showmeRes_submit()">
  </form>
</div>
