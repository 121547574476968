import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-component-three',
  templateUrl: './component-three.component.html',
  styleUrls: ['./component-three.component.css']
})
export class ComponentThreeComponent implements OnInit {
  showComponentThree: boolean = false;
  @Input() myValPropThree: string;
  inputs: any;
  propChanges: any;
  componentThree: any;
  componentThreecompThreeHolds: any;
  componentThreeTimeTickets: any;
  componentThreePermits: any;
  componentThreeAttributes: any;
  componentThreeCohort: any;
  componentThreeEmrg: any;
  panelOpenState = false;

  constructor(private http: HttpClient) { }

  ngOnChanges(changes: SimpleChanges) {
    this.propChanges = changes;
    console.log("propChanges.myValPropThree.currentValue: " + this.propChanges.myValPropThree.currentValue);
    if (this.propChanges.myValPropThree.currentValue != '' && this.propChanges.myValPropThree.currentValue != 'reset') {
      this.inputs = this.propChanges.myValPropThree.currentValue.split(',');
      this.getDataComponentThree();
    }
    else if (this.propChanges.myValPropThree.currentValue == 'reset') {
      this.showComponentThree = false;
      this.componentThree = [];
    }

  }
  async getDataComponentThree() {
    //gets Holds
    const compThreeTimeTicketsHolds = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/ComponentThreeHolds').toPromise();
    this.componentThreecompThreeHolds = compThreeTimeTicketsHolds;

    //gets TimeTickets
    const compThreeTimeTickets = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/' + this.inputs[1] + '/term/ComponentThreeTimeTickets').toPromise();
    this.componentThreeTimeTickets = compThreeTimeTickets;

    //gets permits
    const compThreePermits = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/' + this.inputs[1] + '/term/ComponentThreePermits').toPromise();
    this.componentThreePermits = compThreePermits;

    //gets Attributes
    const compThreeAttributes = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/' + this.inputs[1] + '/term/ComponentThreeAttributes').toPromise();
    this.componentThreeAttributes = compThreeAttributes;

    //gets Cohort
    const compThreeCohort = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID/' + this.inputs[1] + '/term/ComponentThreeCohort').toPromise();
    this.componentThreeCohort = compThreeCohort;

    //gets EmergencyDetails
    const compThreeEmrg = await this.http.get('dashboard/studentapi/studentdetail/' + this.inputs[0] + '/ID' + '/ComponentThreeEmrg').toPromise();
    this.componentThreeEmrg = compThreeEmrg;

    this.showComponentThree = true;
  }
  ngOnInit() {
  }

}
