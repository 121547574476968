<div style="margin-top: 20px;"  class="container">
  <h1>Registrar Dashboard</h1>
  <p>Welcome to Registrar Dashboard</p>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div *ngIf="searchBar">
      <table>
        <tbody>
          <tr>
            <td>
              <input type="text"
                     #rnumber
                     placeholder="Student ID"
                     aria-label="Number"
                     required
                     formControlName="rNumberText">
            </td>
            <td><button class="btn btn-dark" type="submit">Search</button></td>
          </tr>
          <tr>
            <td>
              <p *ngIf="invalidRNum">Incorrect R#</p>
              <p *ngIf="rNumberNotFound">R# not found or not a student.</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
      <br>

      <div *ngIf="validStudent">
        <select formControlName="termCodes" class="form-control" (change)="changeTermCode($event.target.value)" onfocus='if(this.options.length > 5){ this.size = 5; }' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
          <option *ngFor="let termcode of termCodes">{{termcode}}</option>
        </select>
        <div>
          <app-component-two [myValPropTwo]="rNumber"></app-component-two>
          <app-component-three [myValPropThree]="rNumber"></app-component-three>
        </div>
      </div>
      <br>
  </form>
</div>

