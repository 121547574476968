import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-prompt-modal',
  templateUrl: './dashboard-prompt-modal.component.html',
  styleUrls: ['./dashboard-prompt-modal.component.css']
})
export class DashboardPromptModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<DashboardPromptModalComponent>) { }

  ngOnInit() {
  }
  AdvisorClick(): void {
    this.dialogRef.close(true);
  }
  StudentClick(): void {
    this.dialogRef.close(false);
  }
}
