import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../Services/students.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ButtonGroupActionRendererComponent } from '../Button Renderer/button-group-action-renderer/button-group-action-renderer.component';

@Component({
  selector: 'app-combined-graduation-dashboard',
  templateUrl: './combined-graduation-dashboard.component.html',
  styleUrls: ['./combined-graduation-dashboard.component.css']
})
export class CombinedGraduationDashboardComponent implements OnInit {
  rNumber: any;
  nameList: any;
  rNumList: any;
  combinedList: any;
  selectedQuantity: any;
  termCodesList: any;
  allLevels: any;
  allPrograms: any;
  degreeOrCertificateDetermined: any;
  infoTabLoading: boolean = false;
  enrollmentData: any;
  enrollmentTableData: any
  hideData: boolean = true;
  frameworkComponents: any;
  constructor(private http: HttpClient, private toastr: ToastrService, private data: SharedService) {
    this.frameworkComponents = {
      actionButtonRenderer: ButtonGroupActionRendererComponent
    }
  }
  ngOnInit(): void {
    this.gridOptions = {
      domLayout: 'normal'
    };
    this.http.get('dashboard/studentapi/studentdetail/AllGradDashBoardTermCodes').subscribe(
      (data: any) => { this.termCodesList = data; console.log(this.termCodesList); });
  }
  mixed_form = new FormGroup({
    'termCodesListsform': new FormControl('', Validators.required),
    'levelCodesListsform': new FormControl('', Validators.required),
    'programCodesListsform': new FormControl('', Validators.required),
  });
  termCodeFieldHasError(): boolean {
    const termControl = this.mixed_form.get('termCodesListsform');
    return termControl.hasError('required') && termControl.touched;
  }
  levelFieldHasError(): boolean {
    const levelControl = this.mixed_form.get('levelCodesListsform');
    return levelControl.hasError('required') && levelControl.touched;
  }
  programFieldHasError(): boolean {
    const programControl = this.mixed_form.get('programCodesListsform');
    return programControl.hasError('required') && programControl.touched;
  }
  termSelect(event: any) {
    if (event.isUserInput) {
      this.getLevels(event.source.value.slice(-6));
    }
  }
  async getLevels(termcode: any) {
    const levels = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/getLevelFromTerm').toPromise();
    this.allLevels = levels;
    this.mixed_form.get('levelCodesListsform').setValue('');
    this.mixed_form.get('programCodesListsform').setValue('');
  }
  levelSelect(event: any) {
    if (event.isUserInput) {
      const termcode = this.mixed_form.get('termCodesListsform').value.slice(-6);
      const level = event.source.value.STVLEVL_CODE;
      this.getPrograms(termcode, level);
    }
  }
  async getPrograms(termcode: any, level: string) {
    const programs = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + level + '/level/getProgramFromTermAndLevel').toPromise();
    this.allPrograms = programs;
    this.mixed_form.get('programCodesListsform').setValue('');
  }
  async schoolGradboard_form_submit() {
    const termcode = this.mixed_form.get('termCodesListsform').value.slice(-6);
    const level = this.mixed_form.get('levelCodesListsform').value.STVLEVL_CODE;
    const program = this.mixed_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1;
    const graduationtableData = await this.http.get('dashboard/studentapi/studentdetail/' + termcode + '/termcode/' + level + '/level/' + program + '/program/getCombinedSchoolGraduationTableValues').toPromise();
    this.graduationTable = graduationtableData;
    this.showNoRecordsMessage = this.graduationTable.length === 0;
    if (this.graduationTable.length === 0) {
      this.hideNoRecords = true
      this.hideData = true;
    } else {
      this.rowData = this.graduationTable;
      this.hideData = false;
      this.hideNoRecords = false;
    }
  }
  graduationTable: any;
  showNoRecordsMessage: boolean = false;
  hideNoRecords: boolean = false;
  //School side Graduation Dashboard Table definition
  gridApi: any;
  gridColumnApi: any;
  rowData: any;
  public gridOptions;
  buttonDeleteRendererConfig = {
    buttonText: 'Post Degree',
    buttonClass: 'btn btn-dark'
  };
  columnDefs = [
    {
      headerName: "",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 60, width: 60
    },
    {
      headerName: 'R Number',
      field: 'RID', sortable: true, filter: true, width: 150, maxWidth: 145
    },
    {
      headerName: 'Name',
      field: 'NAME', sortable: true, filter: true, width: 450
    }, {
      headerName: 'Honors',
      field: 'REGISTRAR_DECISION_HONOR', sortable: true, filter: true, width: 250
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionButtonRenderer',
      cellRendererParams: {
        isChildRow: (params) => {
          return !params.node.group;
        },
        onClick: this.PostDegree.bind(this),
        config: this.buttonDeleteRendererConfig,
      },
    },
  ];
  defaultColDef = {
    resizable: true,
    minWidth: 100,
    filter: true,
    sortable: true,
    floatingFilter: true,
    flex: 1
  };
  rowSelection = 'multiple';
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.autoSizeColumns();
  }
  paginationPageSize = 10;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi.setQuickFilter(globalSearchValue.value);
  }
  isBulkOperation: boolean = false;
  async showInfoToast(): Promise<number | undefined> {
    const activeToast = this.toastr.info("Update in progress, please wait...", 'Info', { timeOut: 0, closeButton: false });
    return activeToast ? activeToast.toastId : undefined;
  }
  async removeToast(toastId: number | undefined): Promise<void> {
    if (toastId !== undefined) {
      this.toastr.remove(toastId);
    }
  }

  async updateSingleRow(data) {
    const payload = [{
      'RID': data.RID,
      'TERM': this.mixed_form.get('termCodesListsform').value.slice(-6),
      'SCHOOL_DECISION_CHECKBOX': data.SCHOOL_DECISION_CHECKBOX,
      'USERNAME': this.data.getUsername()
    }];
    let api = 'dashboard/studentapi/studentdetail/saveSchoolGradDecisionsData';
    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    const toastId = await this.showInfoToast();
    try {
      const responseData = await this.http.post(api, payload, options).toPromise();
      if (responseData['status'] !== 'complete') {
        throw new Error('Error saving data');
      }
      await this.removeToast(toastId);
      this.toastr.success('Value updated', 'Success', { timeOut: 2000 });
    } catch (error) {
      await this.removeToast(toastId);
      this.toastr.error(`Error while saving data. Please contact adnaik@ttuhsc.edu`, 'Error', { timeOut: 5000 });
    }
  }

  async PostDegree(data: any) {
    const degree = this.mixed_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1.split('-')[1];
    const degOrCer = await this.http.get('dashboard/studentapi/studentdetail/' + degree + '/degree/getCertificateOrDegree').toPromise();
    this.degreeOrCertificateDetermined = degOrCer;
    const payload = {
      "rnumber": data.RID,
      "term": this.mixed_form.get('termCodesListsform').value.slice(-6),
      "degs": this.degreeOrCertificateDetermined[0].DEGS,
      "grst": 'CLG',
      "program": this.mixed_form.get('programCodesListsform').value.SGBSTDN_PROGRAM_1
    };
    let api = 'dashboard/studentapi/studentdetail/PostDegree';
    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    const toastId = await this.showInfoToast();
    try {
      var responseData: any = await this.http.post(api, payload, options).toPromise();
      if (responseData.status !== 'Inserted into Shrdgmr, Sorlcur and Sorlfos') {
        throw new Error('Error saving data');
      }
      await this.removeToast(toastId);
      this.toastr.success('Value updated', 'Success', { timeOut: 2000 });
    } catch (error) {
      await this.removeToast(toastId);
      this.toastr.error(`Error while saving data. Please contact adnaik@ttuhsc.edu`, 'Error', { timeOut: 5000 });
    }
  }
}
