import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogServiceService {

  private updateSuccessfulSource = new BehaviorSubject<boolean>(false);
  updateSuccessful$ = this.updateSuccessfulSource.asObservable();

  constructor() { }

  updateSuccessful(status: boolean) {
    this.updateSuccessfulSource.next(status);
  }
}
