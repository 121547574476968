
<div  style="margin-top: 20px;" class="container">
  <h2>Grading form</h2>
  <br />
  <form [formGroup]="roster_form" (ngSubmit)="roster_submit()">
    <div class="row">
      <div class="col-sm">
        <table class="table-borderless">
          <tbody>
            <tr>
              <td><p>Please select Term : </p></td>
              <td>
                <mat-form-field style="width:500px;" appearance="outline">
                  <mat-label>Select Term</mat-label>
                  <mat-select formControlName="termCodesListsform">
                    <mat-option *ngFor="let termcode of termCodesList" [value]="termcode.HSCREG_USER_TERM" (onSelectionChange)="termSelect($event)">{{termcode.HSCREG_USER_TERM}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>

            <tr>
              <td>

                <mat-form-field>
                  <mat-label>CRN</mat-label>
                  <input matInput placeholder="Ex. CRN" formControlName="crnNumberText"
                         (keyup)="crnSelect($event)" autocomplete="off">
                </mat-form-field>
              </td>
            </tr>
            <tr>
            <tr>
              <td style="padding: 50px;">
                Please Enter a CRN <b>or</b> Select a Subject
              </td>
            </tr>
            <tr>

            <tr>
              <td><p>Please select Subject : </p></td>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Select Subject</mat-label>
                  <mat-select formControlName="subjectListsform" (selectionChange)="subjectSelect($event)">
                    <mat-option value="None">None</mat-option>
                    <mat-option *ngFor="let subjects of allSubjects" [value]="subjects">{{subjects}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>

            <tr>
              <td><p>Please select Section : </p></td>
              <td>
                <mat-form-field style="width:500px;" appearance="outline">
                  <mat-label>Select Section</mat-label>
                  <mat-select formControlName="programCodesListsform">
                    <mat-option *ngFor="let programs of allPrograms" [value]="programs.BACK_DISPLAY_VALUE" (onSelectionChange)="programSelect($event)">{{programs.FRONT_DISPLAY_VALUE}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <td><button class="btn btn-dark" type="submit">Display Grades</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
  <p></p>
  <form [formGroup]="grade_form" (ngSubmit)="grade_submit()">
    <table [hidden]="!hidePagination">
      <tbody>
        <tr><td><h5>Subject Code : {{subjCode}}</h5></td></tr>
        <tr><td><h6>Course and Section : {{courseNumber}}.{{section}}</h6></td></tr>
      </tbody>
    </table>
    <mat-table #table
               [dataSource]="dataSource" matSort
               class="table"
               [hidden]="!hidePagination">

      <ng-container matColumnDef="SELECT">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>

        <mat-cell *matCellDef="let row" width="10px">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [disabled]="getBooleanFromBothGrades(row.GRADED, row.LIVE_GRADE)">
          </mat-checkbox>
        </mat-cell>

      </ng-container>
      <!-- Position Column -->
      <ng-container matColumnDef="NUMBER">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let j = index">
          {{ (j+1) + (myPaginator.pageIndex * myPaginator.pageSize) }}
        </mat-cell>
      </ng-container>
      <!-- RNumber Column -->
      <ng-container matColumnDef="RID">
        <mat-header-cell *matHeaderCellDef mat-sort-header> RID </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.RID}}</div>
        </mat-cell>
      </ng-container>
      <!-- NAME Column -->
      <ng-container matColumnDef="NAME">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.NAME}}</div>
        </mat-cell>
      </ng-container>
      <!-- EMAIL Column -->
      <ng-container matColumnDef="EMAIL">
        <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index" style="column-gap: 10px;">
          <div>{{GradeItem.EMAIL}}</div>
        </mat-cell>
      </ng-container>
      <!-- CLASSIFICATION Column -->
      <ng-container matColumnDef="CLASSIFICATION">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Classification </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.CLASSIFICATION}}</div>
        </mat-cell>
      </ng-container>
      <!-- COLLEGE Column -->
      <ng-container matColumnDef="COLLEGE">
        <mat-header-cell *matHeaderCellDef mat-sort-header> College </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.COLLEGE}}</div>
        </mat-cell>
      </ng-container>
      <!-- DEGREE Column -->
      <ng-container matColumnDef="DEGREE">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Degree </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.DEGREE}}</div>
        </mat-cell>
      </ng-container>
      <!-- MAJOR Column -->
      <ng-container matColumnDef="MAJOR">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Major </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.MAJOR}}</div>
        </mat-cell>
      </ng-container>
      <!-- GRADED Column -->
      <ng-container matColumnDef="GRADED">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Graded </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.GRADED}}</div>
        </mat-cell>
      </ng-container>
      <!-- FINAL GRADES Column -->
      <ng-container matColumnDef="LIVE_GRADE">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Final Grades </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div>{{GradeItem.LIVE_GRADE}} ({{GradeItem.LAST_ATTENDANCE_DATE}})</div>
        </mat-cell>
      </ng-container>
      <!-- GRADES Column -->
      <ng-container matColumnDef="GRADES">
        <mat-header-cell *matHeaderCellDef> Grades </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i=index">
          <div *ngIf="getBooleanForGradeDropDown(GradeItem.GRADED, GradeItem.LIVE_GRADE)">
            <mat-form-field style="width:100px;" appearance="outline">
              <mat-select placeholder="Grade" (selectionChange)="updateDropDownValue(GradeItem,$event.value)" (click)="onGradeClick($event)" required>
                <mat-option *ngFor="let grade of gradeDropDownData" [value]="grade">
                  {{grade}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>
      <!-- LAST_ATTENDANCE_DATE Column -->
      <ng-container matColumnDef="LAST_ATTENDANCE_DATE">
        <mat-header-cell *matHeaderCellDef> Last Attended Date </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index">
          <div *ngIf="GradeItem.current_grade=='F' || GradeItem.current_grade=='FA'||GradeItem.current_grade>=0 && GradeItem.current_grade<=69">
            <mat-form-field appearance="fill">
              <mat-label>Input Date</mat-label>
              <input matInput [matDatepicker]="dp3" (dateInput)="updateDateValue(GradeItem,$event.value)" disabled>
              <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
              <mat-datepicker #dp3 disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>
      <!-- MAJOR Column -->
      <ng-container matColumnDef="TERM">
        <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Term </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index" [style.display]="'none'">
        </mat-cell>
      </ng-container>
      <!-- MAJOR Column -->
      <ng-container matColumnDef="CRN">
        <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Crn </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index" [style.display]="'none'">
        </mat-cell>
      </ng-container>
      <!-- MAJOR Column -->
      <ng-container matColumnDef="PTRM">
        <mat-header-cell *matHeaderCellDef [style.display]="'none'"> Ptrm </mat-header-cell>
        <mat-cell *matCellDef="let GradeItem; let i = index" [style.display]="'none'">
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [ngClass]="{incompletedred: !getBooleanFromBothGrades(row.GRADED, row.LIVE_GRADE)}"
               *matRowDef="let row; columns: displayedColumns;" cdkDrag
               (click)="!getBooleanFromBothGrades(row.GRADED, row.LIVE_GRADE) && selection.toggle(row)"></mat-row>
    </mat-table>
    <p [hidden]="!hideNoRecords">No records found</p>
    <br />
    <div *ngIf="!formValid" class="alert alert-danger">
      Please fill out all Required Fields with valid <b>Grade and Date.</b>
    </div>
    <br />
    <br />
    <div [hidden]="!hidePagination">
      <button style="float:left" class="btn btn-dark" *ngIf="dataSource.data.length != 0" type="submit">Submit Grades</button>
      <mat-paginator #myPaginator [pageSizeOptions]="[100,25,10,5]" (page)="onPaginateChange($event)" showFirstLastButtons></mat-paginator>
    </div>
    <br />
  </form>
  <div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
    <button type="button" class="btn active" data-toggle="button" aria-pressed="true" (click)="scrollToTop()">
      <i class="arrow up"></i>
    </button>
  </div>
</div>

