import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GridApi, ColumnApi } from 'ag-grid-community';

@Component({
  selector: 'app-work-board-ferpa-table',
  templateUrl: './work-board-ferpa-table.component.html',
  styleUrls: ['./work-board-ferpa-table.component.css']
})
export class WorkBoardFerpaTableComponent implements OnInit {

  @Input() rowDataCurrentRnumberFerpa: any;
  hideFerpaTable: boolean = false;
  public rowData: any;
  public gridOptions;
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  frameworkComponents: any;
  public defaultColDef;

  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.getFerpaDetails(this.rowDataCurrentRnumberFerpa);
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
      filter: true,
      sortable: true,
      floatingFilter: true,

    };
    this.gridOptions = {
      domLayout: 'normal',
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      rowSelection: 'single',
    };
  }
  async getFerpaDetails(rno: any) {
    const finalRowData = await this.http.get('dashboard/studentapi/studentdetail/' + rno + '/rno/' + 'workTableFerpaDetails').toPromise();
    this.rowData = finalRowData;
    if (this.rowData.length != 0) {
      this.hideFerpaTable = true;
    } else {
      this.hideFerpaTable = false;
    }
  }

  columnDefs = [
    {
      headerName: 'Ferpa First Name',
      field: 'FERPA_FIRSTNAME',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Ferpa Last Name',
      field: 'FERPA_LASTNAME',
      sortable: true,
      filter: true
    },

    {
      headerName: 'Access Pin',
      field: 'FERPA_ACCESSPIN',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Business Account',
      field: 'FERPA_BUSINESSACCOUNT',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Application Records',
      field: 'FERPA_APPLICATIONRECORDS',
      sortable: true,
      filter: true
    },

    {
      headerName: 'Financial Aid',
      field: 'FERPA_FINANCIALAID',
      sortable: true,
      filter: true
    }, {
      headerName: 'Academic Records',
      field: 'FERPA_ACADEMICRECORDS',
      sortable: true,
      filter: true
    },
  ];
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
}
