import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

export class Tile {
  text: string;
  content: any;
}

@Component({
  selector: 'app-deans-dashboard',
  templateUrl: './deans-dashboard.component.html',
  styleUrls: ['./deans-dashboard.component.css']
})
export class DeansDashboardComponent implements OnInit {
  
  infoTabLoading: boolean = false;
  infoTabLoadingCampus: boolean = false;
  countList: any;
  
  constructor(private http: HttpClient) { }
  ngOnInit() {
    this.infoTabLoading = true;

    this.getOverallCount();
   
  }
  
  

  async getOverallCount() {
    const data = await this.http.get('dashboard/studentapi/studentdetail/getDeansCount').toPromise();
    this.countList = data;
    this.infoTabLoading = false;
  }

  


}
