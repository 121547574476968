import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { GridApi, ColumnApi } from 'ag-grid-community';

export interface DialogInfo {
  RNumber: string;
}

@Component({
  selector: 'app-holds-prompt-modal',
  templateUrl: './holds-prompt-modal.component.html',
  styleUrls: ['./holds-prompt-modal.component.css']
})
export class HoldsPromptModalComponent implements OnInit {
  Rnumber = this.data.RNumber;
  NoHoldInfo: boolean = false;
  public rowData: any;
  public gridOptions;
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  frameworkComponents: any;
  public defaultColDef;

  columnDefs = [
    {
      headerName: 'Hold Code',
      field: 'SPRHOLD_HLDD_CODE',
      sortable: true,
      filter: true
    },
    {
      headerName: 'Stvorig Description',
      field: 'STVORIG_DESC',
      sortable: true,
      filter: true
    },

    {
      headerName: 'Hold Reason',
      field: 'SPRHOLD_REASON',
      sortable: true,
      filter: true
    },
    
  ];
  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    public dialogRef: MatDialogRef<HoldsPromptModalComponent>) { }

  ngOnInit(): void {
    this.getHoldsTable();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
      filter: true,
      sortable: true,
      floatingFilter: true,

    };
    this.gridOptions = {
      domLayout: 'normal',
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      rowSelection: 'single',
    };
  }
  async getHoldsTable() {
    if (this.Rnumber != "") {
      const holdsTableData = await this.http.get('dashboard/studentapi/studentdetail/' + this.Rnumber + '/rno/' + 'holdsModalTableData').toPromise();
      this.rowData = holdsTableData;
      if (this.rowData.length != 0) {

        this.NoHoldInfo = true;
      }
     else {     
      this.NoHoldInfo = false;
      }
    }
  }
  Close(): void {
    this.dialogRef.close(true);
  }
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
}
