<mat-toolbar color="red">
  <mat-toolbar-row>
    <button mat-icon-button (click)="isExpanded = !isExpanded"><mat-icon>menu</mat-icon></button>
    <span class="menu-spacer"></span>
    <div>
      <a class="nav-link" [routerLink]="'welcome'"><img style="height:40px;margin: 5px;padding: 1px;background-color: black;float:left" src="dashboard/assets/TTUHSCLogo.jpg" alt="Fallbackimage"></a>
    </div>
    <div style="position: absolute; top: 18px; right: 4px;">
      <button mat-icon-button (click)=showNotification()>
        <mat-icon>notifications</mat-icon>
        <span matBadge="{{ notificationCount }}" matBadgePosition="after"></span>
      </button>
      <button (click)='logout()' mat-button> Log Out </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="example-container" fullscreen id="container">
  <mat-sidenav [class.shortnav]="!isExpanded && !isShowing" #sidenav class="example-sidenav" mode="side" opened="true" (mouseenter)="mouseenter()" (mouseleave)="mouseleave()">
    <mat-nav-list>
      <mat-list-item *ngIf="showDashboardMainTab" (click)="showDashboardMenu = !showDashboardMenu" class="parent">
        <mat-icon mat-list-icon>dashboard</mat-icon>
        <span *ngIf="isExpanded || isShowing" class="menu-item">Dashboards</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showDashboardMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
      </mat-list-item>
      <!--Dashboard-->
      <div class="submenu" [ngClass]="{'expanded' : showDashboardMenu}" *ngIf="isShowing || isExpanded">
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_DASHBOARD =='YES'" [routerLink]="''" class="submenu-item">
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Dashboard</span>
        </mat-list-item>
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_WORKBOARD =='YES'" [routerLink]="'work-board'" class="submenu-item">
          <mat-icon mat-list-icon>assignment</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Audit Board </span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_TRANSFERBOARD =='YES'" [routerLink]="'transfer-board'" class="submenu-item">
          <mat-icon mat-list-icon>assignment</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Transfer Board</span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_INSSUPPORT =='YES'" [routerLink]="'institutional-support'" class="submenu-item">
          <mat-icon mat-list-icon>info</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Institutional Support</span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_ENROLLMENTDASH =='YES'" [routerLink]="'enrollment-dashboard'" class="submenu-item">
          <mat-icon mat-list-icon>monitoring</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Enrollment Dashboard</span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_DUALREPORTSDASH =='YES'" [routerLink]="'dualdegree-dashboard'" class="submenu-item">
          <mat-icon mat-list-icon>monitoring</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Dual Degree Dashboard</span>
        </mat-list-item>
      </div>
      <mat-list-item *ngIf="showAcademicServicesMainTab" (click)="showAcademicMenu = !showAcademicMenu" class="parent">
        <mat-icon mat-list-icon>school</mat-icon>
        <span *ngIf="isExpanded || isShowing" class="menu-item">Academic Services</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showAcademicMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
      </mat-list-item>
      <!--Academic-->
      <div class="submenu" [ngClass]="{'expanded' : showAcademicMenu}" *ngIf="isShowing || isExpanded">
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_ROSTER =='YES'" [routerLink]="'roster'" class="submenu-item">
          <mat-icon mat-list-icon>receipt_long</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Roster</span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_GRADES =='YES'" [routerLink]="'grade'" class="submenu-item">
          <mat-icon mat-list-icon>percent</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Grades</span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_GRADDASH =='YES'" [routerLink]="'graduation-dashboard'" class="submenu-item">
          <mat-icon mat-list-icon>assignment</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Graduation Dashboard</span>
        </mat-list-item>
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_SCHOOLGRADDASH =='YES'" [routerLink]="'school-graduation-dashboard'" class="submenu-item">
          <mat-icon mat-list-icon>assignment</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">School Graduation Dashboard</span>
        </mat-list-item>
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_COMBINEDGRADDASH =='YES'" [routerLink]="'combined-graduation-dashboard'" class="submenu-item">
          <mat-icon mat-list-icon>assignment</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Banner Degree Dashboard</span>
        </mat-list-item>
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_RESIDENCYDASH =='YES'" [routerLink]="'residency-dashboard'" class="submenu-item">
          <mat-icon mat-list-icon>home</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Residency Dashboard</span>
        </mat-list-item>
      </div>
      <mat-list-item *ngIf="showReportsMainTab" (click)="showReportsMenu = !showReportsMenu" class="parent">
        <mat-icon mat-list-icon>table_chart</mat-icon>
        <span *ngIf="isExpanded || isShowing" class="menu-item">Reports & Support</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showReportsMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
      </mat-list-item>
      <!--Reports-->
      <div class="submenu" [ngClass]="{'expanded' : showReportsMenu}" *ngIf="isShowing || isExpanded">
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_ODSREPORTSDASH =='YES'" [routerLink]="'ods-reports'" class="submenu-item">
          <mat-icon mat-list-icon>article</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Reports</span>
        </mat-list-item>
      </div>
      <mat-list-item *ngIf="showSettingsMainTab" (click)="showSubSubMenu = !showSubSubMenu" class="parent">
        <mat-icon mat-list-icon>settings</mat-icon>
        <span *ngIf="isExpanded || isShowing" class="menu-item">Settings</span>
        <mat-icon class="menu-button" [ngClass]="{'rotated' : showSubSubMenu}" *ngIf="isExpanded || isShowing">expand_more</mat-icon>
      </mat-list-item>
      <!--Settings-->
      <div class="submenu" [ngClass]="{'expanded' : showSubSubMenu}" *ngIf="isShowing || isExpanded">
        <mat-list-item *ngIf="userRoles?.HSCREG_USER_ROLES =='YES'" [routerLink]="'roles'" class="submenu-item">
          <mat-icon mat-list-icon>settings</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">Roles</span>
        </mat-list-item>

        <mat-list-item *ngIf="userRoles?.HSCREG_USER_CRNACCESS =='YES'" [routerLink]="'crn-access'" class="submenu-item">
          <mat-icon mat-list-icon>settings</mat-icon>
          <span *ngIf="isExpanded || isShowing" class="menu-item">CRN Access</span>
        </mat-list-item>
      </div>


    </mat-nav-list>
  </mat-sidenav>
  <!-- Content - rendered Content for the current router path into the <router-outlet>, e.g. /dashboard or /info-->
  <mat-sidenav-content #sidenavContent>
    <router-outlet></router-outlet>
    <app-scroll-to-top [scrollContainer]="sidenavContent"></app-scroll-to-top>

  </mat-sidenav-content>

</mat-sidenav-container>
