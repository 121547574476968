import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../Services/students.service';
@Component({
  selector: 'app-graduation-dashboard-table',
  templateUrl: './graduation-dashboard-table.component.html',
  styleUrls: ['./graduation-dashboard-table.component.css']
})
export class GraduationDashboardTableComponent implements OnInit {
  @Input() rowDataSelectedTerm: any;
  @Input() rowDataCurrentRnumber: any;
  honorOptions: string[] = [];
  constructor(private http: HttpClient, private toastr: ToastrService, private data: SharedService ) { }

  ngOnInit() {
    this.fetchDropdownValues();
    this.gridOptions = {
      domLayout: 'normal',
      singleClickEdit: true 
    };
    this.getGraduationDetailRowData(this.rowDataSelectedTerm, this.rowDataCurrentRnumber);
  }
  async getGraduationDetailRowData(rowDataTerm: any, rno: any) {
    const finalRowData = await this.http.get('dashboard/studentapi/studentdetail/' + rowDataTerm + '/termcode/' + rno + '/rno/' + 'GraduationTableDetailsData').toPromise();
    this.rowData1 = finalRowData;
  }
  //Grad board Table definition
  gridApi1: any;
  gridColumnApi1: any;
  rowData1: any;
  public gridOptions;
  columnDefs1 = [
    {
      headerName: 'Registrar Decision',
      field: 'REGISTRAR_DECISION_CHECKBOX',
      width: 60, maxWidth: 250,
      cellRenderer: params => {
        const inputElement = document.createElement('input');
        inputElement.type = 'checkbox';
        inputElement.checked = params.value === 'YES';
        inputElement.addEventListener('click', async () => {
          const decision = inputElement.checked ? 'YES' : 'NO';
          params.data.REGISTRAR_DECISION_CHECKBOX = decision;
          await this.updateRegistrarDecision(params.data);
        });
        return inputElement;
      }
    },
    /*{
      headerName: 'DW Staus',
      field: 'SORPCOL_SBGI_CODE', sortable: true, filter: true,
      maxWidth: 135

    },
    {
      headerName: 'Degree Status',
      field: 'DATE_RECEIVED', sortable: true, filter: true, editable: true,
      maxWidth: 140
    }, {
      headerName: 'Date Mailed',
      field: 'DATE_MAILED', sortable: true, filter: true, editable: true,
      maxWidth: 140
    },*/ {
      headerName: 'Honors',
      field: 'REGISTRAR_DECISION_HONOR',
      width: 220, maxWidth: 250,
      cellRenderer: (params) => {
        //create dropdown select for every student
        const selectElem = document.createElement('select');
        // Add a default "None" option
        const defaultOptionElem = document.createElement('option');
        defaultOptionElem.value = 'None';
        defaultOptionElem.text = 'None';
        selectElem.appendChild(defaultOptionElem);
        // Add the honoroptions value to the option
        this.honorOptions.forEach(opt => {
          const optionElem = document.createElement('option');
          optionElem.value = opt;
          optionElem.text = opt;
          if (params.value === opt) {
            optionElem.selected = true;
          }
          selectElem.appendChild(optionElem);
        });
        //when user clicks come to update
        selectElem.addEventListener('change', async () => {
          params.data.REGISTRAR_DECISION_HONOR = selectElem.value;
          await this.updateRegistrarDecision(params.data);
        });

        return selectElem;
      }
    },
    {
      headerName: 'Notes',
      field: 'GRADBOARDNOTES',
      editable: true,
      cellEditor: 'agLargeTextCellEditor',
      flex: 2,
    },
  ];

  defaultColDef1 = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    filter: true,
    sortable: true,
    floatingFilter: true,
  };
  rowSelection1 = 'multiple';
  onGridReady1(params) {
    this.gridApi1 = params.api;
    this.gridColumnApi1 = params.columnApi;
  }
  paginationPageSize1 = 10;
  onPageSizeChanged() {
    var pageValue = document.getElementById('page-size') as HTMLInputElement;
    this.gridApi1.paginationSetPageSize(Number(pageValue.value));
  }
  paginationNumberFormatter1 = function (params) {
    return '[' + params.value.toLocaleString() + ']';
  };
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi1.setQuickFilter(globalSearchValue.value);
  }
  async showInfoToast(): Promise<number | undefined> {
    const activeToast = this.toastr.info("Update in progress, please wait...", 'Info', { timeOut: 0, closeButton: false });
    return activeToast ? activeToast.toastId : undefined;
  }
  async removeToast(toastId: number | undefined): Promise<void> {
    if (toastId !== undefined) {
      this.toastr.remove(toastId);
    }
  }
  onCellClicked(params) {
    if (params.column.getColId() === 'REGISTRAR_DECISION_HONOR') {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: 'REGISTRAR_DECISION_HONOR'
      });
    }
  }
  async onCellValueChanged(event) {
    if (event === undefined || event.data.GRADBOARDNOTES === undefined) {
      event.data.GRADBOARDNOTES = '';
    }
    this.sendGradBoardNotesToSave(event.data.GRADBOARDNOTES);
  }
  async sendGradBoardNotesToSave(gradBoardNotesValue: any) {
    var tempItems = '{'
      + '"RID" : "' + this.rowDataCurrentRnumber + '",'
      + '"TERM" : "' + this.rowDataSelectedTerm + '",'
      + '"GRADBOARDNOTES" : "' + gradBoardNotesValue + '"'
      + '}';

    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    let api = 'dashboard/studentapi/studentdetail/saveGradBoardNotesData';

    var data = await this.http.post<string>(api, tempItems, options).toPromise();
    if (data['status'] == 'complete') {
      console.log('Notes Saving Success');
    } else if (data['status'] == 'error') {
      console.log('Notes Saving Failed');
    }
  }
  async fetchDropdownValues() {
    const honors = await this.http.get('dashboard/studentapi/studentdetail/GetHonors').toPromise();
  this.honorOptions = (honors as any[]).map(h => h.HONOR_DESC);
  console.log('Fetched Honors:', this.honorOptions);
  }
  async updateRegistrarDecision(data) {
    const payload = [{
      'RID': this.rowDataCurrentRnumber,
      'TERM': this.rowDataSelectedTerm,
      'REGISTRAR_DECISION_CHECKBOX': data.REGISTRAR_DECISION_CHECKBOX,
      'REGISTRAR_DECISION_HONOR': data.REGISTRAR_DECISION_HONOR === 'None' ? null : data.REGISTRAR_DECISION_HONOR,
      'USERNAME': this.data.getUsername()
    }];
    let api = 'dashboard/studentapi/studentdetail/saveRegistrarGradDecisionsData';
    let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: lclheaders };
    const toastId = await this.showInfoToast();
    try {
      const responseData = await this.http.post(api, payload, options).toPromise();
      if (responseData['status'] !== 'complete') {
        throw new Error('Error saving data');
      }
      await this.removeToast(toastId);
      this.toastr.success('Value updated', 'Success', { timeOut: 2000 });
    } catch (error) {
      await this.removeToast(toastId);
      this.toastr.error(`Error while saving data. Please contact adnaik@ttuhsc.edu`, 'Error', { timeOut: 5000 });
    }
  }

}
