import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { DialogServiceService } from '../../Services/modalDialogservices/dialog-service.service';
export interface DialogInfo {
  RNumber: string;
  Term: string;
}
@Component({
  selector: 'app-workboard-update-modal',
  templateUrl: './workboard-update-modal.component.html',
  styleUrls: ['./workboard-update-modal.component.css']
})
export class WorkboardUpdateModalComponent implements OnInit {
  hideUpdateForm: boolean = true;
  hideSuccessUpdateMessage: boolean = true;
  hideErrorUpdateMessage: boolean = true;
  disableSubmitButton: boolean = false;
  public updateSuccessful: boolean = false;
  Rnumber = this.data.RNumber;
  Term = this.data.Term;
  errorMessage: string = '';
  xDocType: any;
  xResidencyList: any;
  xCampusCodeList: any;
  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    public dialogRef: MatDialogRef<WorkboardUpdateModalComponent>, public dialogService: DialogServiceService) { }
  ngOnInit() {
    this.getXDocTypes();
  }
  async getXDocTypes() {
    const ResidencyList = await this.http.get('dashboard/studentapi/studentdetail/GetResidencyTypes').toPromise();
    this.xResidencyList = ResidencyList;
    const CampusCodeList = await this.http.get('dashboard/studentapi/studentdetail/GetCampCode').toPromise();
     this.xCampusCodeList = CampusCodeList;
  }
  workboardUpdate_form = new FormGroup({
    '_residency': new FormControl('', Validators.required),
    '_gradterm': new FormControl('', [Validators.required, Validators.min(100000), Validators.max(999999)]),
    '_graddate': new FormControl('', [Validators.required, this.dateValidator]),
    '_campcode': new FormControl('', Validators.required),
  });
  dateValidator(control: FormControl): { [key: string]: any } | null {
    const datePattern = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;

    if (control.value instanceof Date) {
      const dateString = ('0' + (control.value.getMonth() + 1)).slice(-2) + '/' + ('0' + control.value.getDate()).slice(-2) + '/' + control.value.getFullYear();
      if (!datePattern.test(dateString)) {
        return { 'invalidDate': true };
      }
    } else if (control.value && typeof control.value === 'string') {
      if (!datePattern.test(control.value)) {
        return { 'invalidDate': true };
      }
    } else if (control.value) {
      return { 'invalidDate': true };
    }
    return null;
  }
  get residency() {
    return this.workboardUpdate_form.get('_residency');
  }
  get gradTerm() {
    return this.workboardUpdate_form.get('_gradterm');
  }
  get gradDate() {
    return this.workboardUpdate_form.get('_graddate');
  }
  get campCode() {
    return this.workboardUpdate_form.get('_campcode');
  }
  async Update() {
    if (this.workboardUpdate_form.valid) {
      this.disableSubmitButton = true;
      const formData = this.workboardUpdate_form.value;
      const body = {
        "SPRIDEN_ID": this.Rnumber,
        "SGBSTDN_TERM_CODE_EFF": this.Term,
        "SGBSTDN_RESD_CODE": formData._residency === 'NULL' ? null : formData._residency,
        "SGBSTDN_TERM_CODE_GRAD": formData._gradterm.toString(),
        "SGBSTDN_EXP_GRAD_DATE": this.formatDate(formData._graddate),
        "SGBSTDN_FULL_PART_IND": null,
        "SGBSTDN_CAMP_CODE": formData._campcode,
      };

      let lclheaders = new HttpHeaders({ 'Content-Type': 'application/json' });
      let options = { headers: lclheaders };
      let api = 'dashboard/studentapi/studentdetail/updateSGBSTDN';
      try {
        var response = await this.http.post<any>(api, body, options).toPromise();
        if (response.status === 'SGBSTDN Updated!') {
          console.log('Value updated.');
          this.hideSuccessUpdateMessage = false;
          this.hideErrorUpdateMessage = true;
          this.hideUpdateForm = false;
          this.updateSuccessful = true;
          this.dialogService.updateSuccessful(true);
          //this.dialogRef.close(true);
        } else {
          console.log('Error:', response.status);
          this.hideErrorUpdateMessage = false;
          this.hideSuccessUpdateMessage = true;
          this.hideUpdateForm = false;
          this.errorMessage = response.status; 
        }
      } catch (error) {
        console.log('Error:', error);
        this.errorMessage = error.message
      }
    } else {
      console.log('Form is not valid.');
    }
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return month + '/' + day + '/' + year;
  }
}
