import { Component, OnInit, Inject } from '@angular/core';
import { GridApi, ColumnApi } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogInfo {
  RNumber: string;
  params: any;
}
@Component({
  selector: 'app-workboardtable-degree-modal',
  templateUrl: './workboardtable-degree-modal.component.html',
  styleUrls: ['./workboardtable-degree-modal.component.css']
})
export class WorkboardtableDegreeModalComponent implements OnInit {
  params = this.data.params;
  constructor(private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: DialogInfo,
    public dialogRef: MatDialogRef<WorkboardtableDegreeModalComponent>
    , private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getDegreeListInTable();
    this.defaultColDef = {
      flex: 1,
      minWidth: 150,
      resizable: true,
      filter: true,
      sortable: true,
      floatingFilter: true,

    };
    this.gridOptions = {
      domLayout: 'normal',
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      rowSelection: 'single',
      filter: 'agTextColumnFilter',
    };
  }
  Rnumber = this.data.RNumber;
  public rowData: any;
  public gridOptions;
  public gridApi: GridApi;
  public columnApi: ColumnApi;
  frameworkComponents: any;
  public defaultColDef;

  columnDefs = [
    {
      headerName: 'Degree Code',
      field: 'DEGREE_CODE',
      sortable: true
    },
    {
      headerName: 'Degree Name',
      field: 'DEGREE_NAME',
      sortable: true
    }
  ];
  onQuickFilterChanged() {
    var globalSearchValue = document.getElementById('quickFilter') as HTMLInputElement;
    this.gridApi.setQuickFilter(globalSearchValue.value);
  }
  async getDegreeListInTable() {
    const student_SBSdocs = await this.http.get('dashboard/studentapi/studentdetail/degreelist').toPromise();
    console.log(student_SBSdocs)
    this.rowData = student_SBSdocs;
  }
  Close(): void {
    this.dialogRef.close(true);
  }
  onGridReady(params): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }
  onDegreeSelected(event) {
    const selectedDegree = event.data.DEGREE_CODE;
    this.dialogRef.close({ degreeCode: selectedDegree, params: this.data.params });
  }

  onRowClicked(event) {
    this.dialogRef.close({ degreeCode: event.data.DEGREE_CODE, params: this.data.params });
  }


  student_docs_download: any;
 
  showSnackbarAction(content, action) {
    let snack = this.snackBar.open(content, action, { duration: 3000 });
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }
}
