import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-button-group-action-renderer',
  templateUrl: './button-group-action-renderer.component.html',
  styleUrls: ['./button-group-action-renderer.component.css']
})
export class ButtonGroupActionRendererComponent implements OnInit, ICellRendererAngularComp {
  ngOnInit() {
  }
  constructor() { }
  private params: any;
  isChildRow: boolean;
  @Input() config: any;
  buttonText: string; // New property for button text
  buttonClass: string; // New property for button class
  
  onClick: (rowData: any) => void; // Add this line to define the onClick function

  agInit(params: any): void {
    this.params = params;
    this.config = params.config;
    this.onClick = params.onClick; // Add this line to assign the onClick function
    if (this.config) {
      this.buttonText = this.config.buttonText;
      this.buttonClass = this.config.buttonClass;
    }
    this.isChildRow = this.params.isChildRow(this.params);
  }

  refresh(params: any): boolean {
    return false;
  }
}
