<div>
  <h5>
    <b>R Number: </b>{{Rnumber}}  <b>Term: </b>{{Term}}
  </h5>
</div>
<div [hidden]="NoEnrollmentInfo">
  <h5 class="alert alert-danger">No information found. Please try with another Term</h5>
</div>
<div [hidden]="!NoEnrollmentInfo">
  <ag-grid-angular style="width: 98%; height: 375px;"
                   class="ag-theme-alpine"
                   pagination
                   [gridOptions]="gridOptions"
                   (gridReady)="onGridReady($event)"
                   [rowData]="rowData"
                   [columnDefs]="columnDefs"
                   [pagination]="true"
                   [paginationPageSize]="5"
                   [suppressRowClickSelection]="true"
                   [suppressMenuHide]="true"
                   [frameworkComponents]="frameworkComponents">

  </ag-grid-angular>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="Close()">Close</button>
</div>
